const prettyBytes = (bytes: number) => {
  const units = ['b', 'kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const exponent = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1000)),
    units.length - 1,
  );

  const out = (bytes / Math.pow(1000, exponent)).toFixed(2);
  const unit = units[exponent];

  return `${out} ${unit}`;
};

export { prettyBytes };
