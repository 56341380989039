import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AccountsList extends Vue {
  @Prop() public accounts!: { email: string; password: string }[];
  public show: { [key: string]: boolean } = {};

  public toggleShow(index: number) {
    Vue.set(this.show, index, !this.show[index]);
  }
}
