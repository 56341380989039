import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import IPool from '@/models/interfaces/pool';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import en from './lang/classification-score-array.en.json';
import fr from './lang/classification-score-array.fr.json';
import ClassificationScoreValue from '@/illuin-annotation/models/dtos/score-value/classification-score-value.dto';

@Component({
    computed: mapState('pool-admin', ['pool']),
    i18n: {
        messages: { en, fr },
    },
})
export default class ClassificationScoreArray extends Vue {
    public pool!: IPool;
    @Prop() public scoreValue!: ClassificationScoreValue;

    public get totalScore() {
        return this.scoreValue.total.kappa;
    }

    public val(tag: {
        positiveMatchs: number;
        negativeMatchs: number;
        mismatchs: number;
        kappa: number;
    }) {
        return tag.kappa;
    }

    public tagTitleFromId(tagId: string): string {
        if (this.pool.project && this.pool.project.dataModel) {
            for (const tag of (this.pool.project.dataModel as INerDataModel).tags) {
                if (tag.id === tagId) {
                    return tag.title;
                }
            }
        }
        return '';
    }
}
