import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import AnnotatorTags from '@/illuin-annotation/components/common/AnnotatorTags/AnnotatorTags.vue';
import NERRelationAnnotatorText from '@/illuin-annotation/components/NERRelation/NERRelationAnnotatorText/NERRelationAnnotatorText.vue';
import ListRelationSelector from '@/illuin-annotation/components/NERRelation/ListRelationSelector/ListRelationSelector.vue';
import PieTagSelector from '@/illuin-annotation/components/common/PieTagSelector/PieTagSelector.vue';
import NERRelationBaseAnnotatorMixin from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorMixin/NERRelationBaseAnnotatorMixin';
import INERTagging from '@/illuin-annotation/models/interfaces/ner-tagging';
import IRelationTagging from '@/illuin-annotation/models/interfaces/relation-tagging';
import IRelation from '@/illuin-annotation/models/interfaces/relation';
import INERAnnotationValue from '@/illuin-annotation/models/interfaces/ner-annotation';
import INERRelationAnnotationValue from '@/illuin-annotation/models/interfaces/ner-relation-annotation';
import en from './lang/ner-relations-annotator.en.json';
import fr from './lang/ner-relations-annotator.fr.json';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import { getAvailableRelations } from '@/illuin-annotation/components/NERRelation/utils/relation-datamodel';
import {
  formatExportableNerTaggings,
  formatExportableRelationTaggings,
} from '@/illuin-annotation/components/NERRelation/utils/ner-relation-dto';
import uuidByString from 'uuid-by-string';
import ProjectType from '@/models/enums/project-type';

@Component({
  components: {
    PieTagSelector,
    ListRelationSelector,
    AnnotatorTags,
    NERRelationAnnotatorText,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class NERRelationAnnotator extends Mixins(
  NERRelationBaseAnnotatorMixin,
) {
  @Prop() public preAnnotation!: INERRelationAnnotationValue | null;
  @Prop() public type!: string;

  public nerTaggings: { [key: string]: INERTagging } = {};
  public relationTaggings: IRelationTagging[] = [];

  public pendingNerTagging: INERTagging | null = null;
  public pendingRelationTagging: IRelationTagging | null = null;
  public pendingAvailableRelations: IRelation[] = [];

  public get hasRelations(): boolean {
    return this.type === ProjectType.NER_RELATIONS;
  }

  @Watch('documentValue')
  public onDocumentChange() {
    this.nerTaggings = {};
    this.relationTaggings = [];
    this.pendingNerTagging = null;
    this.pendingRelationTagging = null;
    this.pendingAvailableRelations = [];
    this.displayTagSelector = false;
    this.displayRelationSelector = false;
    this.$emit('setAnnotation', this.annotation);
  }

  @Watch('preAnnotation')
  public onPreAnnotationChange() {
    this.computePreAnnotation();
    this.$emit('setAnnotation', this.annotation);
  }

  public get annotation(): INERAnnotationValue | INERRelationAnnotationValue {
    if (this.relations) {
      return {
        tags: formatExportableNerTaggings(this.nerTaggings),
        relations: formatExportableRelationTaggings(this.relationTaggings),
      };
    }
    return {
      tags: formatExportableNerTaggings(this.nerTaggings),
    };
  }

  public computePreAnnotation() {
    if (this.preAnnotation) {
      this.nerTaggings = {};
      this.relationTaggings = [];
      this.preAnnotation.tags.forEach((nerTagging) => {
        this.nerTaggings[nerTagging.id] = {
          id: nerTagging.id,
          begin: nerTagging.begin,
          end: nerTagging.end,
          tag: this.tagsById[nerTagging.tag],
        };
      });
      if (this.relations) {
        this.preAnnotation.relations!.forEach((relationTagging) => {
          const begin = this.nerTaggings[relationTagging.begin];
          const end = this.nerTaggings[relationTagging.end];
          this.relationTaggings.push({
            begin,
            end,
            id: relationTagging.id,
            relation: this.relationsById[relationTagging.relation],
            reversed: begin.begin > end.begin,
          });
        });
      }
    }
  }

  public addPendingNerTagging(infos: {
    taggingInfo: INERTagging;
    mousePosition: any;
  }) {
    this.pendingNerTagging = infos.taggingInfo;
    if (this.tags.length === 1) {
      this.confirmNerTagging(this.tags[0]);
    } else {
      this.mousePosition = infos.mousePosition;
      this.displayTagSelector = true;
    }
  }

  public confirmNerTagging(tag: ITag) {
    if (this.pendingNerTagging) {
      const id = uuidByString(
        `${this.pendingNerTagging.begin}-${this.pendingNerTagging.end}-${
          tag!.id
        }`,
      );
      Vue.set(this.nerTaggings, id, {
        id,
        tag,
        begin: this.pendingNerTagging.begin,
        end: this.pendingNerTagging.end,
      });
    }
    this.displayTagSelector = false;
    this.pendingNerTagging = null;
    this.$emit('setAnnotation', this.annotation);
  }

  public addPendingRelationTagging(infos: {
    taggingInfo: IRelationTagging;
    mousePosition: any;
  }) {
    this.pendingAvailableRelations = getAvailableRelations(
      this.relations,
      infos.taggingInfo.begin.tag!.id,
      infos.taggingInfo.end.tag!.id,
    );
    if (this.pendingAvailableRelations.length === 1) {
      this.pendingRelationTagging = infos.taggingInfo;
      this.confirmRelationTagging(this.pendingAvailableRelations[0]);
    } else if (this.pendingAvailableRelations.length > 1) {
      this.pendingRelationTagging = infos.taggingInfo;
      this.mousePosition = infos.mousePosition;
      this.displayRelationSelector = true;
    }
  }

  public confirmRelationTagging(relation: IRelation) {
    if (this.pendingRelationTagging) {
      let alreadyExisting = false;
      this.relationTaggings.forEach((relationTagging) => {
        if (
          relationTagging.relation!.id === relation.id &&
          relationTagging.begin.id === this.pendingRelationTagging!.begin.id &&
          relationTagging.end.id === this.pendingRelationTagging!.end.id
        ) {
          alreadyExisting = true;
        }
      });
      if (!alreadyExisting) {
        const id = uuidByString(
          `${this.pendingRelationTagging!.begin.begin}-${
            this.pendingRelationTagging!.end.begin
          }-${relation.id}`,
        );
        this.relationTaggings.push({
          id,
          relation,
          begin: this.pendingRelationTagging!.begin,
          end: this.pendingRelationTagging!.end,
          reversed: this.pendingRelationTagging!.reversed,
        });
      }
    }
    this.displayRelationSelector = false;
    this.pendingRelationTagging = null;
    this.$emit('setAnnotation', this.annotation);
  }

  public removeRelationsForNerTagging(nerTaggingId: string) {
    this.relationTaggings = this.relationTaggings.filter(
      (relationTagging) =>
        relationTagging.begin.id !== nerTaggingId &&
        relationTagging.end.id !== nerTaggingId,
    );
  }

  public removeNerTagging(taggingId: string) {
    this.removeRelationsForNerTagging(taggingId);
    Vue.delete(this.nerTaggings, taggingId);
    this.$emit('setAnnotation', this.annotation);
  }

  public removeRelationTagging(taggingId: string) {
    this.relationTaggings = this.relationTaggings.filter(
      (relationTagging) => relationTagging.id !== taggingId,
    );
    this.$emit('setAnnotation', this.annotation);
  }

  private handleKeyup(event: any) {
    if (event.key && event.key === 'Escape') {
      this.displayTagSelector = false;
      this.displayRelationSelector = false;
    }
    if (this.pendingNerTagging && event.key && this.tagsByKey[event.key]) {
      this.confirmNerTagging(this.tagsByKey[event.key]);
    }
  }

  private handleMouseUp() {
    this.displayTagSelector = false;
    this.displayRelationSelector = false;
  }

  private created() {
    this.computePreAnnotation();
  }

  private mounted() {
    window.addEventListener('keyup', this.handleKeyup);
    window.addEventListener('mouseup', this.handleMouseUp);
    this.$emit('setAnnotation', this.annotation);
  }

  private beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }
}
