import { Component, Prop, Vue } from 'vue-property-decorator';
import ITask from '@/models/interfaces/task';
import DocumentsTaskList from '@/components/DocumentsTaskList/DocumentsTaskList.vue';

@Component({
  components: { DocumentsTaskList },
})
export default class AnnotatorCard extends Vue {
  @Prop() public task!: ITask;
  @Prop() public initExpanded!: boolean;
  public showDocuments: boolean = this.initExpanded;
}
