import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import AzureSigninMixin from '@/mixins/azure-signin';

@Component({
  computed: {
    ...mapState(['azureClientId', 'azureTenantAuthority']),
  },
})
export default class MicrosoftAzureLogin extends AzureSigninMixin {
  public loading = false;

  public async signIn(): Promise<void> {
    this.loading = true;
    (await this.login()).subscribe((response: any) => {
      if (response && response.token) {
        this.loading = false;
        this.$store.commit('setBackToken', response.token);
        this.$router.push((this.$route.query.redirect as string) || '/');
      }
    });
  }
}
