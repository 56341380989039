import { Component, Prop, Vue } from 'vue-property-decorator';
import ITag from '@/illuin-annotation/models/interfaces/tag';

@Component({
  components: {},
})
export default class AnnotatorTags extends Vue {
  @Prop() public tags!: ITag[];
  @Prop({ default: () => [] }) public selectedTags!: ITag[];
}
