import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import IProject from '@/models/interfaces/project';
import IPool from '@/models/interfaces/pool';
import { PoolMetadataDto } from '@/models/dtos/pool-metadata.dto';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';

@Component({
  computed: mapState('admin-project', ['project']),
  providers: [ApiService],
})
export default class PoolList extends Vue {
  public project!: IProject;
  public loading: boolean = false;
  @inject() public apiService!: ApiService;

  public computeAdvancement(meta: PoolMetadataDto): number {
    return (meta.annotatedDocuments / meta.annotableDocuments) * 100;
  }

  public get orderedPools() {
    return this.project.pools.sort((a: IPool, b: IPool) => {
      if (a.closed !== b.closed) {
        return a.closed ? 1 : -1;
      }
      return a.name < b.name ? -1 : 1;
    });
  }

  public closePool(pool: IPool) {
    this.apiService.closePool(pool.id).subscribe((closedPool) => {
      this.$store.dispatch('onPoolUpdated', {
        pool: closedPool,
        projectId: closedPool.project.id,
      });
    });
  }

  public reopenPool(pool: IPool) {
    this.apiService.openPool(pool.id).subscribe((openPool) => {
      this.$store.dispatch('onPoolUpdated', {
        pool: openPool,
        projectId: openPool.project.id,
      });
    });
  }

  public created() {
    this.apiService
      .getPoolsByProject(this.project.id, true)
      .subscribe((pools) => {
        this.$store.commit('admin-project/updatePools', pools);
      });
  }
}
