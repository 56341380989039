import IRelation from '@/illuin-annotation/models/interfaces/relation';

const getAvailableRelations = (
  relations: IRelation[],
  leftTagId: string,
  rightTagId: string,
) => {
  return relations.filter(
    (relation) =>
      relation.leftTagsIds.indexOf(leftTagId) > -1 &&
      relation.rightTagsIds.indexOf(rightTagId) > -1,
  );
};

export { getAvailableRelations };
