import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import NERRelationAnnotator from '@/illuin-annotation/components/NERRelation/NERRelationAnnotator/NERRelationAnnotator.vue';
import ObjectDetectionAnnotator from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotator/ObjectDetectionAnnotator.vue';
import IAnnotationValue from '@/illuin-annotation/models/types/annotation';
import ProjectType from '@/models/enums/project-type';
import QAAnnotator from '@/illuin-annotation/components/QA/QAAnnotator/QAAnnotator.vue';
import ClassificationAnnotator from '@/illuin-annotation/components/Classification/ClassificationAnnotator/ClassificationAnnotator.vue';
import IDocument from '@/models/interfaces/document';
import OCRAnnotator from '@/illuin-annotation/components/OCR/OCRAnnotator/OCRAnnotator.vue';

@Component({})
export default class Annotator extends Vue {
  @Prop() public type!: ProjectType;
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: any;
  @Prop() public datamodel!: IDataModel;
  @Prop({ default: false }) public passive!: boolean;
  @Prop({ default: null }) public preAnnotation!: IAnnotationValue | null;

  public annotatorComponentByName: { [key: string]: VueConstructor<Vue> } = {
    [ProjectType.NER]: NERRelationAnnotator,
    [ProjectType.NER_RELATIONS]: NERRelationAnnotator,
    [ProjectType.TEXT_CLASSIFICATION]: ClassificationAnnotator,
    [ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL]: ClassificationAnnotator,
    [ProjectType.IMAGE_CLASSIFICATION]: ClassificationAnnotator,
    [ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL]: ClassificationAnnotator,
    [ProjectType.OBJECT_DETECTION]: ObjectDetectionAnnotator,
    [ProjectType.QA]: QAAnnotator,
    [ProjectType.OCR]: OCRAnnotator,
  };

  get componentName(): VueConstructor<Vue> {
    return this.annotatorComponentByName[this.type];
  }
}
