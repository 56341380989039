import INERToken, {
  INERFullToken,
} from '@/illuin-annotation/models/interfaces/ner-token';
import ILine from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/line';

const getEnrichedTokens = (
  maxwidth: number,
  tokens: INERToken[],
): INERFullToken[] => {
  let currentSentenceIdx = -1;
  let yOffset = 0;
  let previousToken!: INERFullToken;
  return tokens.reduce((acc: INERFullToken[], token) => {
    let x;
    let endX;
    if (token.sentenceIdx > currentSentenceIdx) {
      x = 0;
      endX = token.word.length;
      currentSentenceIdx = token.sentenceIdx;
    } else {
      x =
        previousToken.endX +
        token.cumulatedX -
        (previousToken.cumulatedX + previousToken.word.length);
      endX = x + token.word.length;
      if (endX > maxwidth && x > 0) {
        yOffset += 1;
        x = 0;
        endX = token.word.length;
      }
    }

    const newToken: INERFullToken = {
      ...token,
      x,
      endX,
      y: token.sentenceIdx + yOffset,
    };
    previousToken = newToken;

    acc.push(newToken);

    return acc;
  }, []);
};

const getLinesData = (tokens: INERFullToken[]): ILine[] => {
  let currentY: number = 0;
  const lines = [];
  let currentLine: INERFullToken[] = [];
  for (const token of tokens) {
    if (token.y === currentY) {
      currentLine.push(token);
    } else {
      lines.push({
        y: currentY,
        tokens: currentLine,
      });
      currentY += 1;
      while (currentY < token.y) {
        lines.push({ y: currentY, tokens: [] });
        currentY += 1;
      }
      currentY = token.y;
      currentLine = [token];
    }
  }
  lines.push({
    y: currentY,
    tokens: currentLine,
  });
  return lines;
};

export { getEnrichedTokens, getLinesData };
