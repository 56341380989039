import { Component, Vue } from 'vue-property-decorator';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import { SimpleAuthDto } from '@/models/dtos/simple-auth.dto';

@Component({
  providers: [ApiService],
})
export default class SimpleLogin extends Vue {
  public simpleAuth: SimpleAuthDto = {
    email: '',
    password: '',
  };
  public alerts: string[] = [];

  @inject() private readonly apiService!: ApiService;

  public onSubmit() {
    this.alerts = [];
    this.apiService.simpleAuthenticate(this.simpleAuth).subscribe(
      (response) => {
        if (response && response.token) {
          this.$store.commit('setBackToken', response.token);
          this.$router.push((this.$route.query.redirect as string) || '/');
        }
      },
      (err) => {
        this.alerts.push(this.$t('alert.login.error') as string);
      },
    );
  }
}
