import { Component, Mixins } from 'vue-property-decorator';
import BaseNav from '@/layouts/BaseNav/BaseNav.vue';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import IProject from '@/models/interfaces/project';
import { mapState } from 'vuex';
import UserMixin from '@/mixins/user';
import ITask from '@/models/interfaces/task';

@Component({
  computed: mapState(['projects', 'tasks']),
  components: {
    BaseNav,
    PastelIcon,
  },
})
export default class ProjectList extends Mixins(UserMixin) {
  public projects!: IProject[];
  public tasks!: ITask[];

  public editProject(index: number) {
    this.$router.push({
      name: 'project-settings',
      params: { id: String(index) },
    });
  }

  get projectsList() {
    return this.projects;
  }

  public projectHasTask(projectId: string) {
    return this.tasks.some(
      (task) => !task.closed && task.project.id === projectId,
    );
  }
}
