var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"classes-tags"},_vm._l((_vm.tags),function(tag){return _c('div',{staticClass:"classes-tags__tag",class:{'classes-tags__tag--selected': _vm.commonTagIds.includes(tag.id),
                'classes-tags__tag--mismatch classes-tags__tag--mismatch--left': _vm.mismatchTagIdsList[0].includes(tag.id),
                'classes-tags__tag--mismatch classes-tags__tag--mismatch--right': _vm.mismatchTagIdsList[1].includes(tag.id),
                'classes-tags__tag--mismatch--hide-border':
                  (_vm.previewStrategy === _vm.PreviewStrategy.None && !_vm.commonTagIds.includes(tag.id)) ||
                  (_vm.previewStrategy === _vm.PreviewStrategy.Right && _vm.mismatchTagIdsList[0].includes(tag.id)) ||
                  (_vm.previewStrategy === _vm.PreviewStrategy.Left && _vm.mismatchTagIdsList[1].includes(tag.id)),
                'classes-tags__tag--mismatch--highlight-border': _vm.previewStrategy !== null,
       },on:{"click":function($event){return _vm.$emit('selectTag', tag)}}},[_c('div',{staticClass:"classes-tags__tag__wrapper"},[_c('div',{staticClass:"classes-tags__tag__wrapper__shortcut"},[_vm._v(" "+_vm._s(tag.shortcut)+" ")]),_c('div',{staticClass:"classes-tags__tag__wrapper__label",style:({background: tag.color})},[_vm._v(" "+_vm._s(tag.title)+" ")])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }