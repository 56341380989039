import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { LoginMethod } from '@/store/store';
import GoogleLogoutButton from '@/components/LogoutButton/GoogleLogoutButton/GoogleLogoutButton.vue';
import SimpleLogoutButton from '@/components/LogoutButton/SimpleLogoutButton/SimpleLogoutButton.vue';
import AzureLogoutButton from '@/components/LogoutButton/AzureLogoutButton/AzureLogoutButton.vue';

@Component({
  computed: {
    ...mapState(['loginMethod']),
  },
})
export default class LogoutButton extends Vue {
  public loginMethod!: LoginMethod;

  public get logoutButtonComponent() {
    switch (this.loginMethod) {
      case LoginMethod.GOOGLE:
        return GoogleLogoutButton;
      case LoginMethod.AZURE:
        return AzureLogoutButton;
      case LoginMethod.SIMPLE:
      default:
        return SimpleLogoutButton;
    }
  }
}
