import { Component, Vue } from 'vue-property-decorator';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import IUser from '@/models/interfaces/user';
import PlatformRolesForm from '@/components/PlatformRolesForm/PlatformRolesForm.vue';
import UserRoleList from '@/components/UseRoleList/UserRoleList.vue';
import { UserRoleDto } from '@/models/dtos/user-role.dto';
import Roles, { roleToText } from '@/models/enums/user-roles';

@Component({
  providers: [ApiService],
  components: { PlatformRolesForm, UserRoleList },
})
export default class PlatformRoles extends Vue {
  public loading: boolean = false;
  public userList!: IUser[];
  public admins: IUser[] = [];

  public roleOptions = [
    {
      value: Roles.PLATFORM_ADMIN,
      text: roleToText[Roles.PLATFORM_ADMIN],
    },
    {
      value: Roles.PLATFORM_PROJECT_CREATOR,
      text: roleToText[Roles.PLATFORM_PROJECT_CREATOR],
    },
  ];

  @inject() private readonly apiService!: ApiService;

  public created() {
    this.loading = true;
    this.apiService.getPlatformUsers().subscribe((users) => {
      this.userList = users;
      this.loading = false;
      this.fetchAdmins();
    });
  }

  public handleDelete(user: IUser) {
    this.apiService
      .deletePlatformRoleAssignement({ user: user.id, role: user.role! })
      .subscribe(this.onRoleChanged);
  }

  public onSubmit(userRole: UserRoleDto) {
    this.apiService
      .postPlatformRoleAssignement(userRole)
      .subscribe(this.onRoleChanged);
  }

  public onRoleChanged() {
    this.$store.dispatch('refreshPermissions');
    this.fetchAdmins();
  }

  public fetchAdmins() {
    this.apiService.getPlatformAdministrators().subscribe((admins) => {
      this.admins = admins;
    });
  }
}
