import { Component, Vue } from 'vue-property-decorator';
import PoolSettings from '@/components/PoolSettings/PoolSettings.vue';
import { mapState } from 'vuex';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import { UpdatePoolDto } from '@/models/dtos/update-pool.dto';

@Component({
  computed: mapState('pool-admin', ['pool']),
  components: { PoolSettings },
  providers: [ApiService],
})
export default class PoolAdminSettings extends Vue {
  public pool!: PoolDashboardDto;
  @inject() private readonly apiService!: ApiService;

  public onOpenPool() {
    this.$router.push({
      name: 'pool-admin-dashboard',
      params: this.$route.params,
    });
    this.apiService.openPool(this.pool.id).subscribe((pool) => {
      this.$store.dispatch('onPoolUpdated', {
        pool,
        projectId: pool.project.id,
      });
    });
  }

  public onDeletePool() {
    if (this.pool) {
      this.$router.push({
        name: 'project-admin-pools',
        params: { id: this.pool.project.id },
      });
      this.apiService.deletePool(this.pool.id).subscribe(() => {
        this.$store.dispatch('onPoolDeleted', this.pool);
      });
    }
  }

  public onClosePool() {
    if (this.pool) {
      this.$router.push({
        name: 'pool-admin-dashboard',
        params: { id: this.pool.project.id },
      });
      this.apiService.closePool(this.pool.id).subscribe((pool) => {
        this.$store.dispatch('onPoolUpdated', {
          pool,
          projectId: pool.project.id,
        });
      });
    }
  }

  public onPoolEdit(poolUpdate: UpdatePoolDto) {
    this.apiService.updatePool(this.pool.id, poolUpdate).subscribe((pool) => {
      this.$store.dispatch('onPoolUpdated', {
        pool,
        projectId: pool.project.id,
      });
    });
  }
}
