import { AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { Observable, throwError } from 'rxjs';
import store from '@/store/store';
import IProject, { INewProject } from '@/models/interfaces/project';
import IAnnotation, { INewAnnotation } from '@/models/interfaces/annotation';
import HttpClient from '@/utils/http-client';
import router from '@/router';
import { injectable } from 'vue-typescript-inject';
import IImport from '@/models/interfaces/import';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import ITask from '@/models/interfaces/task';
import IPool from '@/models/interfaces/pool';
import { ICreatePool } from '@/models/interfaces/create-pool';
import IUser from '@/models/interfaces/user';
import { ProjectMetadataDto } from '@/models/dtos/project-metadata.dto';
import { PoolMetadataDto } from '@/models/dtos/pool-metadata.dto';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';
import IDocument from '@/models/interfaces/document';
import { UserRoleDto } from '@/models/dtos/user-role.dto';
import { IUserRole } from '@/models/interfaces/user-role';
import { IPermissions } from '@/models/interfaces/permissions';
import PoolScoreDto from '@/illuin-annotation/models/dtos/pool-score';
import { DocumentsMetadataDto } from '@/models/dtos/document-metadata.dto';
import { UpdatePoolDto } from '@/models/dtos/update-pool.dto';
import ProjectDashboardDto from '@/models/dtos/project-dashboard.dto';
import { TranslateResult } from 'vue-i18n';
import { catchError, map } from 'rxjs/operators';
import Vue from 'vue';
import { i18n } from '@/plugins/i18n';
import { SimpleAuthDto } from '@/models/dtos/simple-auth.dto';
import IExport from '@/models/interfaces/export';
import { CreateExportDto } from '@/models/dtos/create-export.dto';

interface INotificationOptions {
  onError?: TranslateResult;
  onSuccess?: TranslateResult;
}

@injectable()
export default class ApiService {
  private BACK_URL: string;
  private httpClient: HttpClient;

  constructor(backUrl: string | null = null) {
    this.BACK_URL = backUrl || store.state.backUrl;
    this.httpClient = new HttpClient();
    this.httpClient.addRequestInterceptor((options: Partial<AjaxRequest>) => {
      const defaultHeaders: { [key: string]: string } = {
        Authorization: `Bearer ${store.getters.backToken}`,
      };
      if (!(options.body instanceof FormData)) {
        defaultHeaders['Content-Type'] = 'application/json';
      }
      return {
        url: this.BACK_URL + options.url,
        method: options.method,
        headers: {
          ...defaultHeaders,
          ...options.headers,
        },
        body: options.body || null,
        responseType: options.responseType || 'json',
      };
    });
    this.httpClient.addResponseInterceptor(
      (response: Partial<AjaxResponse>) => {
        if (response.status === 401) {
          router.push({
            name: 'login',
            query: { redirect: router.currentRoute.fullPath },
          });
        }
        return response;
      },
    );
  }

  public performCall<T>(
    requestOptions: AjaxRequest,
    notificationOptions: INotificationOptions = {},
  ): Observable<T> {
    return this.httpClient.request(requestOptions).pipe(
      catchError((err) => {
        if (notificationOptions.onError) {
          Vue.notify({
            group: 'app',
            type: 'error',
            text: notificationOptions.onError as string,
          });
        }
        return throwError(err);
      }),
      map((response: T) => {
        if (notificationOptions.onSuccess) {
          Vue.notify({
            group: 'app',
            type: 'success',
            text: notificationOptions.onSuccess as string,
          });
        }
        return response;
      }),
    );
  }

  public authenticate(
    googleAccessToken: string,
  ): Observable<{ token: string }> {
    return this.httpClient.request({
      url: 'auth/google',
      method: 'POST',
      headers: {},
      body: { accessToken: googleAccessToken },
    });
  }

  public azureAuthenticate(
    azureAccessToken: string,
  ): Observable<{ token: string }> {
    return this.httpClient.request({
      url: 'auth/azure',
      method: 'POST',
      headers: {},
      body: { accessToken: azureAccessToken },
    });
  }

  public simpleAuthenticate(
    simpleAuth: SimpleAuthDto,
  ): Observable<{ token: string }> {
    return this.httpClient.request({
      url: 'auth/simple',
      method: 'POST',
      body: simpleAuth,
    });
  }

  public getPermissions(): Observable<IPermissions> {
    return this.httpClient.request({
      url: 'roles/user/me',
      method: 'GET',
    });
  }

  public getUser(): Observable<IUser> {
    return this.httpClient.request({
      url: 'users/me',
      method: 'GET',
    });
  }

  public getPlatformProjects(): Observable<IProject[]> {
    return this.httpClient.request({
      url: 'projects/all',
      method: 'GET',
    });
  }

  public getProjectList(): Observable<IProject[]> {
    return this.httpClient.request({
      url: 'projects',
      method: 'GET',
    });
  }

  public createProject(project: INewProject): Observable<IProject> {
    return this.performCall(
      {
        url: 'projects',
        method: 'POST',
        body: project,
      },
      {
        onError: i18n.t('alert.project.creation.error'),
      },
    );
  }

  public updateProjectDatamodel(
    project: IProject,
    datamodel: IDataModel,
  ): Observable<IDataModel> {
    return this.performCall(
      {
        url: `data-models/project/${project.id}`,
        method: 'PUT',
        body: datamodel,
      },
      {
        onSuccess: i18n.t('alert.datamodel.edited.success'),
        onError: i18n.t('alert.datamodel.edited.error'),
      },
    );
  }

  public importData(project: IProject, file: File): Observable<IImport> {
    const formData = new FormData();
    formData.append('data', file);
    return this.httpClient.request({
      url: `projects/${project.id}/import`,
      method: 'POST',
      body: formData,
    });
  }

  public importAwsData(project: IProject, file: File): Observable<IDocument[]> {
    const formData = new FormData();
    formData.append('data', file);
    return this.httpClient.request({
      url: `projects/${project.id}/import-aws`,
      method: 'POST',
      body: formData,
    });
  }

  public getImport(importData: IImport): Observable<IImport> {
    return this.httpClient.request({
      url: `imports/${importData.id}`,
      method: 'GET',
    });
  }

  public importAnnotations(project: IProject, file: File): Observable<IImport> {
    const formData = new FormData();
    formData.append('data', file);
    return this.httpClient.request({
      url: `projects/${project.id}/import/annotations`,
      method: 'POST',
      body: formData,
    });
  }

  public getProjectById(id: string): Observable<IProject> {
    return this.httpClient.request({
      url: `projects/${id}`,
      method: 'GET',
    });
  }

  public getProjectDashboard(
    projectId: string,
  ): Observable<ProjectDashboardDto> {
    return this.httpClient.request({
      url: `projects/${projectId}/dashboard`,
      method: 'GET',
    });
  }

  public updateProject(
    projectid: string,
    project: INewProject,
  ): Observable<IProject> {
    return this.performCall(
      {
        url: `projects/${projectid}`,
        method: 'PUT',
        body: project,
      },
      {
        onSuccess: i18n.t('alert.project.edition.success'),
        onError: i18n.t('alert.project.edition.error'),
      },
    );
  }

  public deleteProject(projectid: string): Observable<void> {
    return this.performCall(
      {
        url: `projects/${projectid}`,
        method: 'DELETE',
      },
      {
        onSuccess: i18n.t('alert.project.deletion.success'),
        onError: i18n.t('alert.project.deletion.error'),
      },
    );
  }

  public addAnnotation(annotation: INewAnnotation): Observable<IAnnotation> {
    return this.performCall(
      {
        url: 'annotations',
        method: 'POST',
        body: annotation,
      },
      {
        onError: i18n.t('alert.annotation.error'),
      },
    );
  }

  public resolveConflict(annotation: INewAnnotation): Observable<IAnnotation> {
    return this.performCall(
      {
        url: 'annotations/resolve_conflict',
        method: 'POST',
        body: annotation,
      },
      {
        onError: i18n.t('alert.conflict.resolution.error'),
      },
    );
  }

  public getAnnotationById(id: string): Observable<IAnnotation> {
    return this.httpClient.request({
      url: `annotations/${id}`,
      method: 'GET',
    });
  }

  public getPoolsByProject(
    projectId: string,
    metadata: boolean = false,
  ): Observable<PoolMetadataDto[]> {
    return this.httpClient.request({
      url: `pools?projectId=${projectId}&metaData=${metadata}`,
      method: 'GET',
    });
  }

  public getPoolDashboard(poolId: string): Observable<PoolDashboardDto> {
    return this.httpClient.request({
      url: `pools/${poolId}/dashboard`,
      method: 'GET',
    });
  }

  public createPool(pool: ICreatePool): Observable<IPool> {
    return this.performCall(
      {
        url: 'pools',
        method: 'POST',
        body: pool,
      },
      {
        onSuccess: i18n.t('alert.pool.creation.success'),
        onError: i18n.t('alert.pool.creation.error'),
      },
    );
  }

  public deletePool(poolId: string): Observable<IPool> {
    return this.performCall(
      {
        url: `pools/${poolId}`,
        method: 'DELETE',
      },
      {
        onSuccess: i18n.t('alert.pool.deletion.success'),
        onError: i18n.t('alert.pool.deletion.error'),
      },
    );
  }

  public closePool(poolId: string): Observable<IPool> {
    return this.performCall(
      {
        url: `pools/${poolId}/close`,
        method: 'POST',
      },
      {
        onSuccess: i18n.t('alert.pool.closing.success'),
        onError: i18n.t('alert.pool.closing.error'),
      },
    );
  }

  public openPool(poolId: string): Observable<IPool> {
    return this.performCall(
      {
        url: `pools/${poolId}/open`,
        method: 'POST',
      },
      {
        onSuccess: i18n.t('alert.pool.opening.success'),
        onError: i18n.t('alert.pool.opening.error'),
      },
    );
  }

  public updatePool(
    poolId: string,
    poolUpdate: UpdatePoolDto,
  ): Observable<IPool> {
    return this.performCall(
      {
        url: `pools/${poolId}`,
        method: 'PUT',
        body: poolUpdate,
      },
      {
        onSuccess: i18n.t('alert.pool.editing.success'),
        onError: i18n.t('alert.pool.editing.error'),
      },
    );
  }

  public getProjectMetadatas(
    project: IProject,
  ): Observable<ProjectMetadataDto> {
    return this.httpClient.request({
      url: `projects/${project.id}/free-documents-count`,
      method: 'GET',
    });
  }

  public getTasksByPool(
    poolId: string,
    metadata: boolean = false,
  ): Observable<TaskMetadataDto[]> {
    return this.httpClient.request({
      url: `tasks?poolId=${poolId}&metaData=${metadata}`,
      method: 'GET',
    });
  }

  public getDocumentsByProject(projectId: string): Observable<IDocument[]> {
    return this.httpClient.request({
      url: `projects/${projectId}/documents`,
      method: 'GET',
    });
  }

  public getDocumentsForPool(
    poolId: string,
    conflictOnly: boolean = false,
  ): Observable<IDocument[]> {
    return this.httpClient.request({
      url: `pools/${poolId}/documents?conflictOnly=${conflictOnly}`,
      method: 'GET',
    });
  }

  public getDocumentsForTask(
    taskId: string,
    conflictOnly: boolean = false,
  ): Observable<IDocument[]> {
    return this.httpClient.request({
      url: `tasks/${taskId}/documents?conflictOnly=${conflictOnly}`,
      method: 'GET',
    });
  }

  public getDocumentsMetadataForTask(
    taskId: string,
  ): Observable<DocumentsMetadataDto[]> {
    return this.httpClient.request({
      url: `tasks/${taskId}/documents`,
      method: 'GET',
    });
  }

  public loadDocumentById(documentId: string): Observable<any> {
    return this.httpClient.request({
      url: `documents/${documentId}/load`,
      method: 'GET',
    });
  }

  public getAnnotationsForDocument(
    documentId: string,
    userOnly: boolean,
  ): Observable<IAnnotation[]> {
    return this.httpClient.request({
      url: `documents/${documentId}/annotations?userOnly=${userOnly}`,
      method: 'GET',
    });
  }

  public getMyTasks(
    metadata: boolean = false,
    openOnly: boolean = false,
  ): Observable<TaskMetadataDto[]> {
    return this.httpClient.request({
      url: `tasks/me?metaData=${metadata}&openOnly=${openOnly}`,
      method: 'GET',
    });
  }

  public getTask(taskId: string): Observable<ITask> {
    return this.httpClient.request({
      url: `tasks/${taskId}`,
      method: 'GET',
    });
  }

  public getPlatformUsers(): Observable<IUser[]> {
    return this.httpClient.request({
      url: 'users/all',
      method: 'GET',
    });
  }

  public getPlatformAdministrators(): Observable<IUser[]> {
    return this.httpClient.request({
      url: 'users/platform-administrators',
      method: 'GET',
    });
  }

  public getProjectUsers(project: IProject): Observable<IUser[]> {
    return this.httpClient.request({
      url: `users?projectId=${project.id}`,
      method: 'GET',
    });
  }

  public postRoleAssignment(userRole: UserRoleDto): Observable<IUserRole> {
    return this.performCall(
      {
        url: 'roles/project',
        method: 'POST',
        body: userRole,
      },
      {
        onSuccess: i18n.t('alert.role.assignment.success'),
        onError: i18n.t('alert.role.assignment.error'),
      },
    );
  }

  public postPlatformRoleAssignement(
    userRole: UserRoleDto,
  ): Observable<IUserRole> {
    return this.performCall(
      {
        url: 'roles/platform',
        method: 'POST',
        body: userRole,
      },
      {
        onSuccess: i18n.t('alert.role.assignment.success'),
        onError: i18n.t('alert.role.assignment.error'),
      },
    );
  }

  public deleteRoleAssignment(userRole: UserRoleDto): Observable<UserRoleDto> {
    return this.performCall(
      {
        url: 'roles/project',
        method: 'DELETE',
        body: userRole,
      },
      {
        onSuccess: i18n.t('alert.role.unassignment.success'),
        onError: i18n.t('alert.role.unassignment.error'),
      },
    );
  }

  public deletePlatformRoleAssignement(
    userRole: UserRoleDto,
  ): Observable<UserRoleDto> {
    return this.performCall(
      {
        url: 'roles/platform',
        method: 'DELETE',
        body: userRole,
      },
      {
        onSuccess: i18n.t('alert.role.unassignment.success'),
        onError: i18n.t('alert.role.unassignment.error'),
      },
    );
  }

  public getPoolScore(poolId: string): Observable<PoolScoreDto> {
    return this.httpClient.request({
      url: `scores?poolId=${poolId}`,
      method: 'GET',
    });
  }

  public newProjectExport(
    projectId: string,
    createExportDto: CreateExportDto,
  ): Observable<IExport> {
    return this.performCall(
      {
        url: `projects/${projectId}/export`,
        method: 'POST',
        body: createExportDto,
      },
      {
        onSuccess: i18n.t('alert.export.start.success'),
        onError: i18n.t('alert.export.start.error'),
      },
    );
  }

  public getProjectExports(projectId: string): Observable<IExport[]> {
    return this.httpClient.request({
      url: `exports/project/${projectId}`,
      method: 'GET',
    });
  }

  public downloadExport(exportId: string) {
    return this.httpClient.request({
      url: `exports/download/${exportId}`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  public createAccount(account: {
    email: string;
    password: string;
  }): Observable<IUser> {
    return this.performCall(
      {
        url: 'users',
        method: 'POST',
        body: account,
      },
      {
        onError: i18n.t('alert.account.creation.error'),
        onSuccess: i18n.t('alert.account.creation.success'),
      },
    );
  }

  public getPlatformAccounts(): Observable<IUser[]> {
    return this.httpClient.request({
      url: 'users/accounts',
      method: 'GET',
    });
  }
}

export const apiService = new ApiService(process.env.VUE_APP_BACK_URL);
