import { Component, Prop, Vue } from 'vue-property-decorator';
import ITag from '@/illuin-annotation/models/interfaces/tag';

@Component
export default class PieTagSelector extends Vue {
  @Prop() public tags!: ITag[];

  public angle: number = (2 * Math.PI) / this.tags.length;

  public get arcsCoordinates(): number[][][] {
    const radius: number = 100;
    const size: number = this.tags.length;
    const arcsCoordinates: number[][][] = [];
    for (let i = 1; i <= size; i += 1) {
      arcsCoordinates.push([
        [
          radius * Math.sin((i - 1) * this.angle),
          -radius * Math.cos((i - 1) * this.angle),
        ],
        [radius * Math.sin(i * this.angle), -radius * Math.cos(i * this.angle)],
      ]);
    }
    return arcsCoordinates;
  }
}
