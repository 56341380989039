import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import TextPreviewer from '@/illuin-annotation/components/common/TextPreviewer/TextPreviewer.vue';
import ImagePreviewer from '@/illuin-annotation/components/common/ImagePreviewer/ImagePreviewer.vue';
import ProjectType from '@/models/enums/project-type';

@Component({})
export default class Previewer extends Vue {
  @Prop() public type!: string;
  @Prop() public documentValue!: any;

  public previewerComponentByName: { [key: string]: VueConstructor<Vue> } = {
    [ProjectType.NER]: TextPreviewer,
    [ProjectType.NER_RELATIONS]: TextPreviewer,
    [ProjectType.TEXT_CLASSIFICATION]: TextPreviewer,
    [ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL]: TextPreviewer,
    [ProjectType.IMAGE_CLASSIFICATION]: ImagePreviewer,
    [ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL]: ImagePreviewer,
    [ProjectType.OBJECT_DETECTION]: ImagePreviewer,
    [ProjectType.QA]: TextPreviewer,
    [ProjectType.OCR]: ImagePreviewer,
  };

  get componentName(): VueConstructor<Vue> {
    return this.previewerComponentByName[this.type];
  }
}
