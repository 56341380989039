import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import IProject from '@/models/interfaces/project';
import AssignRoleForm from '@/components/AssignRoleForm/AssignRoleForm.vue';
import UserRoleList from '@/components/UseRoleList/UserRoleList.vue';
import { UserRoleDto } from '@/models/dtos/user-role.dto';
import { inject } from 'vue-typescript-inject';
import ResourceType from '@/models/enums/resource-type';
import IUser from '@/models/interfaces/user';
import Roles, { roleToText } from '@/models/enums/user-roles';

@Component({
  computed: mapState('admin-project', ['project']),
  providers: [ApiService],
  components: { AssignRoleForm, UserRoleList },
})
export default class ProjectRoles extends Vue {
  public project!: IProject;
  public userList: IUser[] = [];
  public readonly roleOptions = [
    { value: Roles.PROJECT_ADMIN, text: roleToText[Roles.PROJECT_ADMIN] },
    {
      value: Roles.PROJECT_SENIOR_ANNOTATOR,
      text: roleToText[Roles.PROJECT_SENIOR_ANNOTATOR],
    },
    {
      value: Roles.PROJECT_JUNIOR_ANNOTATOR,
      text: roleToText[Roles.PROJECT_JUNIOR_ANNOTATOR],
    },
  ];
  @inject() private readonly apiService!: ApiService;

  public handleSubmit(userRole: UserRoleDto) {
    userRole.ressourceType = ResourceType.PROJECT;
    userRole.ressourceId = this.project.id;
    this.apiService.postRoleAssignment(userRole).subscribe(this.onRoleChanged);
  }

  public handleDelete(user: IUser) {
    const userRole: UserRoleDto = {
      user: user.id,
      role: user.role!,
      ressourceType: ResourceType.PROJECT,
      ressourceId: this.project.id,
    };
    this.apiService
      .deleteRoleAssignment(userRole)
      .subscribe(this.onRoleChanged);
  }

  public fetchUsers() {
    this.apiService.getProjectUsers(this.project).subscribe((users) => {
      this.userList = users;
    });
  }

  public onRoleChanged() {
    this.$store.dispatch('refreshPermissions');
    this.fetchUsers();
  }

  public created() {
    this.fetchUsers();
  }
}
