import { Selection } from 'd3-selection';
import { ScaleLinear } from 'd3-scale';

const offsetPositions = (
  selection: Selection<SVGGElement, any, SVGGElement, any>,
  relationHeightByLine: number[],
  yScale: ScaleLinear<number, number>,
) => {
  if (selection) {
    const cumulativeRelationHeightByLine: number[] = [];
    relationHeightByLine.reduce(
      (prev, curr, i) => (cumulativeRelationHeightByLine[i] = prev + curr),
      0,
    );
    selection.attr('transform', (d) => {
      return `translate(0 ${yScale(
        +d.y + (cumulativeRelationHeightByLine[d.y] || 0) / 2.5,
      )})`;
    });
  }
};

export { offsetPositions };
