import { Component, Vue } from 'vue-property-decorator';
import ICreateUser from '@/models/interfaces/create-user';

@Component({})
export default class CreateAccountForm extends Vue {
  public createUser: ICreateUser = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  };

  public onPasswordInput() {
    const passwordInput = this.$refs.password as HTMLInputElement;
    passwordInput.setCustomValidity('');
  }

  public onSubmit() {
    const passwordInput = this.$refs.password as HTMLInputElement;
    if (this.createUser.password.length < 8) {
      passwordInput.setCustomValidity(
        this.$t('create-account.invalid-password') as string,
      );
      passwordInput.reportValidity();
      return;
    }
    passwordInput.setCustomValidity('');
    this.$emit('submit', this.createUser);
  }
}
