import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ITask from '@/models/interfaces/task';
import { mapState } from 'vuex';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import IDocument from '@/models/interfaces/document';
import { inject } from 'vue-typescript-inject';
import ApiService from '@/services/api';
import IAnnotation, { IAnnovationValue } from '@/models/interfaces/annotation';
import Annotator from '@/illuin-annotation/factories/Annotator/Annotator.vue';

@Component({
  computed: mapState('pool-admin', ['pool']),
  providers: [ApiService],
  components: {
    Annotator,
  }
})
export default class DocumentsTaskList extends Vue {
  public pool!: PoolDashboardDto;
  public previewedIndex: number | null = null;
  public previewedDocument: IDocument | null = null;
  public previewedDocumentValue: any = null;
  public previewedAnnotationValue: IAnnovationValue | null = null;
  @Prop() public task!: ITask;
  @Prop({ default: false }) public myDocuments!: boolean;

  @inject() private apiService!: ApiService;

  public get modalTitle(): string {
    return this.previewedDocument ? this.previewedDocument.name : '';
  }

  public viewEntry(index: number) {
    this.previewedIndex = index;
  }

  @Watch('previewedIndex', { immediate: true })
  public onPreviewedIndexChange() {
    this.previewedDocument = null;
    this.previewedDocumentValue = null;
    this.previewedAnnotationValue = null;
    if (this.previewedIndex !== null) {
      const document = this.task.documents[this.previewedIndex];
      this.previewedDocument = document;
      this.$bvModal.show(`annotationPreviewModal${this.task.id}`);
      this.apiService
          .loadDocumentById(document.id)
          .subscribe((documentValue: any) => {
            this.previewedDocumentValue = documentValue;
          });
      if(document.annotationId) {
        this.apiService
            .getAnnotationById(document.annotationId)
            .subscribe((annotation) => {
              this.previewedAnnotationValue = annotation.value;
            });
      }
    }
  }

  public entryHidden() {
    this.previewedIndex = null;
    this.previewedDocument = null;
    this.previewedDocumentValue = null;
    this.previewedAnnotationValue = null;
  }

  private handleKeyup(event: any) {
    if (this.previewedIndex !== null) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          if (this.previewedIndex < this.task.documents.length - 1) {
            this.previewedIndex += 1;
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (this.previewedIndex > 0) {
            this.previewedIndex -= 1;
          }
          break;
      }
    }
  }

  private mounted() {
    window.addEventListener('keyup', this.handleKeyup);
  }

  private beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup);
  }
}
