import { Component, Vue } from 'vue-property-decorator';
import MyTasks from '@/components/MyTasks/MyTasks.vue';
import BaseNav from '@/layouts/BaseNav/BaseNav.vue';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import IUser from '@/models/interfaces/user';

@Component({
  providers: [ApiService],
  components: { BaseNav, MyTasks },
})
export default class Homepage extends Vue {
  public user: IUser | null = null;
  public loading: boolean = true;
  @inject() private readonly apiService!: ApiService;

  public created() {
    this.loading = true;

    this.apiService.getUser().subscribe((user: IUser) => {
      this.user = user;
      this.loading = false;
    });
  }
}
