import { Component, Prop, Vue } from 'vue-property-decorator';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import { VueConstructor } from 'vue';
import NerDisplay from '@/illuin-annotation/components/NER/NerDisplay/NerDisplay.vue';
import ProjectType from '@/models/enums/project-type';
import NerRelationsDisplay from '@/illuin-annotation/components/NERRelation/NerRelationsDisplay/NerRelationsDisplay.vue';

@Component({})
export default class DataModelDisplay extends Vue {
  @Prop() public datamodel!: IDataModel;
  @Prop() public type!: string;

  public formComponentByName: { [key: string]: VueConstructor<Vue> } = {
    [ProjectType.NER]: NerDisplay,
    [ProjectType.NER_RELATIONS]: NerRelationsDisplay,
    [ProjectType.TEXT_CLASSIFICATION]: NerDisplay,
    [ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL]: NerDisplay,
    [ProjectType.IMAGE_CLASSIFICATION]: NerDisplay,
    [ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL]: NerDisplay,
    [ProjectType.OBJECT_DETECTION]: NerDisplay,
    [ProjectType.OCR]: NerDisplay,
  };

  get componentName(): VueConstructor<Vue> {
    return this.formComponentByName[this.type];
  }
}
