import Vue from 'vue';
import { RootState } from '@/store/store';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import IDocument from '@/models/interfaces/document';
import ITask from '@/models/interfaces/task';

interface AnnotationState {
  task: ITask | null;
  documents: IDocument[];
  cachedAnnotations: { [key: string]: { duration: number, value: any } };
  currentDocumentIdx: number | null;
}

const getDefaultState = (): AnnotationState => {
  return {
    task: null,
    documents: [],
    cachedAnnotations: {},
    currentDocumentIdx: null,
  };
};

const storeState: AnnotationState = getDefaultState();

const storeGetters: GetterTree<AnnotationState, RootState> = {};

const storeMutations: MutationTree<AnnotationState> = {
  init(state) {
    Object.assign(state, getDefaultState());
  },
  setTask(state, task) {
    state.task = task;
  },
  setDocuments(state, documents) {
    state.documents = documents;
  },
  setDocumentAnnotationId(state, { idx, annotationId }) {
    const updatedDocument = state.documents[idx];
    updatedDocument.annotationId = annotationId;
    updatedDocument.hasConflict = false;
    Vue.set(state.documents, idx, updatedDocument);
  },
  setDocumentAnnotationErrored(state, { idx, errored }) {
    const updatedDocument = state.documents[idx];
    updatedDocument.annotationErrored = errored;
    Vue.set(state.documents, idx, updatedDocument);
  },
  setCachedAnnotation(
    state,
    payload: { documentId: string; annotationValue: any; duration: number; },
  ) {
    Vue.set(
      state.cachedAnnotations,
      payload.documentId,
      {
        duration: payload.duration,
        value: payload.annotationValue,
      },
    );
  },
  setCurrentDocumentIndex(state, index) {
    state.currentDocumentIdx = index;
  },
};

const storeActions: ActionTree<AnnotationState, RootState> = {};

const annotationStore = {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};

export default annotationStore;
