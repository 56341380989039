import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AnnotationsImportForm extends Vue {
  public annotationsFile: any = null;
  @Prop({ default: false }) public creation!: boolean;

  public onSubmit(event: any) {
    event.preventDefault();
    this.$emit('submit', this.annotationsFile);
  }
}
