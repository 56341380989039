import { Component, Prop, Vue } from 'vue-property-decorator';
import NerScoreValue from '@/illuin-annotation/models/dtos/score-value/ner-score-value.dto';
import { mapState } from 'vuex';
import IPool from '@/models/interfaces/pool';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import en from './lang/ner-score-array.en.json';
import fr from './lang/ner-score-array.fr.json';

@Component({
  computed: mapState('pool-admin', ['pool']),
  i18n: {
    messages: { en, fr },
  },
})
export default class NerScoreArray extends Vue {
  public pool!: IPool;
  @Prop() public scoreValue!: NerScoreValue;

  public get totalScore() {
    return this.scoreValue.total.match / this.scoreValue.total.all;
  }

  public val(tag: { match: number; all: number }) {
    return tag.match / tag.all;
  }

  public tagTitleFromId(tagId: string): string {
    if (this.pool.project && this.pool.project.dataModel) {
      for (const tag of (this.pool.project.dataModel as INerDataModel).tags) {
        if (tag.id === tagId) {
          return tag.title;
        }
      }
    }
    return '';
  }
}
