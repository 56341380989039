import { Component, Prop, Vue } from 'vue-property-decorator';
import IUser from '@/models/interfaces/user';

@Component({})
export default class UserSearchBar extends Vue {
  @Prop() public userList!: IUser[];
  public searchQuery = '';
  public focusedUserIndex: number = -1;
  public autocompleteList: IUser[] = [];
  public showAutocomplete: boolean = true;

  public autocomplete() {
    this.focusedUserIndex = -1;
    this.autocompleteList = this.userList.filter((user) => {
      const name = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;
      return name.indexOf(this.searchQuery.toLowerCase()) >= 0;
    });
  }

  public onSearchBlur() {
    if (this.focusedUserIndex >= 0) {
      this.onSelectUser(this.focusedUserIndex);
    } else {
      this.searchQuery = '';
      this.autocompleteList = [];
    }
  }

  public onSearchFocus() {
    this.showAutocomplete = true;
    if (this.searchQuery !== '') {
      this.autocomplete();
    }
  }

  public onSelectUser(index: number) {
    const user = this.autocompleteList[index];
    this.searchQuery = `${user.firstName} ${user.lastName}`;
    this.showAutocomplete = false;
    this.$emit('onSelectUser', user);
  }

  public onHoverUser(index: number) {
    this.focusedUserIndex = index;
  }

  public onLeaveUser(index: number) {
    this.focusedUserIndex = -1;
  }
}
