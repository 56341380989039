import IImageTagging from '@/illuin-annotation/models/interfaces/image-tagging';
import { BaseType, select, Selection } from 'd3';

/*
 * Shoelace formula
 * https://en.wikipedia.org/wiki/Shoelace_formula
 */
const computeArea = (tag: IImageTagging): number => {
  const vertices = tag.vertices;
  const n = vertices.length;
  let area =
    vertices[n - 1].x * vertices[0].y - vertices[0].x * vertices[n - 1].y;

  for (let i = 0; i < vertices.length - 1; i += 1) {
    area +=
      vertices[i].x * vertices[i + 1].y - vertices[i + 1].x * vertices[i].y;
  }

  return Math.abs(0.5 * area);
};

const getImageSize = (url: string): Promise<{ x: number; y: number }> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      resolve({ x: image.width, y: image.height });
    };
  });

const labelForPolygon = (
  polygon: SVGPolygonElement,
): Selection<SVGTextElement, IImageTagging, BaseType, unknown> => {
  const polygonData = select<SVGPolygonElement, IImageTagging>(polygon).datum();
  return select('g.labels')
    .selectAll<SVGTextElement, IImageTagging>('*')
    .filter((d) => {
      return d.id === polygonData.id;
    });
};

const polygonForLabel = (
    label: SVGTextElement,
): Selection<SVGPolygonElement, IImageTagging, BaseType, unknown> => {
  const labelData = select<SVGTextElement, IImageTagging>(label).datum();
  return select('g.polygons')
      .selectAll<SVGPolygonElement, IImageTagging>('*')
      .filter((d) => {
        return d.id === labelData.id;
      });
}

const getRectVerticesFromStartAndEnd = (
  start: { x: number; y: number },
  end: { x: number; y: number },
) => {
  return [
    { x: start.x, y: start.y },
    { x: end.x, y: start.y },
    { x: end.x, y: end.y },
    { x: start.x, y: end.y },
  ];
};

export {
  computeArea,
  getImageSize,
  getRectVerticesFromStartAndEnd,
  labelForPolygon,
  polygonForLabel,
};
