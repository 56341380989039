import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Collapse extends Vue {
  @Prop() public id!: string;
  @Prop() public title!: string;
  @Prop() public visible!: boolean;

  public isVisible: boolean = this.visible || false;
}
