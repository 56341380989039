import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IExport from '@/models/interfaces/export';
import downloadFile from '@/utils/download';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';

@Component({
  providers: [ApiService],
})
export default class ProjectExportsList extends Vue {
  @Prop() public exports!: IExport[];
  public fields: { [key: string]: any }[] = [];
  public waitingDownload: boolean = false;
  public isReloading = false;
  @inject() private readonly apiService!: ApiService;

  @Watch('exports')
  public onExportRefresh(): void {
    this.isReloading = false;
  }

  public created() {
    this.fields = [
      {
        key: 'name',
        label: this.$t('project-exports-list.name'),
        sortable: false,
      },
      {
        key: 'documentsCount',
        label: this.$t('project-exports-list.documents-count'),
        sortable: false,
      },
      {
        key: 'containsRawEntries',
        label: this.$t('project-exports-list.contains-entries'),
        sortable: false,
      },
      {
        key: 'createdAt',
        label: this.$t('project-exports-list.created-at'),
        sortable: true,
      },
      {
        key: 'status',
        label: this.$t('project-exports-list.status.label'),
        sortable: false,
      },
      {
        key: 'actions',
        label: this.$t('project-exports-list.actions.download'),
        sortable: false,
      },
    ];
  }

  public downloadExport(exportId: string, name: string): void {
    this.waitingDownload = true;
    this.apiService.downloadExport(exportId).subscribe((fileContent: any) => {
      downloadFile(fileContent, name, 'application/zip');
      this.waitingDownload = false;
    });
  }

  public reload(): void {
    this.isReloading = true;
    this.$emit('reload');
  }
}
