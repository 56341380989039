import { Component, Vue, Watch } from 'vue-property-decorator';
import SidebarAnnotation from '@/components/SidebarAnnotation/SidebarAnnotation.vue';
import Annotator from '@/illuin-annotation/factories/Annotator/Annotator.vue';
import { mapState } from 'vuex';
import IAnnotation from '@/models/interfaces/annotation';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import IDocument from '@/models/interfaces/document';
import ITask from '@/models/interfaces/task';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  computed: {
    ...mapState('annotation', [
      'task',
      'currentDocumentIdx',
      'cachedAnnotations',
    ]),
  },
  components: {
    SidebarAnnotation,
    Annotator,
  },
  providers: [ApiService],
})
export default class Annotation extends Vue {
  public documents: IDocument[] = [];
  public documentsValueById: { [key: string]: any } = {};
  public currentDocumentIdx!: number | null;
  public cachedAnnotations!: { [key: string]: { duration: number, value: any } };
  public loadingList: boolean = false;
  public loadingDocument: boolean = false;
  public loadingAnnotation: boolean = false;
  public goingPrevious: boolean = false;
  public skipping: boolean = false;
  public goingNext: boolean = false;
  public task!: ITask;
  public annotationValue: any = null;
  public beginTime: number = Date.now();
  @inject() private readonly apiService!: ApiService;

  public get currentDocument(): IDocument | null {
    if (this.currentDocumentIdx !== null) {
      return this.documents[this.currentDocumentIdx];
    }
    return null;
  }

  public get currentDocumentValue(): any | null {
    if (
      this.currentDocument &&
      this.documentsValueById.hasOwnProperty(this.currentDocument.id)
    ) {
      return this.documentsValueById[this.currentDocument.id];
    }
    return null;
  }

  public get currentDocumentAnnotation() {
    if (this.currentDocument) {
      return this.cachedAnnotations[this.currentDocument.id];
    }
  }

  @Watch('currentDocumentIdx')
  public onDocumentChange() {
    this.annotationValue = null;
    this.beginTime = Date.now();
    if (this.currentDocument) {
      this.fetchDocumentAndAnnotation(this.currentDocument, true);
    }
    const nextIdx = this.getNextDocumentIdx(false);
    if (nextIdx !== null) {
      this.fetchDocumentAndAnnotation(this.documents[nextIdx], false);
    }
  }

  public get loading(): boolean {
    return this.loadingList || this.loadingDocument || this.loadingAnnotation;
  }

  public fetchDocumentAndAnnotation(document: IDocument, waiting = false) {
    if (!this.documentsValueById.hasOwnProperty(document.id)) {
      this.fetchDocument(document.id, waiting);
    }
    if (document.annotationId && !this.cachedAnnotations[document.id]) {
      this.fetchAnnotationForDocument(document, waiting);
    } else {
      this.annotationValue = this.cachedAnnotations[document.id] ? this.cachedAnnotations[document.id].value : null;
    }
  }

  public fetchDocument(documentId: string, waiting = false) {
    if (waiting) {
      this.loadingDocument = true;
    }
    this.apiService.loadDocumentById(documentId).subscribe((document: any) => {
      Vue.set(this.documentsValueById, documentId, document);
      this.loadingDocument = false;
    });
  }

  public fetchAnnotationForDocument(document: IDocument, waiting = false) {
    if (waiting) {
      this.loadingAnnotation = true;
    }
    if (document && document.annotationId) {
      const documentId = document.id;
      this.apiService
        .getAnnotationById(document.annotationId)
        .subscribe((annotation) => {
          this.$store.commit('annotation/setCachedAnnotation', {
            documentId,
            duration: annotation.duration,
            annotationValue: annotation.value,
          });
          this.annotationValue = annotation.value;
          this.loadingAnnotation = false;
        });
    }
  }

  public getNextDocumentIdx(allowSame: boolean = true): number | null {
    const currentDocumentIdx =
      this.currentDocumentIdx === null ? -1 : this.currentDocumentIdx;
    for (let i = 1; i <= this.documents.length; i += 1) {
      const idx = (currentDocumentIdx + i) % this.documents.length;
      if (
        this.documents[idx].annotationId === null &&
        !this.cachedAnnotations.hasOwnProperty(this.documents[idx].id)
      ) {
        return idx === this.currentDocumentIdx && !allowSame ? null : idx;
      }
    }
    return null;
  }

  public getDocumentIndexFromId(documentId: string): number | null {
    for (let i = 0; i < this.documents.length; i += 1) {
      if (this.documents[i].id === documentId) {
        return i;
      }
    }
    return null;
  }

  public hasDocumentQuery() {
    if (!this.$route.query.documentId) {
      return false;
    }

    const idx = this.getDocumentIndexFromId(
      this.$route.query.documentId as string,
    );
    if (idx === null) {
      return false;
    }

    this.$store.commit('annotation/setCurrentDocumentIndex', idx);
    return true;
  }

  public skip() {
    this.$store.commit(
      'annotation/setCurrentDocumentIndex',
      this.getNextDocumentIdx(),
    );
  }

  public previous() {
    if (this.currentDocumentIdx && this.currentDocumentIdx > 0) {
      this.$store.commit(
        'annotation/setCurrentDocumentIndex',
        this.currentDocumentIdx - 1,
      );
    }
  }

  public next() {
    if (this.currentDocument) {
      const currentDocumentIdx = this.currentDocumentIdx;
      const currentDocument = this.currentDocument;
      let duration = Date.now() - this.beginTime;
      if (this.currentDocumentAnnotation) {
        duration += this.currentDocumentAnnotation.duration;
      }
      const annotationValue = this.annotationValue;
      this.apiService
        .addAnnotation({
          duration,
          documentId: currentDocument.id,
          value: annotationValue,
        })
        .pipe(
          catchError((err) => {
            this.$store.commit('annotation/setDocumentAnnotationErrored', {
              idx: currentDocumentIdx,
              errored: true,
            });
            return throwError(err);
          }),
        )
        .subscribe((annotation: IAnnotation) => {
          this.$store.commit('annotation/setDocumentAnnotationId', {
            idx: currentDocumentIdx,
            annotationId: annotation.id,
          });
        });
      this.$store.dispatch('onNewAnnotation', {
        annotationValue,
        duration,
        documentId: currentDocument.id,
        index: currentDocumentIdx,
        nextIndex: this.getNextDocumentIdx(false),
      });
    }
  }

  public created() {
    this.loadingList = true;
    this.$store.commit('annotation/init');
    this.apiService
      .getTask(this.$route.params.taskId)
      .subscribe((task: ITask) => {
        this.$store.commit('annotation/setTask', task);
      });

    this.apiService
      .getDocumentsForTask(this.$route.params.taskId)
      .subscribe((documents: IDocument[]) => {
        this.documents = documents.sort((docA, docB) =>
          docA.name < docB.name ? -1 : 1,
        );
        this.$store.commit('annotation/setDocuments', documents);
        this.loadingList = false;

        if (!this.hasDocumentQuery()) {
          this.$store.commit(
            'annotation/setCurrentDocumentIndex',
            this.getNextDocumentIdx(),
          );
        }
      });
  }

  public setAnnotation(annotationValue: IAnnotation) {
    this.annotationValue = annotationValue;
  }

  private handleKeydown(event: any) {
    if (document.activeElement !== document.body) {
      return;
    }
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault();
        this.goingPrevious = true;
        break;
      case 'ArrowRight':
        event.preventDefault();
        this.goingNext = true;
        break;
    }
  }

  private handleKeyup(event: any) {
    if (document.activeElement !== document.body) {
      return;
    }
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault();
        this.previous();
        this.skipping = false;
        this.goingPrevious = false;
        this.goingNext = false;
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.skip();
        this.skipping = false;
        this.goingPrevious = false;
        this.goingNext = false;
        break;
      case 'ArrowRight':
        event.preventDefault();
        this.next();
        this.skipping = false;
        this.goingPrevious = false;
        this.goingNext = false;
        break;
    }
  }

  private mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);
    this.beginTime = Date.now();
  }

  private beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
  }
}
