import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex';
import IProject from '@/models/interfaces/project';
import JWT from 'jwt-decode';
import ProjectType, { projectTypeToText } from '@/models/enums/project-type';

import { apiService } from '@/services/api';

import AnnotationStore from '@/store/annotation';
import AdminProjectStore from '@/store/admin-project';
import { IPermissions } from '@/models/interfaces/permissions';
import AdminPoolStore from '@/store/admin-pool';
import ITask from '@/models/interfaces/task';
import IPool from '@/models/interfaces/pool';

Vue.use(Vuex);

export enum LoginMethod {
  SIMPLE = 'simple',
  GOOGLE = 'google',
  AZURE = 'azure',
}

export enum StorageMethod {
  LOCAL = 'local',
  AWS = 'aws',
}

export interface RootState {
  _backToken: string | null;
  locale: string;
  permissions: IPermissions;
  userEmail: string | null;
  types: { [key: string]: string }[];
  projects: IProject[];
  tasks: ITask[];
  homepageSelectedProjects: IProject[];
  baseUrl: string;
  backUrl: string;
  loginMethod: LoginMethod;
  googleClientId: string;
  azureClientId: string;
  azureRedirectUri: string;
  azureTenantAuthority: string;
  storageMethods: StorageMethod[];
}

const storeState: RootState = {
  _backToken: null,
  locale: 'en',
  permissions: {},
  userEmail: null,
  types: [
    { value: ProjectType.NER, text: projectTypeToText[ProjectType.NER] },
    {
      value: ProjectType.NER_RELATIONS,
      text: projectTypeToText[ProjectType.NER_RELATIONS],
    },
    {
      value: ProjectType.TEXT_CLASSIFICATION,
      text: projectTypeToText[ProjectType.TEXT_CLASSIFICATION],
    },
    {
      value: ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL,
      text: projectTypeToText[ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL],
    },
    {
      value: ProjectType.QA,
      text: projectTypeToText[ProjectType.QA],
    },
    {
      value: ProjectType.IMAGE_CLASSIFICATION,
      text: projectTypeToText[ProjectType.IMAGE_CLASSIFICATION],
    },
    {
      value: ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL,
      text: projectTypeToText[ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL],
    },
    {
      value: ProjectType.OBJECT_DETECTION,
      text: projectTypeToText[ProjectType.OBJECT_DETECTION],
    },
    {
      value: ProjectType.OCR,
      text: projectTypeToText[ProjectType.OCR],
    },
  ],
  projects: [],
  tasks: [],
  homepageSelectedProjects: [],
  baseUrl: '',
  backUrl: '',
  loginMethod: LoginMethod.SIMPLE,
  googleClientId: '',
  azureClientId: '',
  azureRedirectUri: '',
  azureTenantAuthority: '',
  storageMethods: [StorageMethod.LOCAL],
};

const storeGetters: GetterTree<RootState, RootState> = {
  backToken: (state: RootState) => {
    if (state._backToken) {
      return state._backToken;
    }
    return localStorage.getItem('backToken');
  },
};

const storeMutations: MutationTree<RootState> = {
  setBackToken(state, token) {
    try {
      const decoded: { exp: number; email: string; [key: string]: any } = JWT(
        token,
      );
      if (decoded.exp * 1000 < Date.now()) {
        state._backToken = null;
      } else {
        state._backToken = token;
        localStorage.setItem('backToken', token);
        state.userEmail = decoded.email;
      }
    } catch (e) {
      state._backToken = token;
    }
  },
  setLocale(state, locale) {
    localStorage.setItem('locale', locale);
    state.locale = locale;
  },
  deleteBackToken(state) {
    state._backToken = null;
    localStorage.removeItem('backToken');
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setPermissions(state, permissions: IPermissions) {
    state.permissions = permissions;
  },
  setMyTasks(state, tasks) {
    state.tasks = tasks;
  },
  setHomepageSelectedProjects(state, homepageSelectedProjects) {
    state.homepageSelectedProjects = homepageSelectedProjects;
  },
  setBaseUrl(state, baseUrl: string) {
    state.baseUrl = baseUrl;
  },
  setBackUrl(state, backUrl: string) {
    state.backUrl = backUrl;
  },
  setLoginMethod(state, loginMethod: string) {
    switch (loginMethod) {
      case 'google':
        state.loginMethod = LoginMethod.GOOGLE;
        break;
      case 'azure':
        state.loginMethod = LoginMethod.AZURE;
        break;
      case 'simple':
      default:
        state.loginMethod = LoginMethod.SIMPLE;
    }
  },
  setGoogleClientId(state, googleClientId) {
    state.googleClientId = googleClientId;
  },
  setAzureClientId(state, azureClientId) {
    state.azureClientId = azureClientId;
  },
  setAzureRedirectUri(state, azureRedirectUri) {
    state.azureRedirectUri = azureRedirectUri;
  },
  setAzureTenantAuthority(state, azureTenantAuthority) {
    state.azureTenantAuthority = azureTenantAuthority;
  },
  setStorageMethods(state, storageMethods) {
    state.storageMethods = storageMethods;
  },
};

const storeActions: ActionTree<RootState, RootState> = {
  loadFromEnv(context) {
    context.commit('setBaseUrl', process.env.BASE_URL);
    context.commit('setBackUrl', process.env.VUE_APP_BACK_URL);
    context.commit('setLoginMethod', process.env.VUE_APP_LOGIN_METHOD);
    context.commit('setGoogleClientId', process.env.VUE_APP_GOOGLE_CLIENT_ID);
    context.commit('setAzureClientId', process.env.VUE_APP_AZURE_CLIENT_ID);
    context.commit(
      'setAzureRedirectUri',
      process.env.VUE_APP_AZURE_REDIRECT_URI,
    );
    context.commit(
      'setAzureTenantAuthority',
      process.env.VUE_APP_AZURE_TENANT_AUTHORITY,
    );
    context.commit(
      'setStorageMethods',
      (process.env.VUE_APP_STORAGE_METHODS || '').split(','),
    );
  },
  loadFromLocalStorage(context) {
    context.commit('setLocale', localStorage.getItem('locale') || 'en');
    context.commit('setBackToken', localStorage.getItem('backToken'));
  },
  refreshPermissions(context) {
    return new Promise((resolve, reject) => {
      apiService.getPermissions().subscribe((permissions) => {
        context.commit('setPermissions', permissions);
        resolve();
      });
    });
  },
  refreshProjectList(context) {
    return new Promise((resolve, reject) => {
      apiService.getProjectList().subscribe((projects) => {
        context.commit('setProjects', projects);
        resolve();
      });
    });
  },
  refreshMyTasks(context) {
    return new Promise((resolve, reject) => {
      apiService.getMyTasks(true, false).subscribe((tasks) => {
        context.commit('setMyTasks', tasks);
        resolve();
      });
    });
  },
  onProjectCreated(context) {
    return Promise.all([
      context.dispatch('refreshPermissions'),
      context.dispatch('refreshProjectList'),
    ]);
  },
  onProjectDeleted(context) {
    return Promise.all([
      context.dispatch('refreshProjectList'),
      context.dispatch('refreshMyTasks'),
    ]);
  },
  onApplicationLoading(context) {
    return Promise.all([
      context.dispatch('refreshProjectList'),
      context.dispatch('refreshPermissions'),
      context.dispatch('refreshMyTasks'),
    ]);
  },
  onNewAnnotation(
    context,
    payload: {
      documentId: string,
      annotationValue: any,
      duration: number,
      index: number;
      nextIndex: number;
    },
  ) {
    context.commit('annotation/setDocumentAnnotationId', {
      idx: payload.index,
      annotationId: null,
    });
    context.commit('annotation/setCachedAnnotation', {
      documentId: payload.documentId,
      annotationValue: payload.annotationValue,
      duration: payload.duration,
    });
    context.commit('annotation/setCurrentDocumentIndex', payload.nextIndex);
    return context.dispatch('refreshMyTasks');
  },
  onConflictResolved(context, poolId) {
    return Promise.all([
      context.dispatch('refreshMyTasks'),
      context.dispatch('pool-admin/refreshTasks', poolId),
    ]);
  },
  onPoolCreated(context, pool) {
    context.commit('admin-project/addPool', pool);
    return Promise.all([
      context.dispatch('refreshMyTasks'),
      context.dispatch('refreshPermissions'),
    ]);
  },
  onPoolDeleted(context, pool) {
    context.commit('admin-project/deletePool', pool);
    return Promise.all([
      context.dispatch('refreshMyTasks'),
      context.dispatch('refreshPermissions'),
    ]);
  },
  onPoolUpdated(context, payload: { projectId: string; pool: IPool }) {
    context.commit('pool-admin/updatePool', payload.pool);
    return Promise.all([
      new Promise((resolve, reject) => {
        apiService
          .getPoolsByProject(payload.projectId, true)
          .subscribe((pools) => {
            context.commit('admin-project/updatePools', pools);
            resolve();
          });
      }),
      context.dispatch('refreshMyTasks'),
    ]);
  },
  onProjectUpdated(context, project) {
    context.commit('admin-project/setProject', project);
    return context.dispatch('refreshProjectList');
  },
};

export default new Vuex.Store({
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
  modules: {
    annotation: AnnotationStore,
    'admin-project': AdminProjectStore,
    'pool-admin': AdminPoolStore,
  },
});
