import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import AnnotatorTags from '@/illuin-annotation/components/common/AnnotatorTags/AnnotatorTags.vue';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import ObjectDetectionAnnotatorRenderer from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorRenderer/ObjectDetectionAnnotatorRenderer.vue';
import ObjectDetectionAnnotatorMenu from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorMenu/ObjectDetectionAnnotatorMenu.vue';
import PieTagSelector from '@/illuin-annotation/components/common/PieTagSelector/PieTagSelector.vue';
import IImageTagging from '@/illuin-annotation/models/interfaces/image-tagging';
import IImageAnnotationValue from '@/illuin-annotation/models/interfaces/image-annotation';
import { toArrayBuffer } from '@/illuin-annotation/services/utils/image-buffer';
import en from './lang/object-detection-diff-annotator.en.json';
import fr from './lang/object-detection-diff-annotator.fr.json';
import IDocument from '@/models/interfaces/document';
import IAnnotation from '@/models/interfaces/annotation';

@Component({
  components: {
    AnnotatorTags,
    ObjectDetectionAnnotatorMenu,
    ObjectDetectionAnnotatorRenderer,
    PieTagSelector,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class ObjectDetectionDiffAnnotator extends Vue {
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: { raw: { data: ArrayBuffer; type: string } };
  @Prop() public datamodel!: INerDataModel;
  @Prop() public passive!: boolean;
  @Prop() public preAnnotations!: IAnnotation[];

  public taggings: { [key: string]: IImageTagging }[] = [{}, {}];

  @Watch('documentValue')
  public onDocumentChange() {
    this.computePreAnnotations();
  }

  @Watch('preAnnotationsValues')
  public onPreAnnotationChange() {
    this.computePreAnnotations();
  }

  public get preAnnotationsValues(): IImageAnnotationValue[] {
    return this.preAnnotations.map((preAnnotation) => preAnnotation.value as IImageAnnotationValue);
  }

  public get tags(): ITag[] {
    return this.datamodel.tags;
  }

  public get imageBuffer() {
    return toArrayBuffer(this.documentValue.raw.data);
  }

  public get tagsById(): { [key: string]: ITag } {
    const tagsById: { [key: string]: ITag } = {};
    this.tags.forEach((tag: ITag) => {
      tagsById[tag.id] = tag;
    });
    return tagsById;
  }

  public computePreAnnotations() {
    this.taggings = [{}, {}];
    if (this.preAnnotationsValues) {
      this.preAnnotationsValues.forEach((preAnnotation: IImageAnnotationValue, idx) => {
        preAnnotation.tags.forEach((tagging) => {
          this.taggings[idx][tagging.id] = {
            id: tagging.id,
            vertices: tagging.vertices,
            tag: this.tagsById[tagging.tag],
          };
        });
      });
    }
  }

  /**
   * Life cycle
   */
  private created() {
    this.computePreAnnotations();
  }
}
