import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import PoolConflict from '@/components/PoolConflict/PoolConflict.vue';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';

@Component({
  computed: mapState('pool-admin', ['pool', 'tasks']),
  components: { PoolConflict },
  providers: [ApiService],
})
export default class PoolAdminConflicts extends Vue {
  public pool!: PoolDashboardDto;
  public tasks!: TaskMetadataDto[];
  @inject() private readonly apiService!: ApiService;

  public get hasConflict() {
    return this.tasks.some((task) => task.conflictsCount > 0);
  }

  public closePool() {
    if (this.pool) {
      this.$router.push({
        name: 'pool-admin-dashboard',
        params: { id: this.pool.project.id },
      });
      this.apiService.closePool(this.pool.id).subscribe((pool) => {
        this.$store.dispatch('onPoolUpdated', {
          pool,
          projectId: pool.project.id,
        });
      });
    }
  }
}
