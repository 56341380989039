import { Component, Vue } from 'vue-property-decorator';
import 'reflect-metadata';
import { mapState } from 'vuex';

@Component({
  computed: mapState(['locale']),
})
export default class App extends Vue {
  public locale!: string;

  public created() {
    this.$i18n.locale = this.locale;
  }
}
