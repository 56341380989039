import Vue from 'vue';
import App from '@/App/App.vue';
import router from '@/router';
import store from '@/store/store';
// BootstrapVue
import BootstrapVue from 'bootstrap-vue';
// Alerts - Notifications
import Notifications from 'vue-notification';
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowRight,
  faChartLine,
  faCheck,
  faChevronDown,
  faClock,
  faCodeBranch,
  faCog,
  faCopy,
  faCrosshairs,
  faEdit,
  faEraser,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileExport,
  faFileImport,
  faGavel,
  faHighlighter,
  faHome,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faInfoCircle,
  faListUl,
  faMousePointer,
  faPen,
  faPlus,
  faQuestionCircle,
  faSignOutAlt,
  faStopwatch,
  faSyncAlt,
  faTags,
  faTasks,
  faTimes,
  faTools,
  faTrash,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Vue Moment
import VueMoment from 'vue-moment';
import moment from 'moment';
import 'moment/locale/fr';
// Custom
import ArrowIcon from '@/icons/Arrow.vue';
// Services
import VueTypeScriptInject from 'vue-typescript-inject';
// Logged
import PermissionsPlugin from '@/plugins/permissions';
// Translation
import { i18n } from '@/plugins/i18n';
// Pretty bytes filter
import { prettyBytes } from '@/plugins/pretty-bytes.filter';

Vue.use(BootstrapVue);

Vue.use(Notifications);

library.add(
  faArrowDown,
  faArrowRight,
  faChartLine,
  faCheck,
  faChevronDown,
  faClock,
  faCodeBranch,
  faCog,
  faCopy,
  faEdit,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileExport,
  faFileImport,
  faGavel,
  faHighlighter,
  faHome,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faInfoCircle,
  faListUl,
  faPen,
  faPlus,
  faQuestionCircle,
  faSignOutAlt,
  faStopwatch,
  faSyncAlt,
  faTags,
  faTasks,
  faTimes,
  faTools,
  faTrash,
  faUser,
  faUsers,
  faArrowRight,
  faFile,
  faCopy,
  faInfoCircle,
  faCodeBranch,
  faEraser,
  faMousePointer,
  faCrosshairs,
  faMousePointer,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueMoment, { moment });

Vue.component('ArrowIcon', ArrowIcon);

Vue.use(VueTypeScriptInject);

Vue.use(new PermissionsPlugin());

Vue.config.productionTip = false;

Vue.filter('prettyBytes', prettyBytes);

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    this.$store.dispatch('loadFromEnv');
    this.$store.dispatch('loadFromLocalStorage');
  },
  render: (h) => h(App),
}).$mount('#app');
