import { Component, Prop, Vue } from 'vue-property-decorator';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';
import { mapState } from 'vuex';
import AnnotatorCard from '@/components/AnnotatorCard/AnnotatorCard.vue';

@Component({
  computed: mapState('pool-admin', ['tasks']),
  components: { AnnotatorCard },
})
export default class PoolAnnotators extends Vue {
  public tasks!: TaskMetadataDto[];
  @Prop({ default: () => [] }) public expandedAnnotatorCards!: string[];
}
