import { strictSegmentsCollision } from '@/utils/math';
import IBlock from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/block';
import ILink from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/link';
import IRelationTagging from '@/illuin-annotation/models/interfaces/relation-tagging';
import {
  RELATION_CHARWIDTH,
  RELATION_LABEL_MARGIN,
} from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/utils/constants';
import INERTagging from '@/illuin-annotation/models/interfaces/ner-tagging';

const isRelationValid = (
  relationAnchorToken: INERTagging | null,
  relationFocusToken: INERTagging | null,
) => {
  return (
    relationAnchorToken &&
    relationFocusToken &&
    relationAnchorToken.id !== relationFocusToken.id
  );
};

const computeLinks = (
  maxwidth: number,
  relationTaggings: IRelationTagging[],
  taggingsBlocks: IBlock[],
) => {
  const links: ILink[] = [];
  for (const relationTagging of relationTaggings) {
    const beginningTaggingBlocks = taggingsBlocks.filter(
      (block) => block.tagging.id === relationTagging.begin.id,
    );
    const endTaggingBlocks = taggingsBlocks.filter(
      (block) => block.tagging.id === relationTagging.end.id,
    );
    if (beginningTaggingBlocks && endTaggingBlocks) {
      const beginningTaggingBlock =
        beginningTaggingBlocks[beginningTaggingBlocks.length - 1];
      const endTaggingBlock = endTaggingBlocks[0];

      const beginX =
        (beginningTaggingBlock.beginX + beginningTaggingBlock.endX) / 2;
      const endX = (endTaggingBlock.beginX + endTaggingBlock.endX) / 2;

      if (beginningTaggingBlock.y === endTaggingBlock.y) {
        // add one full link
        links.push({
          beginX,
          endX,
          relation: relationTagging,
          id: relationTagging.id,
          y: beginningTaggingBlock.y,
          label: relationTagging.relation!.name,
          labelX: (beginX + endX) / 2,
          labelAnchor: 'middle',
          labelBackgroundXOffset:
            (-relationTagging.relation!.name.length * RELATION_CHARWIDTH) / 2,
          isStart: true,
          isEnd: true,
          height: 0,
        });
      } else {
        // add two semi links
        links.push({
          beginX,
          relation: relationTagging,
          id: `${relationTagging.id}begin`,
          y: beginningTaggingBlock.y,
          endX: relationTagging.reversed ? 0 : maxwidth,
          label: relationTagging.relation!.name,
          labelX: relationTagging.reversed ? 0 : maxwidth,
          labelAnchor: relationTagging.reversed ? 'start' : 'end',
          labelBackgroundXOffset: relationTagging.reversed
            ? -RELATION_LABEL_MARGIN
            : -relationTagging.relation!.name.length * RELATION_CHARWIDTH +
              RELATION_LABEL_MARGIN,
          isStart: true,
          isEnd: false,
          height: 0,
        });
        links.push({
          endX,
          relation: relationTagging,
          id: `${relationTagging.id}end`,
          y: endTaggingBlock.y,
          beginX: relationTagging.reversed ? maxwidth : 0,
          label: relationTagging.relation!.name,
          labelX: relationTagging.reversed ? maxwidth : 0,
          labelAnchor: relationTagging.reversed ? 'end' : 'start',
          labelBackgroundXOffset: relationTagging.reversed
            ? -relationTagging.relation!.name.length * RELATION_CHARWIDTH +
              RELATION_LABEL_MARGIN
            : -RELATION_LABEL_MARGIN,
          isStart: false,
          isEnd: true,
          height: 0,
        });
      }
    }
  }
  return sortAndTranslateLink(links);
};

const sortAndTranslateLink = (links: ILink[]) => {
  const finalLinks = [];
  links.sort(
    (link1, link2) =>
      Math.abs(link1.endX - link1.beginX) - Math.abs(link2.endX - link2.beginX),
  );
  for (const link of links) {
    const collisions = new Set();
    let height = 0;
    for (const finalLink of finalLinks) {
      if (
        link.y === finalLink.y &&
        strictSegmentsCollision(
          link.beginX,
          link.endX,
          finalLink.beginX,
          finalLink.endX,
        )
      ) {
        collisions.add(finalLink.height);
      }
    }
    while (collisions.has(height)) {
      height += 1;
    }
    link.height = height;
    finalLinks.push(link);
  }
  return finalLinks;
};

const computeArrowsLines = (links: ILink[]) => {
  const linksByLine: { [key: number]: ILink[] } = {};
  const heightByLine: { [key: number]: number } = {};
  for (const link of links) {
    if (linksByLine.hasOwnProperty(link.y)) {
      linksByLine[link.y].push(link);
      heightByLine[link.y] = Math.max(
        heightByLine[link.y],
        Number(link.height + 1),
      );
    } else {
      linksByLine[link.y] = [link];
      heightByLine[link.y] = Math.max(Number(link.height), 1);
    }
  }

  const arrowsLines = [];
  for (const line of Object.keys(linksByLine)) {
    arrowsLines.push({
      y: Number(line),
      height: heightByLine[Number(line)],
      links: linksByLine[Number(line)].sort(
        (a, b) => Number(b.height) - Number(a.height),
      ),
    });
  }
  return arrowsLines;
};

export {
  isRelationValid,
  computeLinks,
  sortAndTranslateLink,
  computeArrowsLines,
};
