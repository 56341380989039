import INERRelationAnnotationValue from '@/illuin-annotation/models/interfaces/ner-relation-annotation';
import INERAnnotationValue from '@/illuin-annotation/models/interfaces/ner-annotation';

const isUnionMergeable = (
  leftAnnotationValue: INERAnnotationValue | INERRelationAnnotationValue,
  rightAnnotationValue: INERAnnotationValue | INERRelationAnnotationValue,
) => {
  const leftTaggingsIds = leftAnnotationValue.tags.map((tag) => tag.id);
  const rightTaggingsIds = rightAnnotationValue.tags.map((tag) => tag.id);
  const uniqueTaggings = leftAnnotationValue.tags
    .filter((tag) => rightTaggingsIds.indexOf(tag.id) < 0)
    .concat(
      rightAnnotationValue.tags.filter(
        (tag) => leftTaggingsIds.indexOf(tag.id) < 0,
      ),
    )
    .sort((tagA, tagB) => (tagA.begin < tagB.begin ? -1 : 1));
  let lastEnd = -1;
  return uniqueTaggings.reduce((ans, tag) => {
    const newAns = tag.begin > lastEnd ? ans : false;
    lastEnd = tag.end;
    return newAns;
  }, true);
};

const unionMerge = (
  leftAnnotationValue: INERAnnotationValue | INERRelationAnnotationValue,
  rightAnnotationValue: INERAnnotationValue | INERRelationAnnotationValue,
): INERAnnotationValue | INERRelationAnnotationValue => {
  const leftTaggingsIds = leftAnnotationValue.tags.map((tag) => tag.id);
  const allTaggings = leftAnnotationValue.tags.concat(
    rightAnnotationValue.tags.filter(
      (tag) => leftTaggingsIds.indexOf(tag.id) < 0,
    ),
  );
  const mergedAnnotation: INERAnnotationValue | INERRelationAnnotationValue = {
    tags: allTaggings,
  };

  if (leftAnnotationValue.hasOwnProperty('relations')) {
    const leftRelationsIds = (leftAnnotationValue as INERRelationAnnotationValue).relations.map(
      (relation) => relation.id,
    );
    (mergedAnnotation as INERRelationAnnotationValue).relations = (leftAnnotationValue as INERRelationAnnotationValue).relations.concat(
      (rightAnnotationValue as INERRelationAnnotationValue).relations.filter(
        (relation) => leftRelationsIds.indexOf(relation.id) < 0,
      ),
    );
  }

  return mergedAnnotation;
};

const intersectionMerge = (
  leftAnnotationValue: INERAnnotationValue | INERRelationAnnotationValue,
  rightAnnotationValue: INERAnnotationValue | INERRelationAnnotationValue,
): INERAnnotationValue | INERRelationAnnotationValue => {
  const leftTaggingsIds = leftAnnotationValue.tags.map((tag) => tag.id);
  const sharedTaggings = rightAnnotationValue.tags.filter(
    (tag) => leftTaggingsIds.indexOf(tag.id) > -1,
  );
  const mergedAnnotation: INERAnnotationValue | INERRelationAnnotationValue = {
    tags: sharedTaggings,
  };

  if (leftAnnotationValue.hasOwnProperty('relations')) {
    const leftRelationsIds = (leftAnnotationValue as INERRelationAnnotationValue).relations.map(
      (relation) => relation.id,
    );
    (mergedAnnotation as INERRelationAnnotationValue).relations = (rightAnnotationValue as INERRelationAnnotationValue).relations.filter(
      (relation) => leftRelationsIds.indexOf(relation.id) > -1,
    );
  }

  return mergedAnnotation;
};

export { isUnionMergeable, unionMerge, intersectionMerge };
