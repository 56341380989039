import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import { RawLocation } from 'vue-router';

@Component({
  computed: mapState('pool-admin', ['pool']),
  components: { PastelIcon },
})
export default class MenuBarPool extends Vue {
  public pool!: PoolDashboardDto;
  @Prop({ default: false }) public forceBackHomepage!: boolean;

  public get backRoute(): RawLocation {
    if (
      this.$can('projectHandleConflict', this.pool.project.id) &&
      !this.forceBackHomepage
    ) {
      return {
        name: 'project-admin-pools',
        params: { id: this.pool.project.id },
      };
    }
    return {
      name: 'homepage',
      query: { resetFilters: '0' },
    };
  }
}
