import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import PoolAnnotators from '@/components/PoolAnnotators/PoolAnnotators.vue';

@Component({
  computed: mapState('pool-admin', ['pool']),
  components: { PoolAnnotators },
})
export default class PoolAdminAnnotators extends Vue {
  public pool!: PoolDashboardDto;

  public get expandedAnnotatorCards() {
    const expandQuery = this.$route.query.expand;
    return typeof expandQuery === 'string' ? [expandQuery] : expandQuery;
  }
}
