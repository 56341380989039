import { Component, Vue } from 'vue-property-decorator';
import PoolDashboard from '@/components/PoolDashboard/PoolDashboard.vue';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';

@Component({
  computed: mapState('pool-admin', ['pool']),
  components: { PoolDashboard },
  providers: [ApiService],
})
export default class PoolAdminDashboard extends Vue {
  public pool!: PoolDashboardDto;
  @inject() private readonly apiService!: ApiService;

  public closePool() {
    if (this.pool) {
      this.$router.push({
        name: 'pool-admin-dashboard',
        params: { id: this.pool.project.id },
      });
      this.apiService.closePool(this.pool.id).subscribe((pool) => {
        this.$store.dispatch('onPoolUpdated', {
          pool,
          projectId: pool.project.id,
        });
      });
    }
  }
}
