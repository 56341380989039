import { Component, Vue } from 'vue-property-decorator';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import IProject from '@/models/interfaces/project';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import IUser from '@/models/interfaces/user';
import PlatformRolesForm from '@/components/PlatformRolesForm/PlatformRolesForm.vue';
import UserRoleList from '@/components/UseRoleList/UserRoleList.vue';
import { UserRoleDto } from '@/models/dtos/user-role.dto';
import Roles from '@/models/enums/user-roles';
import ResourceType from '@/models/enums/resource-type';

@Component({
  providers: [ApiService],
  components: { PastelIcon, PlatformRolesForm, UserRoleList },
})
export default class PlatformProjectAdmin extends Vue {
  public projectList: IProject[] = [];
  public selectedProject: IProject | null = null;
  public platformUsers: IUser[] = [];
  public projectUsers: IUser[] = [];
  @inject() private readonly apiService!: ApiService;

  public created() {
    this.apiService.getPlatformProjects().subscribe((projects) => {
      this.projectList = projects;
      this.apiService.getPlatformUsers().subscribe((users) => {
        this.platformUsers = users;
        if (this.$route.query.projectId) {
          this.switchToProject(this.$route.query.projectId as string);
        }
      });
    });
  }

  public fetchProjectUsers() {
    if (this.selectedProject) {
      this.apiService
        .getProjectUsers(this.selectedProject)
        .subscribe((users) => {
          this.projectUsers = users.filter((user) => {
            return (
              user.role === Roles.PROJECT_ADMIN ||
              user.role === Roles.PROJECT_OWNER
            );
          });
        });
    }
  }

  public onSelectProject(projectId: string) {
    this.$router.push({ query: { projectId } });
    this.switchToProject(projectId);
  }

  public switchToProject(projectId: string) {
    this.selectedProject =
      this.projectList.find((p) => p.id === projectId) || null;
    // this.$route.query.projectId = projectId;
    this.fetchProjectUsers();
  }

  public unselectProject() {
    this.$router.push({ query: {} });
    this.selectedProject = null;
  }

  public onSubmit(newUserRole: UserRoleDto) {
    const userRole: UserRoleDto = {
      user: newUserRole.user,
      role: Roles.PROJECT_ADMIN,
      ressourceType: ResourceType.PROJECT,
      ressourceId: this.selectedProject!.id,
    };
    this.apiService.postRoleAssignment(userRole).subscribe(this.onRoleChanged);
  }

  public handleDelete(user: IUser) {
    this.apiService
      .deleteRoleAssignment({
        user: user.id,
        role: Roles.PROJECT_ADMIN,
        ressourceType: ResourceType.PROJECT,
        ressourceId: this.selectedProject!.id,
      })
      .subscribe(this.onRoleChanged);
  }

  public onRoleChanged() {
    this.$store.dispatch('refreshProjectList');
    this.$store.dispatch('refreshPermissions');
    this.fetchProjectUsers();
  }
}
