import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IDocument from '@/models/interfaces/document';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import Previewer from '@/illuin-annotation/factories/Previewer/Previewer.vue';

@Component({
  components: { Previewer },
  providers: [ApiService],
})
export default class ProjectEntriesList extends Vue {
  @Prop() public documents!: IDocument[];
  @Prop() public projectType!: string;
  public filter: string = '';
  public currentPage: number = 1;
  public totalRows: number = 0;
  public previewedDocument: IDocument | null = null;
  public previewedDocumentValue: any = null;
  public fields: { [key: string]: any }[] = [];
  @inject() private apiService!: ApiService;

  @Watch('documents', { immediate: true })
  public onDocumentsUpdate() {
    this.totalRows = this.documents.length;
    this.filter = '';
  }

  public filterer(row: IDocument, filter: string) {
    return row.name.includes(filter);
  }

  public onFiltered(filteredItems: { [key: string]: any }[]): void {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  public get modalTitle(): string {
    return this.previewedDocument ? this.previewedDocument.name : '';
  }

  public viewEntry(document: IDocument) {
    this.previewedDocument = document;
    this.$bvModal.show('previewModal');
    this.apiService
      .loadDocumentById(document.id)
      .subscribe((documentValue: any) => {
        this.previewedDocumentValue = documentValue;
      });
  }

  public entryHidden() {
    this.previewedDocument = null;
    this.previewedDocumentValue = null;
  }

  public created() {
    this.fields = [
      {
        key: 'name',
        label: this.$t('project-entries.name-column'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: this.$t('project-entries.created-at-column'),
        sortable: true,
      },
      { key: 'url', label: '', sortable: false },
    ];
  }
}
