import { Component, Prop, Vue } from 'vue-property-decorator';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';

@Component({
  components: { PastelIcon },
})
export default class TaskItem extends Vue {
  @Prop() public task!: TaskMetadataDto;
  public extended: boolean = false;

  public get remainingDays(): number {
    const exactDueDate = new Date(this.task.pool.dueDate);
    const dueDate = Date.UTC(
      exactDueDate.getFullYear(),
      exactDueDate.getMonth(),
      exactDueDate.getDate(),
    );
    const exactToday = new Date();
    const today = Date.UTC(
      exactToday.getFullYear(),
      exactToday.getMonth(),
      exactToday.getDate(),
    );
    return Math.round((dueDate - today) / (1000 * 60 * 60 * 24));
  }

  public get completed() {
    return (
      this.task.annotatedCount - this.task.conflictsCount - this.task.pendingCrossAnnotationCount ===
      this.task.documentsCount
    );
  }

  public get localeDate() {
    return new Date(this.task.pool.dueDate).toLocaleDateString(
      this.$i18n.locale,
      {
        dateStyle: 'full',
      },
    );
  }
}
