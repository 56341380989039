import { Component, Vue } from 'vue-property-decorator';
import IProject from '@/models/interfaces/project';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import IDocument from '@/models/interfaces/document';
import ProjectEntriesList from '@/components/ProjectEntriesList/ProjectEntriesList.vue';

@Component({
  computed: mapState('admin-project', ['project']),
  components: { ProjectEntriesList },
  providers: [ApiService],
})
export default class ProjectEntries extends Vue {
  public project!: IProject;
  public documents: IDocument[] = [];
  public loading: boolean = false;
  @inject() private readonly apiService!: ApiService;

  public created() {
    this.apiService
      .getDocumentsByProject(this.project.id)
      .subscribe((documents: IDocument[]) => {
        this.documents = documents;
        this.loading = false;
      });
  }
}
