import { Component, Vue } from 'vue-property-decorator';
import IProject from '@/models/interfaces/project';
import BaseNavSide from '@/layouts/BaseNavSide/BaseNavSide.vue';
import IUser from '@/models/interfaces/user';
import MenuBarPlatform from '@/components/MenuBarPlatform/MenuBarPlatform.vue';

@Component({
  components: { BaseNavSide, MenuBarPlatform },
})
export default class PlatformRoles extends Vue {
  public projects: IProject[] = [];
  public userList: IUser[] = [];

  public created() {
    if (!this.$can('platformAssignRole', null)) {
      this.$router.push({ name: 'project-list' });
    }
  }
}
