import { Component, Vue } from 'vue-property-decorator';
import MenuBar from '@/layouts/MenuBar/MenuBar.vue';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import IProject from '@/models/interfaces/project';
import { mapState } from 'vuex';

@Component({
  computed: {
    ...mapState('admin-project', ['project']),
  },
  components: {
    MenuBar,
    PastelIcon,
  },
})
export default class MenuBarProject extends Vue {
  public project!: IProject;
}
