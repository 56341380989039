import { Component, Prop, Vue } from 'vue-property-decorator';
import IProject from '@/models/interfaces/project';
import ProjectType, { projectTypeToText } from '@/models/enums/project-type';

@Component({})
export default class ProjectInfosViewer extends Vue {
  @Prop() public project!: IProject;

  public projectTypeToText(type: ProjectType) {
    return projectTypeToText[type];
  }
}
