import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import EditDataModelForm from '@/illuin-annotation/factories/EditDataModelForm/EditDataModelForm.vue';
import DataModelDisplay from '@/illuin-annotation/factories/DataModelDisplay/DataModelDisplay.vue';
import IProject from '@/models/interfaces/project';
import { inject } from 'vue-typescript-inject';
import ApiService from '@/services/api';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import IPool from '@/models/interfaces/pool';

@Component({
  computed: mapState('admin-project', ['project']),
  components: {
    EditDataModelForm,
    DataModelDisplay,
  },
  providers: [ApiService],
})
export default class ProjectDataModelSettings extends Vue {
  public project!: IProject;
  public canEdit: boolean = false;
  public datamodel: IDataModel | null = null;
  public statusModalText: string = '';
  public formKey: number = 1;
  public editing: boolean = false;
  @inject() private readonly apiService!: ApiService;

  public get editButtonTooltip() {
    if (this.editing) {
      return this.$t('project-datamodel-settings.tooltips.cancel-edit');
    }
    if (!this.canEdit) {
      return this.$t('project-datamodel-settings.tooltips.disabled');
    }
    return this.$t('project-datamodel-settings.tooltips.edit');
  }

  public created() {
    this.apiService
      .getPoolsByProject(this.project.id)
      .subscribe((pools: IPool[]) => {
        if (!pools.length) {
          this.canEdit = true;
        }
      });
  }

  public onSubmit(datamodel: IDataModel) {
    this.datamodel = datamodel;
    this.apiService
      .updateProjectDatamodel(this.project, this.datamodel)
      .subscribe((dataModel: IDataModel) => {
        this.$store.commit('admin-project/updateDataModel', dataModel);
        this.editing = false;
      });
  }

  public toggleEdit() {
    this.editing = !this.editing;
  }
}
