import { Component, Vue, Watch } from 'vue-property-decorator';
import IPool from '@/models/interfaces/pool';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import { mapState } from 'vuex';
import IDocument from '@/models/interfaces/document';
import ConflictHandlerSidebyside from '@/components/ConflictHandlerSidebyside/ConflictHandlerSidebyside.vue';
import IAnnotation from '@/models/interfaces/annotation';
import Annotator from '@/illuin-annotation/factories/Annotator/Annotator.vue';
import IAnnotationValue from '@/illuin-annotation/models/types/annotation';
import computeAnnotationsGCF from '@/illuin-annotation/services/annotation-gcf';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';

@Component({
  computed: {
    ...mapState(['userEmail']),
    ...mapState('pool-admin', ['pool']),
  },
  components: { ConflictHandlerSidebyside, Annotator },
  providers: [ApiService],
})
export default class PoolConflict extends Vue {
  public userEmail!: string;
  public pool!: IPool;

  public passive: boolean = false;
  public conflictedDocuments: IDocument[] = [];
  public selectedDocumentIdx: number | null = null;
  public annotationsByDocumentId: { [key: string]: IAnnotation[] } = {};
  public annotationGCFByDocumentId: { [key: string]: IAnnotationValue } = {};
  public documentsValueById: { [key: string]: any } = {};
  public manualAnnotation: IAnnotationValue | null = null;
  public loading: boolean = true;
  public editMode: boolean = false;
  public beginTime: number = Date.now();

  @inject() private readonly apiService!: ApiService;

  public get selectedDocumentId(): string | null {
    if (
      this.selectedDocumentIdx !== null &&
      this.conflictedDocuments[this.selectedDocumentIdx]
    ) {
      return this.conflictedDocuments[this.selectedDocumentIdx].id;
    }
    return null;
  }

  @Watch('selectedDocumentId')
  public onSelectedDocumentChange() {
    this.editMode = false;
    if (this.selectedDocumentIdx !== null) {
      this.beginTime = Date.now();
      const document = this.conflictedDocuments[this.selectedDocumentIdx];
      if (!this.annotationsByDocumentId.hasOwnProperty(document.id)) {
        this.apiService
          .getAnnotationsForDocument(document.id, true)
          .subscribe((annotations: IAnnotation[]) => {
            Vue.set(this.annotationsByDocumentId, document.id, annotations);
            Vue.set(
              this.annotationGCFByDocumentId,
              document.id,
              computeAnnotationsGCF(
                this.pool.project.annotationType,
                annotations.map((annotation) => annotation.value),
              ),
            );
          });
      }
      if (!this.documentsValueById.hasOwnProperty(document.id)) {
        this.apiService
          .loadDocumentById(document.id)
          .subscribe((documentValue: any) => {
            Vue.set(this.documentsValueById, document.id, documentValue);
            this.beginTime = Date.now();
            this.loading = false;
          });
      }
    }
  }

  public getDocumentIndexFromId(documentId: string): number | null {
    for (let i = 0; i < this.conflictedDocuments.length; i += 1) {
      if (this.conflictedDocuments[i].id === documentId) {
        return i;
      }
    }
    return null;
  }

  public get nbConflicts(): number {
    return this.conflictedDocuments.length;
  }

  public get documentsSelectOptions(): any {
    return this.conflictedDocuments.map((document: IDocument, idx: number) => {
      return {
        value: idx,
        text: document.name,
      };
    });
  }

  public get currentDocumentValue(): any {
    if (this.selectedDocumentIdx !== null) {
      return this.documentsValueById[
        this.conflictedDocuments[this.selectedDocumentIdx].id
      ];
    }
    return null;
  }

  public get annotations(): IAnnotation[] {
    if (this.selectedDocumentIdx !== null) {
      return this.annotationsByDocumentId[
        this.conflictedDocuments[this.selectedDocumentIdx].id
      ];
    }
    return [];
  }

  public confirmAnnotation(annotationValue: IAnnotationValue) {
    const duration = Date.now() - this.beginTime;
    this.apiService
      .resolveConflict({
        duration,
        documentId: this.selectedDocumentId!,
        value: annotationValue,
      })
      .subscribe(() => {
        this.$store.dispatch('onConflictResolved', this.pool.id);
        this.conflictedDocuments.splice(Number(this.selectedDocumentIdx), 1);
        this.selectedDocumentIdx = this.conflictedDocuments.length
          ? Number(this.selectedDocumentIdx) % this.conflictedDocuments.length
          : null;
      });
  }

  public resolveConflict() {
    const duration = Date.now() - this.beginTime;
    this.apiService
      .resolveConflict({
        duration,
        documentId: this.selectedDocumentId!,
        value: this.manualAnnotation,
      })
      .subscribe(() => {
        this.$store.dispatch('onConflictResolved', this.pool.id);
        this.manualAnnotation = null;
        this.conflictedDocuments.splice(Number(this.selectedDocumentIdx), 1);
        this.selectedDocumentIdx = this.conflictedDocuments.length
          ? Number(this.selectedDocumentIdx) % this.conflictedDocuments.length
          : null;
      });
  }

  public created() {
    if (this.$can('projectHandleConflict', this.pool.project.id)) {
      this.apiService
        .getDocumentsForPool(this.pool.id, true)
        .subscribe((documents: IDocument[]) => {
          this.conflictedDocuments = documents;
          if (this.conflictedDocuments.length) {
            this.selectedDocumentIdx =
              this.getDocumentIndexFromId(
                this.$route.query.documentId as string,
              ) || 0;
          }
          this.loading = false;
        });
    } else {
      this.passive = true;
      this.apiService
        .getTasksByPool(this.pool.id, true)
        .subscribe((tasks: TaskMetadataDto[]) => {
          this.apiService
            .getDocumentsForTask(
              tasks.find((task) => task.user.email === this.userEmail)!.id,
              true,
            )
            .subscribe((documents: IDocument[]) => {
              this.conflictedDocuments = documents;
              if (this.conflictedDocuments.length) {
                this.selectedDocumentIdx = 0;
              }
              this.loading = false;
            });
        });
    }
  }
}
