import { Component, Vue } from 'vue-property-decorator';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import TasksList from '@/components/TasksList/TasksList.vue';
import Collapse from '@/components/Collapse/Collapse.vue';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';
import IProject from '@/models/interfaces/project';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';

@Component({
  computed: mapState(['tasks', 'homepageSelectedProjects']),
  components: { PastelIcon, TasksList, Collapse, Multiselect },
})
export default class MyTasks extends Vue {
  public tasks!: TaskMetadataDto[];
  public loading: boolean = false;
  public openTasksCount: number = 0;
  public homepageSelectedProjects!: IProject[];
  public selectedProjects: IProject[] = [];
  public notCompletedOnly: boolean = false;

  public get sortedTasks(): TaskMetadataDto[] {
    return this.tasks.sort((taskA, taskB) =>
      taskA.pool.dueDate < taskB.pool.dueDate ? -1 : 1,
    );
  }

  public get selectedTasks(): TaskMetadataDto[] {
    return this.sortedTasks
      .filter(
        (task) =>
          this.selectedProjects.length === 0 ||
          this.selectedProjectsIds.includes(task.project.id),
      )
      .filter(
        (task) =>
          !(
            this.notCompletedOnly &&
            task.annotatedCount -
              task.conflictsCount -
              task.pendingCrossAnnotationCount ===
              task.documentsCount
          ),
      );
  }

  public get openSelectedTasks(): TaskMetadataDto[] {
    return this.selectedTasks.filter((task) => !task.closed);
  }

  public get closedSelectedTasks(): TaskMetadataDto[] {
    return this.selectedTasks.filter((task) => task.closed);
  }

  public get projects(): IProject[] {
    const projects: IProject[] = [];
    const seen: string[] = [];
    this.tasks.forEach((task) => {
      if (!seen.includes(task.project.id)) {
        projects.push(task.project);
        seen.push(task.project.id);
      }
    });
    return projects;
  }

  public get selectedProjectsIds(): string[] {
    return this.selectedProjects.map((project) => project.id);
  }

  public created() {
    this.openTasksCount = this.tasks.filter((task) => !task.closed).length;
    this.prefillMultiSelect();
  }

  public beforeDestroy() {
    this.$store.commit('setHomepageSelectedProjects', this.selectedProjects);
  }

  private prefillMultiSelect() {
    if (this.$route.query.resetFilters === '0') {
      this.selectedProjects = this.homepageSelectedProjects;
    } else if (this.$route.query.projectId) {
      const prefillProject = this.projects.find((project) => {
        return project.id === this.$route.query.projectId;
      });

      if (prefillProject) {
        this.selectedProjects.push(prefillProject);
      }
    }
  }
}
