import { RootState } from '@/store/store';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';
import { apiService } from '@/services/api';
import { forkJoin } from 'rxjs';

interface AdminPoolState {
  pool: PoolDashboardDto | null;
  tasks: TaskMetadataDto[] | null;
}

const getDefaultState = () => {
  return {
    pool: null,
    tasks: null,
  };
};

const storeState: AdminPoolState = getDefaultState();

const storeGetters: GetterTree<AdminPoolState, RootState> = {};

const storeMutations: MutationTree<AdminPoolState> = {
  init(state) {
    Object.assign(state, getDefaultState());
  },
  setPool(state, pool) {
    state.pool = pool;
  },
  updatePool(state, pool) {
    if (state.pool) {
      Object.assign(state.pool, pool);
    }
  },
  setTasks(state, tasks) {
    state.tasks = tasks;
  },
};

const storeActions: ActionTree<AdminPoolState, RootState> = {
  refreshTasks(context, poolId) {
    return new Promise((resolve, reject) => {
      apiService.getTasksByPool(poolId, true).subscribe((tasks) => {
        forkJoin(
          tasks.map((task) => apiService.getDocumentsMetadataForTask(task.id)),
        ).subscribe((documents) => {
          for (let i = 0; i < tasks.length; i += 1) {
            tasks[i].documents = documents[i];
          }
          context.commit('setTasks', tasks);
          resolve();
        });
      });
    });
  },
};

const adminPoolStore = {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};

export default adminPoolStore;
