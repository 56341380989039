import INERRelationAnnotationValue from '@/illuin-annotation/models/interfaces/ner-relation-annotation';

const nerRelationGCF = (
  annotations: INERRelationAnnotationValue[],
): INERRelationAnnotationValue | null => {
  if (annotations.length === 0) {
    return null;
  }
  if (annotations.length === 1) {
    return annotations[0];
  }
  const nerTaggingsAllowedIds = annotations
    .map((annotation: INERRelationAnnotationValue) =>
      annotation.tags.map((nerTagging) => nerTagging.id),
    )
    .reduce((nerTaggingIds1: string[], nerTaggingIds2: string[]) =>
      nerTaggingIds1.filter((nerTaggingId) =>
        nerTaggingIds2.includes(nerTaggingId),
      ),
    );

  const relationsTaggingsAllowedIds = annotations
    .map((annotation: INERRelationAnnotationValue) =>
      annotation.relations
        .filter(
          (relationTagging) =>
            nerTaggingsAllowedIds.includes(relationTagging.begin) &&
            nerTaggingsAllowedIds.includes(relationTagging.end),
        )
        .map((relations) => relations.id),
    )
    .reduce((relationTaggingIds1: string[], relationTaggingIds2: string[]) =>
      relationTaggingIds1.filter((relationTaggingId) =>
        relationTaggingIds2.includes(relationTaggingId),
      ),
    );

  return {
    tags: annotations[0].tags.filter((nerTagging) =>
      nerTaggingsAllowedIds.includes(nerTagging.id),
    ),
    relations: annotations[0].relations.filter((relationTagging) =>
      relationsTaggingsAllowedIds.includes(relationTagging.id),
    ),
  };
};

export default nerRelationGCF;
