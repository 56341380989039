import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { StorageMethod } from '@/store/store';
import LocalDataImportForm from '@/components/LocalDataImportForm/LocalDataImportForm.vue';
import AwsDataImportForm from '@/components/AwsDataImportForm/AwsDataImportForm.vue';
import { VueConstructor } from 'vue';
import OnPremiseIcon from '@/icons/OnPremise.vue';
import AwsIcon from '@/icons/Aws.vue';

@Component({
  computed: mapState(['storageMethods']),
  components: {
    LocalDataImportForm,
    AwsDataImportForm,
    OnPremiseIcon,
    AwsIcon,
  },
})
export default class DataImport extends Vue {
  public storageMethods!: StorageMethod[];
  public selected: StorageMethod | null = null;
  @Prop({ default: false }) public skippable!: boolean;

  public get formComponentByStorageMethod(): {
    [key: string]: VueConstructor<Vue>;
  } {
    return {
      [StorageMethod.LOCAL]: LocalDataImportForm,
      [StorageMethod.AWS]: AwsDataImportForm,
    };
  }

  public get optionIconByStorageMethod(): {
    [key: string]: VueConstructor<Vue>;
  } {
    return {
      [StorageMethod.LOCAL]: OnPremiseIcon,
      [StorageMethod.AWS]: AwsIcon,
    };
  }

  public get optionLabelByStorageMethod(): { [key: string]: string } {
    return {
      [StorageMethod.LOCAL]: 'On premise',
      [StorageMethod.AWS]: 'AWS',
    };
  }

  public submitDataset(event: any) {
    this.$emit('submit', {
      dataset: event,
      type: this.selected,
    });
  }

  public created() {
    if (this.storageMethods.length === 1) {
      this.selected = this.storageMethods[0];
    }
  }
}
