import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NERRelationDiffAnnotatorText from '@/illuin-annotation/components/NERRelation/NERRelationDiffAnnotatorText/NERRelationDiffAnnotatorText.vue';
import INERTagging from '@/illuin-annotation/models/interfaces/ner-tagging';
import IRelationTagging from '@/illuin-annotation/models/interfaces/relation-tagging';
import IRelation from '@/illuin-annotation/models/interfaces/relation';
import INERAnnotationValue from '@/illuin-annotation/models/interfaces/ner-annotation';
import INERRelationAnnotationValue from '@/illuin-annotation/models/interfaces/ner-relation-annotation';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import { getAvailableRelations } from '@/illuin-annotation/components/NERRelation/utils/relation-datamodel';
import NERRelationBaseAnnotatorMixin from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorMixin/NERRelationBaseAnnotatorMixin.ts';
import {
  formatExportableNerTaggings,
  formatExportableRelationTaggings,
  getNerTaggingsFromExported,
  getRelationTaggingsFromExported,
} from '@/illuin-annotation/components/NERRelation/utils/ner-relation-dto';
import IntersectionIcon from '@/icons/Intersection.vue';
import UnionIcon from '@/icons/Union.vue';
import {
  intersectionMerge,
  isUnionMergeable,
  unionMerge,
} from '@/illuin-annotation/components/NERRelation/utils/ner-relation-merger';
import IEntitiesLine from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/entities-line';
import IArrowsLine from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/arrows-line';
import IBlock from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/block';
import ILink from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/link';
import en from './lang/ner-relations-diff-annotator.en.json';
import fr from './lang/ner-relations-diff-annotator.fr.json';
import uuidByString from 'uuid-by-string';
import IAnnotation from '@/models/interfaces/annotation';
import IAnnotationValue from '@/illuin-annotation/models/types/annotation';

@Component({
  components: {
    NERRelationDiffAnnotatorText,
    IntersectionIcon,
    UnionIcon,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class NERRelationDiffAnnotator extends NERRelationBaseAnnotatorMixin {
  @Prop() public preAnnotations!: IAnnotation[];

  public nerTaggings: { [key: string]: INERTagging }[] = [{}, {}];
  public relationTaggings: IRelationTagging[][] = [[], []];

  public pendingNerTaggings: (INERTagging | null)[] = [null, null];
  public pendingRelationTaggings: (IRelationTagging | null)[] = [null, null];
  public pendingAvailableRelations: IRelation[] = [];

  public relationHeightsByLine: number[][] = [[], []];
  public minRelationHeightByLine: number[] = [];

  public displayTagSelectors: boolean[] = [false, false];
  public displayRelationSelectors: boolean[] = [false, false];

  public previewIntersection: boolean[] = [false, false];
  public previewUnion: boolean[] = [false, false];

  public entitiesLinesDatas: IEntitiesLine[][] = [[], []];
  public arrowsLinesDatas: IArrowsLine[][] = [[], []];

  @Watch('documentValue')
  public onDocumentChange() {
    this.nerTaggings = [{}, {}];
    this.relationTaggings = [[], []];
    this.pendingNerTaggings = [null, null];
    this.pendingRelationTaggings = [null, null];
    this.pendingAvailableRelations = [];
    this.displayTagSelectors = [false, false];
    this.displayRelationSelectors = [false, false];
  }

  @Watch('preAnnotationsValues')
  public onPreAnnotationsChange() {
    this.computePreAnnotations(this.preAnnotationsValues);
  }

  get preAnnotationsValues(): (INERAnnotationValue | INERRelationAnnotationValue | null)[] {
    return this.preAnnotations.map((preAnnotation) => preAnnotation.value as (INERAnnotationValue | INERRelationAnnotationValue));
  }

  public get annotations(): (
    | INERAnnotationValue
    | INERRelationAnnotationValue
  )[] {
    if (this.relations) {
      return [
        {
          tags: formatExportableNerTaggings(this.nerTaggings[0]),
          relations: formatExportableRelationTaggings(this.relationTaggings[0]),
        },
        {
          tags: formatExportableNerTaggings(this.nerTaggings[1]),
          relations: formatExportableRelationTaggings(this.relationTaggings[1]),
        },
      ];
    }
    return [
      {
        tags: formatExportableNerTaggings(this.nerTaggings[0]),
      },
      {
        tags: formatExportableNerTaggings(this.nerTaggings[1]),
      },
    ];
  }

  public get isUnionMergeable() {
    return isUnionMergeable(this.annotations[0], this.annotations[1]);
  }

  public get unionMerged() {
    return this.isUnionMergeable
      ? unionMerge(this.annotations[0], this.annotations[1])
      : null;
  }

  public get unionMergedNerTaggings() {
    return getNerTaggingsFromExported(this.unionMerged, this.tagsById);
  }

  public get unionMergedRelationTaggings() {
    return this.relations
      ? getRelationTaggingsFromExported(
          this.unionMerged,
          this.unionMergedNerTaggings,
          this.relationsById,
        )
      : [];
  }

  public get intersectionMerged() {
    return intersectionMerge(this.annotations[0], this.annotations[1]);
  }

  public get intersectionMergedNerTaggings() {
    return getNerTaggingsFromExported(this.intersectionMerged, this.tagsById);
  }

  public get intersectionMergedRelationTaggings() {
    return this.relations
      ? getRelationTaggingsFromExported(
          this.intersectionMerged,
          this.unionMergedNerTaggings,
          this.relationsById,
        )
      : [];
  }

  public get ids(): string[][] {
    const ids: string[][] = [];
    this.annotations.forEach((annotation) => {
      let sideIds = annotation.tags.map((tag) => tag.id);
      if (annotation.hasOwnProperty('relations')) {
        sideIds = sideIds.concat(
          (annotation as INERRelationAnnotationValue).relations.map(
            (relation) => relation.id,
          ),
        );
      }
      ids.push(sideIds);
    });
    return ids;
  }

  public get leftOnlyIds() {
    return this.ids[0].filter((id) => this.ids[1].indexOf(id) < 0);
  }

  public get rightOnlyIds() {
    return this.ids[1].filter((id) => this.ids[0].indexOf(id) < 0);
  }

  public get conflictedDisplayedLines(): number[] {
    const blockConflictedLines: Set<number> = new Set();
    const leftBlocksByLine: { [key: number]: any } = {};
    this.entitiesLinesDatas[0].forEach((entitiesLine: IEntitiesLine) => {
      blockConflictedLines.add(entitiesLine.y);
      leftBlocksByLine[entitiesLine.y] = entitiesLine.blocks
        .map((block: IBlock) => {
          return {
            beginX: block.beginX,
            endX: block.endX,
            tagId: block.tagging.tag!.id,
          };
        })
        .sort((blockA, blockB) => (blockA.beginX < blockB.beginX ? 1 : -1));
    });
    this.entitiesLinesDatas[1].forEach((entitiesLine: IEntitiesLine) => {
      if (leftBlocksByLine.hasOwnProperty(entitiesLine.y)) {
        if (
          JSON.stringify(leftBlocksByLine[entitiesLine.y]) ===
          JSON.stringify(
            entitiesLine.blocks
              .map((block: IBlock) => {
                return {
                  beginX: block.beginX,
                  endX: block.endX,
                  tagId: block.tagging.tag!.id,
                };
              })
              .sort((blockA, blockB) =>
                blockA.beginX < blockB.beginX ? 1 : -1,
              ),
          )
        ) {
          blockConflictedLines.delete(entitiesLine.y);
        }
      } else {
        blockConflictedLines.add(entitiesLine.y);
      }
    });

    const linkConflictedLines: Set<number> = new Set();
    const leftLinksByLine: { [key: number]: any } = {};
    this.arrowsLinesDatas[0].forEach((arrowsLine: IArrowsLine) => {
      linkConflictedLines.add(arrowsLine.y);
      leftLinksByLine[arrowsLine.y] = arrowsLine.links
        .map((link: ILink) => {
          return {
            firstToken: link.relation.begin.begin,
            beginX: link.beginX,
            endX: link.endX,
            relationId: link.relation.relation!.id,
          };
        })
        .sort((linkA, linkB) => (linkA.firstToken < linkB.firstToken ? 1 : -1));
    });
    this.arrowsLinesDatas[1].forEach((arrowsLine: IArrowsLine) => {
      if (leftLinksByLine.hasOwnProperty(arrowsLine.y)) {
        if (
          JSON.stringify(leftLinksByLine[arrowsLine.y]) ===
          JSON.stringify(
            arrowsLine.links
              .map((link: ILink) => {
                return {
                  firstToken: link.relation.begin.begin,
                  beginX: link.beginX,
                  endX: link.endX,
                  relationId: link.relation.relation!.id,
                };
              })
              .sort((linkA, linkB) =>
                linkA.firstToken < linkB.firstToken ? 1 : -1,
              ),
          )
        ) {
          linkConflictedLines.delete(arrowsLine.y);
        }
      } else {
        linkConflictedLines.add(arrowsLine.y);
      }
    });

    return [...new Set([...blockConflictedLines, ...linkConflictedLines])];
  }

  public get displayedNerTaggings(): { [key: string]: INERTagging }[] {
    if (this.previewUnion[0]) {
      return [this.unionMergedNerTaggings, this.nerTaggings[1]];
    }
    if (this.previewUnion[1]) {
      return [this.nerTaggings[0], this.unionMergedNerTaggings];
    }
    return this.nerTaggings;
  }

  public get displayedRelationTaggings(): IRelationTagging[][] {
    if (this.previewUnion[0]) {
      return [this.unionMergedRelationTaggings, this.relationTaggings[1]];
    }
    if (this.previewUnion[1]) {
      return [this.relationTaggings[0], this.unionMergedRelationTaggings];
    }
    return this.relationTaggings;
  }

  public get temporaryElementsIds(): string[][] {
    if (this.previewUnion[0]) {
      return [this.rightOnlyIds, []];
    }
    if (this.previewUnion[1]) {
      return [[], this.leftOnlyIds];
    }
    if (this.previewIntersection[0]) {
      return [this.leftOnlyIds, []];
    }
    if (this.previewIntersection[1]) {
      return [[], this.rightOnlyIds];
    }
    return [[], []];
  }

  public newEntitiesLinesData(
    entitiesLinesData: IEntitiesLine[],
    annotationIndex: number,
  ) {
    Vue.set(this.entitiesLinesDatas, annotationIndex, entitiesLinesData);
  }

  public newArrowsLinesData(
    arrowsLinesData: IArrowsLine[],
    annotationIndex: number,
  ) {
    Vue.set(this.arrowsLinesDatas, annotationIndex, arrowsLinesData);
  }

  public startPreviewIntersection(annotationIndex: number) {
    Vue.set(this.previewIntersection, annotationIndex, true);
  }
  public stopPreviewIntersection(annotationIndex: number) {
    Vue.set(this.previewIntersection, annotationIndex, false);
  }
  public startPreviewUnion(annotationIndex: number) {
    Vue.set(this.previewUnion, annotationIndex, true);
  }
  public stopPreviewUnion(annotationIndex: number) {
    Vue.set(this.previewUnion, annotationIndex, false);
  }

  public get unionButtonTooltip() {
    return this.isUnionMergeable
      ? this.$t('ner-relation-diff-annotator.union-tooltip')
      : this.$t('ner-relation-diff-annotator.union-impossible-tooltip');
  }

  public unionMerge(annotationIndex: number) {
    if (annotationIndex === 0) {
      this.computePreAnnotations([this.unionMerged, this.annotations[1]]);
    } else {
      this.computePreAnnotations([this.annotations[0], this.unionMerged]);
    }
  }

  public intersectionMerge(annotationIndex: number) {
    if (annotationIndex === 0) {
      this.computePreAnnotations([
        this.intersectionMerged,
        this.annotations[1],
      ]);
    } else {
      this.computePreAnnotations([
        this.annotations[0],
        this.intersectionMerged,
      ]);
    }
  }

  public computePreAnnotations(
    preAnnotationsValues: (INERAnnotationValue | INERRelationAnnotationValue | null)[],
  ) {
    if (preAnnotationsValues) {
      this.nerTaggings = [{}, {}];
      this.relationTaggings = [[], []];
      this.relationHeightsByLine = [[], []];
      preAnnotationsValues.forEach(
        (
          preAnnotation:
            | INERAnnotationValue
            | INERRelationAnnotationValue
            | null,
          index: number,
        ) => {
          Vue.set(
            this.nerTaggings,
            index,
            getNerTaggingsFromExported(preAnnotation, this.tagsById),
          );
          if (this.relations) {
            Vue.set(
              this.relationTaggings,
              index,
              getRelationTaggingsFromExported(
                preAnnotation,
                this.nerTaggings[index],
                this.relationsById,
              ),
            );
          }
        },
      );
    }
  }

  public addPendingNerTagging(
    infos: {
      taggingInfo: INERTagging;
      mousePosition: any;
    },
    annotationIndex: number,
  ) {
    Vue.set(this.pendingNerTaggings, annotationIndex, infos.taggingInfo);
    if (this.tags.length === 1) {
      this.confirmNerTagging(this.tags[0], annotationIndex);
    } else {
      this.mousePosition = infos.mousePosition;
      Vue.set(this.displayTagSelectors, annotationIndex, true);
    }
  }

  public confirmNerTagging(tag: ITag, annotationIndex: number) {
    if (this.pendingNerTaggings[annotationIndex]) {
      const id = uuidByString(
        `${this.pendingNerTaggings[annotationIndex]!.begin}-${
          this.pendingNerTaggings[annotationIndex]!.end
        }-${tag!.id}`,
      );
      Vue.set(this.nerTaggings[annotationIndex], id, {
        id,
        tag,
        begin: this.pendingNerTaggings[annotationIndex]!.begin,
        end: this.pendingNerTaggings[annotationIndex]!.end,
      });
    }
    Vue.set(this.displayTagSelectors, annotationIndex, false);
  }

  public addPendingRelationTagging(
    infos: {
      taggingInfo: IRelationTagging;
      mousePosition: any;
    },
    annotationIndex: number,
  ) {
    this.pendingAvailableRelations = getAvailableRelations(
      this.relations,
      infos.taggingInfo.begin.tag!.id,
      infos.taggingInfo.end.tag!.id,
    );
    if (this.pendingAvailableRelations.length === 1) {
      Vue.set(this.pendingRelationTaggings, annotationIndex, infos.taggingInfo);
      this.confirmRelationTagging(
        this.pendingAvailableRelations[0],
        annotationIndex,
      );
    } else if (this.pendingAvailableRelations.length > 1) {
      Vue.set(this.pendingRelationTaggings, annotationIndex, infos.taggingInfo);
      this.mousePosition = infos.mousePosition;
      Vue.set(this.displayRelationSelectors, annotationIndex, true);
    }
  }

  public confirmRelationTagging(relation: IRelation, annotationIndex: number) {
    if (this.pendingRelationTaggings[annotationIndex]) {
      let alreadyExisting = false;
      this.relationTaggings[annotationIndex].forEach((relationTagging) => {
        if (
          relationTagging.relation!.id === relation.id &&
          relationTagging.begin.id ===
            this.pendingRelationTaggings[annotationIndex]!.begin.id &&
          relationTagging.end.id ===
            this.pendingRelationTaggings[annotationIndex]!.end.id
        ) {
          alreadyExisting = true;
        }
      });
      if (!alreadyExisting) {
        const id = uuidByString(
          `${this.pendingRelationTaggings[annotationIndex]!.begin.begin}-${
            this.pendingRelationTaggings[annotationIndex]!.end.begin
          }-${relation.id}`,
        );
        this.relationTaggings[annotationIndex].push({
          id,
          relation,
          begin: this.pendingRelationTaggings[annotationIndex]!.begin,
          end: this.pendingRelationTaggings[annotationIndex]!.end,
          reversed: this.pendingRelationTaggings[annotationIndex]!.reversed,
        });
      }
    }
    Vue.set(this.displayRelationSelectors, annotationIndex, false);
  }

  public removeRelationsForNerTagging(
    nerTaggingId: string,
    annotationIndex: number,
  ) {
    Vue.set(
      this.relationTaggings,
      annotationIndex,
      this.relationTaggings[annotationIndex].filter(
        (relationTagging) =>
          relationTagging.begin.id !== nerTaggingId &&
          relationTagging.end.id !== nerTaggingId,
      ),
    );
  }

  public removeNerTagging(taggingId: string, annotationIndex: number) {
    this.removeRelationsForNerTagging(taggingId, annotationIndex);
    Vue.delete(this.nerTaggings[annotationIndex], taggingId);
  }

  public removeRelationTagging(taggingId: string, annotationIndex: number) {
    Vue.set(
      this.relationTaggings,
      annotationIndex,
      this.relationTaggings[annotationIndex].filter(
        (relationTagging) => relationTagging.id !== taggingId,
      ),
    );
  }

  public newRelationHeightByLine(
    newHeightByLine: number[],
    annotationIndex: number,
  ) {
    Vue.set(this.relationHeightsByLine, annotationIndex, newHeightByLine);
    this.computeMinRelationHeightByLine();
  }

  public computeMinRelationHeightByLine() {
    this.minRelationHeightByLine = this.relationHeightsByLine[0].map(
      (firstHeight, index) =>
        Math.max(firstHeight, this.relationHeightsByLine[1][index] || 0),
    );
  }

  private handleKeyup(event: any) {
    if (event.key && event.key === 'Escape') {
      this.displayTagSelectors = [false, false];
      this.displayRelationSelectors = [false, false];
    }
    if (event.key && this.tagsByKey[event.key]) {
      this.pendingNerTaggings.forEach((pendingNerTagging, index: number) => {
        if (pendingNerTagging) {
          this.confirmNerTagging(this.tagsByKey[event.key], index);
        }
      });
    }
  }

  private handleMouseUp() {
    this.displayTagSelectors = [false, false];
    this.displayRelationSelectors = [false, false];
  }

  private created() {
    this.computePreAnnotations(this.preAnnotationsValues);
  }

  private mounted() {
    window.addEventListener('keyup', this.handleKeyup);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  private beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }
}
