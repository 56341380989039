import { Component, Prop, Vue } from 'vue-property-decorator';
import AnnotatorTags from '@/illuin-annotation/components/common/AnnotatorTags/AnnotatorTags.vue';
import NERRelationAnnotatorText from '@/illuin-annotation/components/NERRelation/NERRelationAnnotatorText/NERRelationAnnotatorText.vue';
import ListRelationSelector from '@/illuin-annotation/components/NERRelation/ListRelationSelector/ListRelationSelector.vue';
import PieTagSelector from '@/illuin-annotation/components/common/PieTagSelector/PieTagSelector.vue';
import INerRelationsDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerrelationsdatamodel';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import INERToken from '@/illuin-annotation/models/interfaces/ner-token';
import IRelation from '@/illuin-annotation/models/interfaces/relation';
import IDocument from '@/models/interfaces/document';

@Component({
  components: {
    PieTagSelector,
    ListRelationSelector,
    AnnotatorTags,
    NERRelationAnnotatorText,
  },
})
export default class NERRelationBaseAnnotatorMixin extends Vue {
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: { raw: string; tokenized: INERToken[] };
  @Prop() public datamodel!: INerRelationsDataModel;
  @Prop() public passive!: boolean;

  public displayTagSelector: boolean = false;
  public displayRelationSelector: boolean = false;
  public mousePosition: { left: number; top: number } = {
    left: 0,
    top: 0,
  };

  public get tags(): ITag[] {
    return this.datamodel!.tags;
  }

  public get tagsByKey(): { [key: string]: ITag } {
    const tagsByKey: { [key: string]: ITag } = {};
    this.tags.forEach((tag: ITag) => {
      tagsByKey[tag.shortcut] = tag;
    });
    return tagsByKey;
  }

  public get tagsById(): { [key: string]: ITag } {
    const tagsById: { [key: string]: ITag } = {};
    this.tags.forEach((tag: ITag) => {
      tagsById[tag.id] = tag;
    });
    return tagsById;
  }

  public get relationsById(): { [key: string]: IRelation } {
    const relationsById: { [key: string]: IRelation } = {};
    this.relations.forEach((relation: IRelation) => {
      relationsById[relation.id] = relation;
    });
    return relationsById;
  }

  public get relations(): IRelation[] {
    return this.datamodel!.relations;
  }
}
