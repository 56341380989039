import { Component, Vue } from 'vue-property-decorator';
import BaseNav from '@/layouts/BaseNav/BaseNav.vue';
import NewProjectForm from '@/components/NewProjectForm/NewProjectForm.vue';
import DataModelForm from '@/illuin-annotation/factories/DataModelForm/DataModelForm.vue';
import IProject, { INewProject } from '@/models/interfaces/project';
import ProjectType from '@/models/enums/project-type';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import 'vue-swatches/dist/vue-swatches.min.css';
import DataImport from '@/components/DataImport/DataImport.vue';
import AnnotationsImportForm from '@/components/AnnotationsImportForm/AnnotationsImportForm.vue';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import IImport from '@/models/interfaces/import';
import { StorageMethod } from '@/store/store';

@Component({
  components: {
    BaseNav,
    NewProjectForm,
    DataModelForm,
    DataImport,
    AnnotationsImportForm,
  },
  providers: [ApiService],
})
export default class NewProject extends Vue {
  public project: INewProject = {
    name: '',
    type: ProjectType.NER,
    description: '',
    dataModel: null,
  };
  public dataset: any = null;
  public annotationsFile: any = null;
  public currentStep: number = 0;
  public creating: boolean = false;
  public creationStatusSentence: string = '';
  public storageMethodUsed: StorageMethod = StorageMethod.LOCAL;
  @inject() private readonly apiService!: ApiService;

  public selectStep(step: number) {
    if (step <= this.currentStep) {
      this.currentStep = step;
    }
  }

  public onProjectSubmitted(project: INewProject) {
    this.project = project;
    this.currentStep += 1;
  }

  public onDataModelSubmitted(dataModel: IDataModel) {
    this.project.dataModel = dataModel;
    this.currentStep += 1;
  }

  public onSkipDataset() {
    this.currentStep += 1;
  }

  public onDatasetSubmitted(datasetObject: {
    dataset: any;
    type: StorageMethod;
  }) {
    this.dataset = datasetObject.dataset;
    this.storageMethodUsed = datasetObject.type;
    this.currentStep += 1;
  }

  public onAnnotationsSubmitted(annotationsFile: any) {
    this.annotationsFile = annotationsFile;
    this.creationStatusSentence = this.$t(
      'new-project.confirm-modal.creation-status.ready',
    ) as string;
    this.$bvModal.show('confirmModal');
  }

  public waitImport(
    createdProject: IProject,
    importData: IImport,
    callback: (arg0: IProject) => void,
  ) {
    this.apiService
      .getImport(importData)
      .subscribe((newImportData: IImport) => {
        if (newImportData.finished) {
          if (newImportData.errored) {
            this.$notify({
              group: 'app',
              type: 'error',
              text: this.$t('alert.entries.addition.error', {
                errorMessage: newImportData.errorMessage,
              }) as string,
            });
          }
          callback(createdProject);
        } else {
          setTimeout(
            () => this.waitImport(createdProject, newImportData, callback),
            1000,
          );
        }
      });
  }

  public createProject(event: any): void {
    event.preventDefault();
    if (this.project) {
      this.creating = true;
      this.creationStatusSentence = this.$t(
        'new-project.confirm-modal.creation-status.creating',
      ) as string;
      this.apiService
        .createProject(this.project)
        .subscribe((createdProject: IProject) => {
          if (this.dataset) {
            this.creationStatusSentence = this.$t(
              'new-project.confirm-modal.creation-status.import',
            ) as string;
            if (this.storageMethodUsed === StorageMethod.LOCAL) {
              this.apiService
                .importData(createdProject, this.dataset)
                .subscribe((importData: IImport) => {
                  this.waitImport(
                    createdProject,
                    importData,
                    this.annotationsFile
                      ? this.uploadDefaultAnnotations(createdProject)
                      : this.onceProjectCreated,
                  );
                });
            } else if (this.storageMethodUsed === StorageMethod.AWS) {
              this.apiService
                .importAwsData(createdProject, this.dataset)
                .subscribe((documents) => {
                  this.annotationsFile
                      ? this.uploadDefaultAnnotations(createdProject)()
                      : this.onceProjectCreated(createdProject);
                });
            } else {
              this.$notify({
                group: 'app',
                type: 'error',
                text: this.$t('alert.entries.addition.error', {
                  errorMessage: 'Invalid file extension',
                }) as string,
              });
            }
          } else {
            this.onceProjectCreated(createdProject);
          }
        });
    }
  }

  public uploadDefaultAnnotations(createdProject: IProject) {
    return () => {
      this.creationStatusSentence = this.$t(
        'new-project.confirm-modal.creation-status.import-annotations',
      ) as string;
      return this.apiService
        .importAnnotations(createdProject, this.annotationsFile)
        .subscribe((importData: IImport) => {
          this.waitImport(createdProject, importData, this.onceProjectCreated);
        });
    };
  }

  public onceProjectCreated(createdProject: IProject) {
    this.$store.dispatch('onProjectCreated').then(() => {
      this.$router.push({
        name: 'project-admin-settings-roles',
        params: { id: createdProject.id },
      });
      this.$notify({
        group: 'app',
        type: 'success',
        text: this.$t('alert.project.creation.success') as string,
      });
    });
  }
}
