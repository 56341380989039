import { Component, Prop, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import { INewProject } from '@/models/interfaces/project';
import { mapState } from 'vuex';

@Component({
  computed: mapState(['types', 'defaultColors']),
  components: {
    Swatches,
  },
})
export default class NewProjectForm extends Vue {
  @Prop() public project!: INewProject;
  public types!: { [key: string]: string }[];
  public defaultColors!: string[];

  public onSubmit(event: any) {
    event.preventDefault();
    this.$emit('submit', this.project);
  }
}
