import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IClassificationAnnotationValue from '@/illuin-annotation/models/interfaces/classification.annotation';
import Previewer from '@/illuin-annotation/factories/Previewer/Previewer.vue';
import ProjectType from '@/models/enums/project-type';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import en from './lang/classification-diff-annotator.en.json';
import fr from './lang/classification-diff-annotator.fr.json';
import IDocument from '@/models/interfaces/document';
import { intersection } from '@/illuin-annotation/services/utils/array-utils';
import AnnotatorConflictedTags
  from '@/illuin-annotation/components/Classification/ClassesConflictedTags/ClassesConflictedTags.vue';
import { PreviewStrategy } from '@/illuin-annotation/components/Classification/utils/preview-strategy';
import IAnnotation from '@/models/interfaces/annotation';

@Component({
  components: { AnnotatorConflictedTags, Previewer },
  i18n: {
    messages: { en, fr },
  },
})
export default class ClassificationDiffAnnotator extends Vue {
  @Prop() public documentValue!: any;
  @Prop() public document!: IDocument;
  @Prop() public datamodel!: INerDataModel;
  @Prop() public passive!: boolean;
  @Prop() public type!: ProjectType;
  @Prop() public preAnnotations!: IAnnotation[];

  public mismatchTagIdsList: string[][] = [];
  public commonTagIds: string[] = [];

  public previewStrategy: PreviewStrategy | null = null;
  public PreviewStrategy = PreviewStrategy;

  public ProjectType = ProjectType;

  public get preAnnotationsValues(): IClassificationAnnotationValue[] {
    return this.preAnnotations.map((preAnnotation) => preAnnotation.value as IClassificationAnnotationValue);
  }

  @Watch('preAnnotationsValues', { immediate: true })
  public onPreAnnotationChange() {
    this.commonTagIds = [];
    if (this.preAnnotationsValues) {
      this.commonTagIds = intersection(this.preAnnotationsValues.map((preAnnotation) => preAnnotation.tagIds));
      this.mismatchTagIdsList = this.preAnnotationsValues.map((preAnnotation) => preAnnotation.tagIds.filter((tagId) => !this.commonTagIds.includes(tagId)));
    }
  }

  @Watch('document', { immediate: true })
  public onDocumentChange() {
    if (!this.preAnnotationsValues) {
      this.commonTagIds = [];
      this.mismatchTagIdsList = [];
    }
  }

  get annotation(): IClassificationAnnotationValue {
    return {
      tagIds: this.commonTagIds,
    };
  }

  public get tagByShortcut() {
    const tags: { [key: string]: ITag } = {};
    for (const tag of this.datamodel.tags) {
      tags[tag.shortcut.toLowerCase()] = tag;
    }
    return tags;
  }

  public get canSubmit() {
    return !this.mismatchTagIdsList.some((mismatchTagIds) => mismatchTagIds.length > 0);
  }

  public openFullDocument() {
    this.$bvModal.show('fullDocumentModal');
  }

  public onTagSelected(tag: ITag, fromKeyup = false) {
    let goNext = false;
    if (
      this.type === ProjectType.TEXT_CLASSIFICATION ||
      this.type === ProjectType.IMAGE_CLASSIFICATION
    ) {
      if (this.commonTagIds.length > 0 && this.commonTagIds[0] === tag.id) {
        this.commonTagIds = [];
      } else {
        this.commonTagIds = [tag.id];
        this.mismatchTagIdsList = this.mismatchTagIdsList.map(() => []);
        goNext = fromKeyup;
      }
    } else {
      if (this.commonTagIds.includes(tag.id)) {
        this.commonTagIds = this.commonTagIds.filter((tagId) => tagId !== tag.id);
      } else if (this.mismatchTagIdsList.some((mismatchTagIds) => mismatchTagIds.includes(tag.id))) {
        this.commonTagIds.push(tag.id);
        this.mismatchTagIdsList = this.mismatchTagIdsList.map((mismatchTagIds) => mismatchTagIds.filter((tagId) => tagId !== tag.id));
      } else {
        this.commonTagIds.push(tag.id);
      }
    }
    if (goNext) {
      this.$emit('confirm', this.annotation);
    }
  }

  public trust(idx: number) {
    if (idx < this.mismatchTagIdsList.length) {
      this.commonTagIds = this.commonTagIds.concat(this.mismatchTagIdsList[idx]);
    }
    this.mismatchTagIdsList = this.mismatchTagIdsList.map(() => []);
  }

  public rejectAll() {
    this.mismatchTagIdsList = this.mismatchTagIdsList.map(() => []);
  }

  public keepAll() {
    const missingToCommonTagIds: Set<string> = new Set();
    this.mismatchTagIdsList.forEach((mismatchTagIds) => {
      mismatchTagIds.forEach((tagId) => missingToCommonTagIds.add(tagId));
    });
    this.commonTagIds = this.commonTagIds.concat(Array.from(missingToCommonTagIds));
    this.mismatchTagIdsList = this.mismatchTagIdsList.map(() => []);
  }

  private handleKeyup(event: { key?: string }) {
    if (event.key) {
      if (Object.keys(this.tagByShortcut).includes(event.key.toLowerCase())) {
        this.onTagSelected(this.tagByShortcut[event.key.toLowerCase()], true);
      }
    }
  }

  private mounted() {
    window.addEventListener('keyup', this.handleKeyup);
  }
}
