import { Component, Prop, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import en from './lang/ner-display.en.json';
import fr from './lang/ner-display.fr.json';

@Component({
  components: {
    Swatches,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class NerDisplay extends Vue {
  @Prop() public datamodel!: INerDataModel;
}
