import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { LoginMethod } from '@/store/store';

@Component({
  computed: {
    ...mapState(['loginMethod']),
  },
})
export default class MenuBarPlatform extends Vue {
  public loginMethod!: LoginMethod;
  public get canCreateAccounts() {
    switch (this.loginMethod) {
      case 'google':
        return false;
      case 'simple':
      default:
        return true;
    }
  }
}
