import { Component, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import EditProjectForm from '@/components/EditProjectForm/EditProjectForm.vue';
import IProject, { INewProject } from '@/models/interfaces/project';
import 'vue-swatches/dist/vue-swatches.min.css';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import ProjectInfosViewer from '@/components/ProjectInfosViewer/ProjectInfosViewer.vue';

@Component({
  computed: mapState('admin-project', ['project']),
  components: {
    Swatches,
    ProjectInfosViewer,
    EditProjectForm,
  },
  providers: [ApiService],
})
export default class ProjectInfos extends Vue {
  public project!: IProject;
  public editing: boolean = false;
  @inject() private readonly apiService!: ApiService;

  public onDelete() {
    this.$router.push({ name: 'project-list' });
    this.apiService.deleteProject(this.project.id).subscribe(() => {
      this.$store.dispatch('onProjectDeleted').then();
    });
  }

  public onSubmit(editedProject: INewProject) {
    this.editing = false;
    this.apiService
      .updateProject(this.project.id, editedProject)
      .subscribe((project: IProject) => {
        this.$store.dispatch('onProjectUpdated', project);
      });
  }
}
