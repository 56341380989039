enum Permission {
  PLATFORM_CREATE_PROJECT = 'PLATFORM_CREATE_PROJECT',
  PLATFORM_ASSIGN_ROLE = 'PLATFORM_ASSIGN_ROLE',
  PLATFORM_VIEW_USERS = 'PLATFORM_VIEW_USERS',
  PLATFORM_CREATE_USERS = 'PLATFORM_CREATE_USERS',
  PROJECT_READ_INFO = 'PROJECT_READ_INFO',
  PROJECT_ASSIGN_ROLE = 'PROJECT_ASSIGN_ROLE',
  PROJECT_ASSIGN_TASK = 'PROJECT_ASSIGN_TASK',
  PROJECT_EXPORT_DATA = 'PROJECT_EXPORT_DATA',
  PROJECT_HANDLE_CONFLICT = 'PROJECT_HANDLE_CONFLICT',
  PROJECT_IMPORT_DATA = 'PROJECT_IMPORT_DATA',
  PROJECT_CLOSE_TASK = 'PROJECT_CLOSE_TASK',
  PROJECT_REMOVE_PROJECT = 'PROJECT_REMOVE_PROJECT',
  PROJECT_UPDATE_SETTINGS = 'PROJECT_UPDATE_SETTINGS',
  PROJECT_VIEW_DOCUMENTS = 'PROJECT_VIEW_DOCUMENTS',
  PROJECT_READ_POOL_INFO = 'PROJECT_READ_POOL_INFO',
  POOL_READ_INFO = 'POOL_READ_INFO',
  TASK_ANNOTATE = 'TASK_ANNOTATE',
}

const directiveToPermission: { [directiveAction: string]: Permission } = {
  platformCreateProject: Permission.PLATFORM_CREATE_PROJECT,
  platformAssignRole: Permission.PLATFORM_ASSIGN_ROLE,
  platformViewUsers: Permission.PLATFORM_VIEW_USERS,
  platformCreateUsers: Permission.PLATFORM_CREATE_USERS,
  projectReadInfo: Permission.PROJECT_READ_INFO,
  projectAssignRole: Permission.PROJECT_ASSIGN_ROLE,
  projectAssignTask: Permission.PROJECT_ASSIGN_TASK,
  projectExportData: Permission.PROJECT_EXPORT_DATA,
  projectHandleConflict: Permission.PROJECT_HANDLE_CONFLICT,
  projectImportData: Permission.PROJECT_IMPORT_DATA,
  projectCloseTask: Permission.PROJECT_CLOSE_TASK,
  projectRemoveProject: Permission.PROJECT_REMOVE_PROJECT,
  projectUpdateSettings: Permission.PROJECT_UPDATE_SETTINGS,
  projectViewDocuments: Permission.PROJECT_VIEW_DOCUMENTS,
  projectReadPoolInfo: Permission.PROJECT_READ_POOL_INFO,
  poolReadInfo: Permission.POOL_READ_INFO,
};

export default Permission;
export { directiveToPermission };
