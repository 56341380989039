import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IClassificationAnnotationValue from '@/illuin-annotation/models/interfaces/classification.annotation';
import AnnotatorTags from '@/illuin-annotation/components/common/AnnotatorTags/AnnotatorTags.vue';
import Previewer from '@/illuin-annotation/factories/Previewer/Previewer.vue';
import ProjectType from '@/models/enums/project-type';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import en from './lang/classification-annotator.en.json';
import fr from './lang/classification-annotator.fr.json';
import IDocument from '@/models/interfaces/document';

@Component({
  components: { AnnotatorTags, Previewer },
  i18n: {
    messages: { en, fr },
  },
})
export default class ClassificationAnnotator extends Vue {
  @Prop() public documentValue!: any;
  @Prop() public document!: IDocument;
  @Prop() public datamodel!: INerDataModel;
  @Prop() public passive!: boolean;
  @Prop() public type!: string;
  @Prop() public preAnnotation!: IClassificationAnnotationValue;

  public selectedTags: ITag[] = [];

  @Watch('preAnnotation', { immediate: true })
  public onPreAnnotationChange() {
    this.selectedTags = [];
    if (this.preAnnotation) {
      this.preAnnotation.tagIds.forEach((tagId: string) => {
        const tag = this.datamodel.tags.find(
          (datamodelTag) => datamodelTag.id === tagId,
        );
        if (tag) {
          this.onTagSelected(tag);
        }
      });
    }
    this.$emit('setAnnotation', this.annotation);
  }

  @Watch('document', { immediate: true })
  public onDocumentChange() {
    if (!this.preAnnotation) {
      this.selectedTags = [];
    }
    this.$emit('setAnnotation', this.annotation);
  }

  get annotation(): IClassificationAnnotationValue {
    return {
      tagIds: this.selectedTags.map((tag) => tag.id),
    };
  }

  public get tagByShortcut() {
    const tags: { [key: string]: ITag } = {};
    for (const tag of this.datamodel.tags) {
      tags[tag.shortcut.toLowerCase()] = tag;
    }
    return tags;
  }

  public openFullDocument() {
    this.$bvModal.show('fullDocumentModal');
  }

  public onTagSelected(tag: ITag, fromKeyup = false) {
    let goNext = false;
    if (
      this.type === ProjectType.TEXT_CLASSIFICATION ||
      this.type === ProjectType.IMAGE_CLASSIFICATION
    ) {
      if (this.selectedTags.length > 0 && this.selectedTags[0].id === tag.id) {
        this.selectedTags = [];
      } else {
        this.selectedTags = [tag];
        goNext = fromKeyup;
      }
    } else {
      if (this.annotation.tagIds.indexOf(tag.id) > -1) {
        this.selectedTags = this.selectedTags.filter(
          (selectedTag) => selectedTag.id !== tag.id,
        );
      } else {
        this.selectedTags.push(tag);
      }
    }
    this.$emit('setAnnotation', this.annotation);
    if (
      goNext &&
      (this.type === ProjectType.TEXT_CLASSIFICATION ||
        this.type === ProjectType.IMAGE_CLASSIFICATION)
    ) {
      this.$emit('next');
    }
  }

  private handleKeyup(event: { key?: string }) {
    if (event.key) {
      if (Object.keys(this.tagByShortcut).includes(event.key.toLowerCase())) {
        this.onTagSelected(this.tagByShortcut[event.key.toLowerCase()], true);
      }
    }
  }

  private mounted() {
    window.addEventListener('keyup', this.handleKeyup);
  }
}
