import { Component, Prop, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import { v4 as uuid } from 'uuid';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import en from './lang/ner-datamodel-form.en.json';
import fr from './lang/ner-datamodel-form.fr.json';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import _ from 'lodash';

@Component({
  components: {
    Swatches,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class NerDataModelForm extends Vue {
  @Prop() public datamodel!: INerDataModel;

  public defaultColors: string[] = [
    '#666666',
    '#32BCB1',
    '#881D81',
    '#D32455',
    '#6570FF',
    '#E28624',
    '#9ED400',
    '#000000',
  ];

  public editedDatamodel: INerDataModel = {
    tags: [],
  };

  public get tagCustomIdDuplicates() {
    return this.editedDatamodel.tags
      .map((tag: ITag) => tag.customId)
      .filter((value, index, self) => self.indexOf(value) !== index);
  }

  public changeShortcut(index: number, event: any) {
    this.editedDatamodel.tags[index].shortcut = event.key;
  }

  public addTag() {
    const id: string = uuid();
    this.editedDatamodel.tags.push({
      id,
      customId: id,
      title: '',
      shortcut: '',
      color: this.defaultColors[
        this.editedDatamodel.tags.length % this.defaultColors.length
      ],
    });
  }

  public deleteTag(index: number) {
    this.editedDatamodel.tags.splice(index, 1);
  }

  public onSubmit(event: any) {
    event.preventDefault();
    this.$emit('submit', this.editedDatamodel);
  }

  public created() {
    if (this.datamodel) {
      this.editedDatamodel = _.cloneDeep(this.datamodel);
    } else {
      this.addTag();
    }
  }
}
