import { Component, Prop, Vue } from 'vue-property-decorator';
import IPool from '@/models/interfaces/pool';
import ApiService from '@/services/api';
import { mapState } from 'vuex';
import IAnnotation from '@/models/interfaces/annotation';
import Annotator from '@/illuin-annotation/factories/Annotator/Annotator.vue';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import INERAnnotationValue from '@/illuin-annotation/models/interfaces/ner-annotation';
import INERRelationAnnotationValue from '@/illuin-annotation/models/interfaces/ner-relation-annotation';
import DiffAnnotator from '@/illuin-annotation/factories/DiffAnnotator/DiffAnnotator.vue';
import IImageAnnotationValue from '@/illuin-annotation/models/interfaces/image-annotation';
import IDocument from '@/models/interfaces/document';
import ITask from '@/models/interfaces/task';

@Component({
  computed: {
    ...mapState('pool-admin', ['pool', 'tasks']),
    ...mapState(['userEmail']),
  },
  components: { DiffAnnotator, Annotator },
  providers: [ApiService],
})
export default class ConflictHandlerSidebyside extends Vue {
  public pool!: IPool;
  public tasks!: ITask[];
  public userEmail!: string;

  @Prop() public annotations!: IAnnotation[];
  @Prop() public projectType!: string;
  @Prop() public datamodel!: IDataModel;
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: any;
  @Prop() public passive!: boolean;

  public key(annotation: IAnnotation) {
    return JSON.stringify(annotation.value);
  }

  public isAnnotator(annotation: IAnnotation) {
    return annotation.user.email === this.userEmail;
  }

  public annotate() {
    let userTask: ITask | null = null;

    for (const task of this.tasks) {
      if (task.user.email === this.userEmail) {
        userTask = task;
      }
    }

    if (userTask) {
      this.$router.push({
        name: 'annotation',
        params: { taskId: userTask.id },
        query: { documentId: this.document.id },
      });
    }
  }
}
