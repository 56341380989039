import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import IProject from '@/models/interfaces/project';
import LogoutButton from '@/components/LogoutButton/LogoutButton.vue';

@Component({
  computed: mapState(['projects']),
  components: {
    LogoutButton,
  },
})
export default class NavBar extends Vue {
  public projects!: IProject[];

  public setLocale(locale: string) {
    this.$store.commit('setLocale', locale);
    this.$i18n.locale = locale;
    this.$router.go(0);
  }

  public get canSeeProjectsTab() {
    return (
      this.projects.some((project) =>
        this.$can('projectHandleConflict', project.id),
      ) || this.$can('platformCreateProject', null)
    );
  }
}
