import { Component, Mixins } from 'vue-property-decorator';
import GoogleSigninMixin from '@/mixins/google-signin';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';

@Component({
  providers: [ApiService],
})
export default class GoogleLogin extends Mixins(GoogleSigninMixin) {
  @inject() private readonly apiService!: ApiService;

  public onSigninSuccess(user: any) {
    const token = user.getAuthResponse().access_token;
    this.apiService.authenticate(token).subscribe((response: any) => {
      if (response && response.token) {
        this.$store.commit('setBackToken', response.token);
        this.$router.push((this.$route.query.redirect as string) || '/');
      }
    });
  }

  public mounted() {
    this.renderSigninButton();
  }
}
