import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import en from './lang/ocr-transcription-tile.en.json';
import fr from './lang/ocr-transcription-tile.fr.json';
import IOCRTagging from '@/illuin-annotation/models/interfaces/ocr-tagging';
import { getImageSize } from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorRenderer/utils/utils';
import { computeImageSource } from '@/illuin-annotation/services/utils/image-buffer';

@Component({
  components: {
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class OCRTranscriptionTile extends Vue {
  @Prop() tagging!: IOCRTagging;
  @Prop() public imageBuffer!: any;
  @Prop() public imageSrc!: string;

  public imageSize: {x: number, y: number} | null = null;

  public maxHeight: number = 200;
  public maxWidth: number = 0;
  public width: number = 0;
  public height: number = 0;

  public content: string = '';

  @Watch('tagging', { immediate: true })
  public onTaggingChange() {
    this.content = this.tagging.content;
  }

  public get clip() {
    const xs: number[] = this.tagging.vertices.map((vertex) => vertex.x);
    const ys: number[] = this.tagging.vertices.map((vertex) => vertex.y);
    const left = Math.floor(this.imageSize!.x * Math.min(...xs));
    const top = Math.floor(this.imageSize!.y * Math.min(...ys));
    return {
      sx: left,
      sy: top,
      swidth: Math.max(0, Math.floor(this.imageSize!.x * Math.max(...xs) - left)),
      sheight: Math.max(0, Math.floor(this.imageSize!.y * Math.max(...ys) - top)),
    }
  }

  public async drawImage() {
    const el = document.getElementById(this.tagging.id);
    if (el) {
      const ctx = (el as HTMLCanvasElement).getContext('2d');
      if (ctx) {
        const img = new Image();

        img.onload = () => {
          const height = this.maxWidth * this.clip.sheight / this.clip.swidth;
          if (height > this.maxHeight) {
            this.height = this.maxHeight;
            this.width = this.maxHeight * this.maxWidth / height;
          } else {
            this.width = this.maxWidth;
            this.height = height;
          }
          ctx.drawImage(img, this.clip.sx, this.clip.sy, this.clip.swidth, this.clip.sheight, 0, 0, this.width, this.height);
        }
        img.src = this.imageSrc;
      }
    }
  }

  private async mounted() {
    this.maxWidth = this.$el.clientWidth - 2;
    this.imageSize = await getImageSize(computeImageSource(this.imageBuffer));
    this.drawImage();
  }

  private updated() {
    this.drawImage();
  }
}
