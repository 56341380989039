import _Vue, { PluginObject } from 'vue';
import { directiveToPermission } from '@/models/enums/permission';
import store from '@/store/store';

const can = (action: string, resourceId: string | null = null): boolean => {
  const permissions = store.state.permissions;

  if (!permissions) {
    return false;
  }

  const requiredPermission = directiveToPermission[action];
  const allowedResources = permissions[requiredPermission];
  if (allowedResources === 'all') {
    // User has this permission for all resources.
    return true;
  }
  if (
    resourceId &&
    allowedResources &&
    allowedResources.some((resource: string) => resourceId === resource)
  ) {
    // User has permission for this specific resource.
    return true;
  }
  // User doesn't have the required permission on this resource.
  return false;
};

export default class PermissionsPlugin implements PluginObject<{}> {
  public install(vue: typeof _Vue) {
    vue.directive('can', (el, binding, vnode) => {
      const action = binding.arg;
      if (!action) {
        return;
      }
      const resourceId = binding.value ? binding.value.id : null;

      const canAccess = can(action, resourceId);
      if (!canAccess) {
        el.style.display = 'none';
      }
    });

    vue.prototype.$can = can;
  }
}

export { can };
