import { Component, Vue } from 'vue-property-decorator';
import { ReplaySubject } from 'rxjs';
import { mapState } from 'vuex';

@Component({
  computed: {
    ...mapState(['googleClientId']),
  },
})
export default class GoogleSigninMixin extends Vue {
  public googleClientId!: string;
  private GOOGLE_SIGNIN_CONFIG: { [key: string]: string } = {};

  private auth2: ReplaySubject<any> = new ReplaySubject(1);

  public onSigninSuccess(user: any): void {
    return undefined;
  }

  public onSigninFailure(error: any): void {
    return undefined;
  }

  public renderSigninButton() {
    this.attachGoogleSignIn();
    this.auth2.subscribe(() => {
      window.gapi.signin2.render('google-login', {
        scope: 'profile email',
        width: 240,
        height: 50,
        longtitle: true,
        theme: 'dark',
        onsuccess: this.onSigninSuccess,
        onfailure: this.onSigninFailure,
      });
    });
  }

  public signOut() {
    this.attachGoogleSignIn();
    this.auth2.subscribe(() => {
      const authInstance = window.gapi.auth2.getAuthInstance();
      authInstance.signOut().then(() => {
        this.$store.commit('deleteBackToken');
        this.$router.push({ name: 'login' });
      });
    });
  }

  public attachGoogleSignIn() {
    const node = document.createElement('script');
    node.src = 'https://apis.google.com/js/platform.js';
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
    node.onload = () => {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init(this.GOOGLE_SIGNIN_CONFIG).then(() => {
          this.auth2.next();
        });
      });
    };
  }

  public created() {
    this.GOOGLE_SIGNIN_CONFIG = {
      client_id: this.googleClientId,
      cookiepolicy: 'single_host_origin',
      scope: 'email',
      prompt: 'select_account',
    };
  }
}
