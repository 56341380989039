import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreValue from '@/illuin-annotation/models/dtos/score-value';
import { mapState } from 'vuex';
import IPool from '@/models/interfaces/pool';
import NerScoreArray from '@/illuin-annotation/components/NER/NerScoreArray/NerScoreArray.vue';
import { AnnotationType } from '@/models/enums/annotation-type';
import ClassificationScoreArray
  from '@/illuin-annotation/components/Classification/ClassificationScoreArray/ClassificationScoreArray.vue';

@Component({
  computed: mapState('pool-admin', ['pool']),
})
export default class ScoreArray extends Vue {
  public pool!: IPool;
  @Prop() public scoreValue!: ScoreValue;

  public get componentName() {
    switch (this.pool.project.annotationType) {
      case AnnotationType.Ner:
      case AnnotationType.NerRelations:
      case AnnotationType.ObjectDetection:
      case AnnotationType.OCR:
        return NerScoreArray;
      case AnnotationType.Classification:
      case AnnotationType.MultiLabelClassification:
        return ClassificationScoreArray;
      default:
        return;
    }
  }
}
