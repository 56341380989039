import { Component, Prop, Vue } from 'vue-property-decorator';
import stringToHslColor from '@/utils/pastel';

@Component
export default class PastelIcon extends Vue {
  @Prop(String) public name!: string;
  @Prop({ type: Boolean, default: false }) public small!: boolean;

  public getColor(projectName: string) {
    return stringToHslColor(projectName, 30, 86);
  }
}
