import { Component, Vue } from 'vue-property-decorator';
import IPool from '@/models/interfaces/pool';
import { mapState } from 'vuex';
import { en, fr } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import { UpdatePoolDto } from '@/models/dtos/update-pool.dto';

@Component({
  computed: mapState('pool-admin', ['pool']),
  components: { Datepicker },
})
export default class PoolSettings extends Vue {
  public pool!: IPool;
  public newDueDate: Date = new Date();

  public disabledDates = {
    to: new Date(Date.now() - 1000 * 60 * 60 * 24),
  };

  public datePickerLocale = {
    en,
    fr,
  };

  public get localeDueDateString() {
    return new Date(this.pool.dueDate).toLocaleDateString(this.$i18n.locale, {
      dateStyle: 'full',
    });
  }

  public onEditPool() {
    const poolUpdate: UpdatePoolDto = {
      dueDate: this.newDueDate,
    };
    this.$emit('editPool', poolUpdate);
  }

  public onOpenPool() {
    this.$emit('openPool');
  }

  public onDeletePool() {
    this.$emit('deletePool');
  }

  public onClosePool() {
    this.$emit('closePool');
  }

  public created() {
    this.newDueDate = new Date(this.pool.dueDate);
    this.newDueDate.toLocaleDateString();
  }
}
