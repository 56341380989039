import { Component, Prop, Vue } from 'vue-property-decorator';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';
import IProject from '@/models/interfaces/project';
import TaskItem from '@/components/TaskItem/TaskItem.vue';

@Component({
  components: { PastelIcon, TaskItem },
})
export default class TasksList extends Vue {
  @Prop() public closed!: boolean;
  @Prop() public tasks!: TaskMetadataDto[];
  @Prop() public projects!: { [projectId: string]: IProject };
}
