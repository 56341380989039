import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import AnnotatorTags from '@/illuin-annotation/components/common/AnnotatorTags/AnnotatorTags.vue';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import ObjectDetectionAnnotatorRenderer from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorRenderer/ObjectDetectionAnnotatorRenderer.vue';
import ObjectDetectionAnnotatorMenu from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorMenu/ObjectDetectionAnnotatorMenu.vue';
import PieTagSelector from '@/illuin-annotation/components/common/PieTagSelector/PieTagSelector.vue';
import IOCRAnnotationValue from '@/illuin-annotation/models/interfaces/ocr-annotation';
import uuidByString from 'uuid-by-string';
import { computeImageSource, toArrayBuffer } from '@/illuin-annotation/services/utils/image-buffer';
import en from './lang/ocr-annotator.en.json';
import fr from './lang/ocr-annotator.fr.json';
import IDocument from '@/models/interfaces/document';
import IOCRTagging from '@/illuin-annotation/models/interfaces/ocr-tagging';
import { createWorker } from 'tesseract.js';
import { from, ReplaySubject, Subject } from 'rxjs';
import { getImageSize } from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorRenderer/utils/utils';
import { takeUntil } from 'rxjs/operators';
import OCRTranscriptionPanel from '@/illuin-annotation/components/OCR/OCRTranscriptionPanel/OCRTranscriptionPanel.vue';

export enum Mode {
  inspect = 'inspect',
  rectangle = 'rectangle',
  polygon = 'polygon',
  erase = 'erase',
}

@Component({
  components: {
    AnnotatorTags,
    ObjectDetectionAnnotatorMenu,
    ObjectDetectionAnnotatorRenderer,
    PieTagSelector,
    OCRTranscriptionPanel,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class OCRAnnotator extends Vue {
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: { raw: { data: ArrayBuffer; type: string } };
  @Prop() public datamodel!: INerDataModel;
  @Prop() public passive!: boolean;
  @Prop() public preAnnotation!: IOCRAnnotationValue;

  public mode: Mode = Mode.rectangle;

  public taggings: IOCRTagging[] = [];

  public highlightedTaggingId: string | null = null;

  public protectedTaggingsId: Set<string> = new Set();

  public displayTagSelector: boolean = false;
  public mousePosition: { [key: string]: number } = {
    left: 0,
    top: 0,
  };
  public imagePosition: { [key: string]: number } = {
    left: 0,
    top: 0,
  };

  public pendingTagging: IOCRTagging | null = null;

  public pendingTesseractWorkers: Array<any> = [];
  public taggings$: ReplaySubject<IOCRTagging> = new ReplaySubject<IOCRTagging>();
  public destroy$ = new Subject<void>();
  public imageSize: {x: number, y: number} = {x:0, y:0};

  @Watch('documentValue')
  public onDocumentChange() {
    this.clear();
    this.computePreAnnotation();
    this.$emit('setAnnotation', this.annotation);
  }

  @Watch('preAnnotation')
  public onPreAnnotationChange() {
    this.computePreAnnotation();
  }

  public get tags(): ITag[] {
    return this.datamodel.tags;
  }

  public get imageBuffer() {
    return toArrayBuffer(this.documentValue.raw.data);
  }

  public get imageSrc() {
    return computeImageSource(this.imageBuffer);
  }

  public get tagsByKey(): { [key: string]: ITag } {
    const tagsByKey: { [key: string]: ITag } = {};
    this.tags.forEach((tag: ITag) => {
      tagsByKey[tag.shortcut] = tag;
    });
    return tagsByKey;
  }

  public get tagsById(): { [key: string]: ITag } {
    const tagsById: { [key: string]: ITag } = {};
    this.tags.forEach((tag: ITag) => {
      tagsById[tag.id] = tag;
    });
    return tagsById;
  }

  public get annotation(): IOCRAnnotationValue {
    return {
      tags: this.taggings.map((tagging: IOCRTagging) => {
        return {
          id: uuidByString(
              `${tagging.vertices.map((v) => `${v.x} ${v.y}`).join(' ')}-${
                  tagging.tag!.id
              }`,
          ),
          vertices: tagging.vertices,
          content: tagging.content,
          tag: tagging.tag!.id,
        };
      })
    };
  }

  public change(mode: Mode) {
    this.clear();
    this.mode = mode;
  }

  public updateContent(e: {tagging: IOCRTagging, content: string}) {
    this.protectedTaggingsId.add(e.tagging.id);
    this.taggings.forEach((tagging, index) => {
      if(tagging.id === e.tagging.id) {
        Vue.set(this.taggings, index, {
          ...tagging,
          content: e.content,
        });
      }
    });
    this.$emit('setAnnotation', this.annotation);
  }

  public highlight(tagging: IOCRTagging) {
    this.highlightedTaggingId = tagging.id;
  }

  public unhighlight() {
    this.highlightedTaggingId = null;
  }

  public async addTagging(data: {
    mousePosition: { left: number; top: number };
    imagePosition: { left: number; top: number };
    taggingInfo: IOCRTagging;
  }) {
    this.pendingTagging = {
      ...data.taggingInfo,
      content: '',
    };

    if (this.tags.length === 1) {
      this.confirmTagging(this.tags[0]);
    } else {
      this.mousePosition = data.mousePosition;
      this.imagePosition = data.imagePosition;

      this.displayTagSelector = true;
    }
  }

  public confirmTagging(tag: ITag) {
    if (this.pendingTagging) {
      const pendingTagging = {
        ...this.pendingTagging,
      };
      this.taggings.push({
        ...pendingTagging,
        tag,
      });

      this.taggings$.next({
        ...pendingTagging,
        tag,
      });
    }

    this.clear();
    this.$emit('setAnnotation', this.annotation);
  }

  public clear() {
    this.pendingTagging = null;
    this.displayTagSelector = false;
  }

  public deleteTagging(toDelete: IOCRTagging) {
    this.taggings.forEach((tagging, index) => {
      if(tagging.id === toDelete.id) {
        Vue.delete(this.taggings, index);
      }
    });
    this.$emit('setAnnotation', this.annotation);
  }

  public computePreAnnotation() {
    this.taggings = [];
    if (this.preAnnotation) {
      this.taggings = this.preAnnotation.tags.map((tagging) => {
        return {
          id: tagging.id,
          vertices: tagging.vertices,
          content: tagging.content,
          tag: this.tagsById[tagging.tag],
        }
      });
    }
  }

  /**
   * Life cycle
   */

  private handleKeyup(event: any) {
    if (event.key && event.key === 'Escape') {
      this.clear();
    }
    if (!this.pendingTagging && event.key) {
      switch (event.key) {
        case 'v':
          this.change(Mode.inspect);
          break;
        case 'm':
          this.change(Mode.rectangle);
          break;
        case 'l':
          this.change(Mode.polygon);
          break;
        case 'e':
          this.change(Mode.erase);
          break;
      }
    }
    if (this.pendingTagging && event.key && this.tagsByKey[event.key]) {
      this.confirmTagging(this.tagsByKey[event.key]);
    }
  }

  private async mounted() {
    if (!this.passive) {
      window.addEventListener('keyup', this.handleKeyup);
      this.$emit('setAnnotation', this.annotation);
    }
    from(getImageSize(this.imageSrc)).subscribe((imSize: any) => {
      this.imageSize = imSize;
    })
  }

  async addWorker() {
    const worker = await createWorker();
    await worker.loadLanguage('fra');
    await worker.initialize('fra');
    this.pendingTesseractWorkers.push(worker);
  }

  private async created() {
    this.computePreAnnotation();

    await this.addWorker();
    await this.addWorker();

    this.taggings$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(async (tagging) => {
        if (this.pendingTesseractWorkers.length === 0) {
          await this.addWorker();
        } else {
          this.addWorker();
        }
        const worker = this.pendingTesseractWorkers.shift();

        const xs: number[] = tagging!.vertices.map((vertex) => vertex.x);
        const ys: number[] = tagging!.vertices.map((vertex) => vertex.y);
        const left = Math.floor(this.imageSize.x * Math.min(...xs));
        const top = Math.floor(this.imageSize.y * Math.min(...ys));
        const rectangle = {
          left,
          top,
          width: Math.max(0, Math.floor(this.imageSize.x * Math.max(...xs) - left)),
          height: Math.max(0, Math.floor(this.imageSize.y * Math.max(...ys) - top)),
        };

        from(worker.recognize(this.imageBuffer, { rectangle }))
          .subscribe((res: any) => {
            // TODO: For some mysterious reason, worker can't be reused (see: https://github.com/naptha/tesseract.js/issues/704)
            worker.terminate();
            if (!this.protectedTaggingsId.has(tagging!.id)) {
              this.taggings.forEach((t, index) => {
                if(t.id === tagging!.id) {
                  Vue.set(this.taggings, index, {
                    ...t,
                    content: res.data.text,
                  });
                }
              });
            }
          });
      });
  }

  private beforeDestroy() {
    this.destroy$.next();
    window.removeEventListener('keyup', this.handleKeyup);
  }
}
