import { Component, Vue } from 'vue-property-decorator';
import IProject from '@/models/interfaces/project';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import ProjectExportsList from '@/components/ProjectExportsList/ProjectExportsList.vue';
import { CreateExportDto } from '@/models/dtos/create-export.dto';

@Component({
  computed: mapState('admin-project', ['project']),
  providers: [ApiService],
  components: { ProjectExportsList },
})
export default class ProjectExport extends Vue {
  public project!: IProject;
  public waitingDownload = false;
  public formName = '';
  public formIncludeRawEntries = false;
  public formEntriesToInclude = 'closed';
  @inject() private readonly apiService!: ApiService;

  public created() {
    this.fetchExports();
  }

  public onNewExport(): void {
    const createExportDto: CreateExportDto = {
      name: this.formName,
      includeRawEntries: this.formIncludeRawEntries,
      entriesToInclude: this.formEntriesToInclude,
    };
    this.apiService
      .newProjectExport(this.project.id, createExportDto)
      .subscribe((exprt) => {
        this.$store.commit('admin-project/addExport', exprt);
      });
  }

  public fetchExports() {
    this.apiService.getProjectExports(this.project.id).subscribe((exports) => {
      this.$store.commit('admin-project/updateExports', exports);
      this.formName = `export-${this.project.name}-${exports.length + 1}`;
    });
  }
}
