import { Component, Prop, Vue } from 'vue-property-decorator';
import IUser from '@/models/interfaces/user';
import Roles, { roleToText } from '@/models/enums/user-roles';
import { UserRoleDto } from '@/models/dtos/user-role.dto';

@Component
export default class UserRoleList extends Vue {
  @Prop() public userList!: IUser[];
  @Prop() public readonly roleOptions!: { value: Roles; text: string }[];
  @Prop() public header!: string;
  public editing: number = -1;
  public userRole: UserRoleDto = {
    user: '',
    role: Roles.NONE,
  };
  public toBeDeletedIndex: number = -1;

  public itemTextFromUser(user: IUser): string {
    return `${user.firstName} ${user.lastName} (${user.email})`;
  }

  public roleToText(role: Roles): string {
    return roleToText[role] as string;
  }

  public setEdit(index: number): void {
    if (index < 0) {
      this.userRole = {
        user: '',
        role: Roles.NONE,
      };
    }
    this.editing = index;
  }

  public isOwner(user: IUser): boolean {
    return (
      user.role === Roles.PROJECT_OWNER || user.role === Roles.PLATFORM_OWNER
    );
  }

  public onEditInput(role: Roles, index: number) {
    const user = this.userList[index];
    this.userRole = { role, user: user.id };
  }

  public submitUserRole() {
    this.$emit('submit', this.userRole);
    this.setEdit(-1);
  }
}
