const downloadFile = (data: any, filename: string, type: string) => {
  const blob = new Blob([data], { type });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    link.remove();
  }
};

export default downloadFile;
