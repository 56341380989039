import { i18n } from '@/plugins/i18n';

enum Roles {
  NONE = 'NONE',
  PLATFORM_OWNER = 'PLATFORM_OWNER',
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  PLATFORM_PROJECT_CREATOR = 'PLATFORM_PROJECT_CREATOR',
  PROJECT_OWNER = 'PROJECT_OWNER',
  PROJECT_ADMIN = 'PROJECT_ADMIN',
  PROJECT_SENIOR_ANNOTATOR = 'PROJECT_SENIOR_ANNOTATOR',
  PROJECT_JUNIOR_ANNOTATOR = 'PROJECT_JUNIOR_ANNOTATOR',
}

const roleToText = {
  [Roles.NONE]: i18n.t('roles.none'),
  [Roles.PLATFORM_OWNER]: i18n.t('roles.platform-owner'),
  [Roles.PLATFORM_ADMIN]: i18n.t('roles.platform-admin'),
  [Roles.PLATFORM_PROJECT_CREATOR]: i18n.t('roles.platform-project-creator'),
  [Roles.PROJECT_OWNER]: i18n.t('roles.project-owner'),
  [Roles.PROJECT_ADMIN]: i18n.t('roles.project-admin'),
  [Roles.PROJECT_SENIOR_ANNOTATOR]: i18n.t('roles.project-senior-annotator'),
  [Roles.PROJECT_JUNIOR_ANNOTATOR]: i18n.t('roles.project-junior-annotator'),
};

export default Roles;
export { roleToText };
