import { Component, Prop, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import IProject, { IUpdateProject } from '@/models/interfaces/project';
import { mapState } from 'vuex';

@Component({
  computed: mapState(['types', 'defaultColors']),
  components: {
    Swatches,
  },
})
export default class EditProjectForm extends Vue {
  @Prop() public project!: IProject;
  public editedProject!: IUpdateProject;
  public types!: { [key: string]: string }[];
  public defaultColors!: string[];

  public get formattedType(): any {
    return this.types
      .filter((type) => type.value === this.project.type)
      .map((type) => type.text)[0];
  }

  public onSubmit(event: any) {
    event.preventDefault();
    this.$emit('submit', this.editedProject);
  }

  public created() {
    this.editedProject = {
      name: this.project.name,
      description: this.project.description,
    };
  }
}
