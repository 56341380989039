import { Component, Vue } from 'vue-property-decorator';
import BaseNavSide from '@/layouts/BaseNavSide/BaseNavSide.vue';
import MenuBarProject from '@/components/MenuBarProject/MenuBarProject.vue';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import { forkJoin } from 'rxjs';

@Component({
  components: {
    BaseNavSide,
    MenuBarProject,
  },
  providers: [ApiService],
})
export default class Project extends Vue {
  public projectLoaded: boolean = false;
  @inject() private readonly apiService!: ApiService;

  public created() {
    if (!this.$can('projectHandleConflict', this.$route.params.id)) {
      this.$router.push({ name: 'homepage' });
      return;
    }
    this.$store.commit('admin-project/init');
    forkJoin([
      this.apiService.getProjectById(this.$route.params.id),
      this.apiService.getPoolsByProject(this.$route.params.id, true),
    ]).subscribe(([project, pools]) => {
      this.$store.commit('admin-project/setProject', project);
      this.$store.commit('admin-project/updatePools', pools);
      this.projectLoaded = true;
    });
  }
}
