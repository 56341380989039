import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { event, scaleLinear, ScaleLinear, select, Selection } from 'd3';
import { v4 as uuid } from 'uuid';
import IImageTagging from '@/illuin-annotation/models/interfaces/image-tagging';
import {
  computeArea,
  getImageSize,
  getRectVerticesFromStartAndEnd,
  labelForPolygon,
  polygonForLabel,
} from './utils/utils';
import { computeImageSource } from '@/illuin-annotation/services/utils/image-buffer';
import IDocument from '@/models/interfaces/document';
import { Mode } from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotator/ObjectDetectionAnnotator';

/**
 * Disable tslint rules for d3
 */
// tslint:disable:no-this-assignment
// tslint:disable:only-arrow-functions
// tslint:disable:ter-prefer-arrow-callback

@Component({})
export default class ObjectDetectionAnnotatorRenderer extends Vue {
  @Prop() public document!: IDocument;
  @Prop() public imageBuffer!: ArrayBuffer;
  @Prop() public taggings!: { [key: string]: IImageTagging };
  @Prop() public passive!: boolean;
  @Prop() public mode!: Mode;
  @Prop() public pendingTagging!: boolean;
  @Prop() public highlightedTaggingId!: string;
  public svg!: Selection<SVGElement, {}, any, any>;
  public image!: Selection<SVGImageElement, {}, HTMLElement, {}>;
  public imageDisplaySize: { x: number; y: number } = { x: 0, y: 0 };
  public xScale: ScaleLinear<number, number> = scaleLinear();
  public yScale: ScaleLinear<number, number> = scaleLinear();
  public fitSize: { x: number; y: number } = { x: 0, y: 0 };
  public currentPolygon: Selection<
    SVGPolygonElement,
    any,
    HTMLElement,
    any
  > | null = null;
  public currentVertices: { x: number; y: number }[] = [];

  public Mode = Mode;

  public isRendering: boolean = false;

  @Watch('document', { immediate: true })
  public async onDocumentChange() {
    await this.drawImageAndTaggings();
  }

  @Watch('taggings')
  public async onTaggingsChange() {
    if (!this.isRendering) {
      this.drawPolygons();
    }
  }

  @Watch('highlightedTaggingId')
  public onHighlightedTaggingIdChange(oldId: string, newId: string) {
    select('g.polygons')
        .selectAll<SVGPolygonElement, IImageTagging>('*')
        .filter((d) => {
          return d.id === newId;
        })
        .style('stroke', (data) => data.tag!.color);
    select('g.polygons')
        .selectAll<SVGPolygonElement, IImageTagging>('*')
        .filter((d) => {
          return d.id === oldId;
        })
        .style('stroke', '#F00');
  }

  @Watch('mode')
  public onModeChange() {
    this.cleanSelection();
  }

  public isAnnotationMode(mode: Mode) {
    return mode === Mode.rectangle || mode === Mode.polygon;
  }

  public changeMode(mode: Mode) {
    this.cleanSelection();
    switch (mode) {
      case Mode.inspect:
        this.hideCrosshair();
        break;
      case Mode.rectangle:
      case Mode.polygon:
        this.showCrosshair();
        break;
      case Mode.erase:
        if (Object.keys(this.taggings).length === 0) {
          return;
        }
        break;
      default:
        break;
    }
    this.$emit('change', mode);
  }

  public addVertex() {
    const self = this;

    this.currentVertices.push({
      x: event.offsetX / this.imageDisplaySize.x,
      y: event.offsetY / this.imageDisplaySize.y,
    });
    if (this.mode === Mode.rectangle && this.currentVertices.length === 2) {
      this.endSelection();
    }
    if (this.currentVertices.length === 1) {
      this.currentPolygon = this.svg
        .select('g.currentPolygon')
        .append('polygon')
        .attr('stroke', 'black')
        .attr('stroke-dasharray', '2 5')
        .attr(
          'points',
          this.currentVertices
            .map((v) => `${self.xScale(v.x)} ${self.yScale(v.y)}`)
            .join(' '),
        );
    }
  }

  public removeVertex() {
    if (this.currentVertices.length > 0) {
      this.currentVertices.pop();
    }
    if (this.currentVertices.length === 0) {
      this.cleanSelection();
    }
  }

  /**
   * Event definitions
   */
  public addEventListeners() {
    this.image
      .on('click', () => {
        if (this.isAnnotationMode(this.mode)) {
          this.addVertex();
        }
      })
      .on('dblclick', () => {
        if (this.isAnnotationMode(this.mode)) {
          if (this.currentVertices.length > 3) {
            this.removeVertex(); // Click triggered twice so the last vertex is duplicated
            this.endSelection();
          }
        }
      })
      .on('contextmenu', (e) => {
        event.preventDefault();
        if (this.isAnnotationMode(this.mode)) {
          this.removeVertex();
        }
      });

    this.svg.on('mousemove', this.mousemove);

    this.svg.on('mouseleave', () => {
      if (this.isAnnotationMode(this.mode)) {
        this.hideCrosshair();
      }
    });
    this.svg.on('mouseenter', () => {
      if (this.isAnnotationMode(this.mode)) {
        this.showCrosshair();
      }
    });
  }

  public mousemove() {
    const self = this;
    // Draw selection
    if (
      this.currentPolygon &&
      this.currentVertices.length > 0 &&
      !this.pendingTagging
    ) {
      const currentVertex = {
        x: event.offsetX / this.imageDisplaySize.x,
        y: event.offsetY / this.imageDisplaySize.y,
      };
      let vertices = [];
      if (this.mode === Mode.rectangle) {
        vertices = getRectVerticesFromStartAndEnd(
          this.currentVertices[0],
          currentVertex,
        );
      } else {
        vertices = [...this.currentVertices, currentVertex];
      }

      this.currentPolygon.attr(
        'points',
        vertices
          .map((v) => `${self.xScale(v.x)} ${self.yScale(v.y)}`)
          .join(' '),
      );
    }

    if (!this.passive) {
      this.svg
        .select('#vertical-crosshair')
        .attr('y1', event.offsetY)
        .attr('y2', event.offsetY);
      this.svg
        .select('#horizontal-crosshair')
        .attr('x1', event.offsetX)
        .attr('x2', event.offsetX);
    }
  }

  public endSelection() {
    if (this.currentPolygon) {
      const svgBoundingRect = this.svg
        .node()!
        .getBoundingClientRect() as DOMRect;
      let vertices = [];
      if (this.mode === Mode.rectangle) {
        vertices = getRectVerticesFromStartAndEnd(
          this.currentVertices[0],
          this.currentVertices[1],
        );
      } else {
        vertices = this.currentVertices;
      }
      this.$emit('addTagging', {
        mousePosition: {
          left: event.clientX - svgBoundingRect.x,
          top: event.clientY - svgBoundingRect.y,
        },
        imagePosition: {
          left: (this.fitSize.x - this.imageDisplaySize.x) / 2,
          top: 0,
        },
        taggingInfo: {
          vertices,
          id: uuid(),
          tag: null,
        },
      });
      this.cleanSelection();
    }
  }

  /**
   * Rendering definitions
   */

  public async drawImageAndTaggings() {
    if (this.isRendering) {
      return;
    }
    this.isRendering = true;
    await this.drawImage();
    this.drawPolygons();
    this.isRendering = false;
  }

  public async drawImage() {
    const src = computeImageSource(this.imageBuffer);
    const imageSize = await getImageSize(src);
    this.image = this.svg.select<SVGImageElement>('#image');
    this.image.attr('xlink:href', src);

    const widthRatio = imageSize.x / this.fitSize.x;
    const heightRatio = imageSize.y / this.fitSize.y;
    const ratio = Math.max(widthRatio, heightRatio);
    this.imageDisplaySize = {
      x: imageSize.x / ratio,
      y: imageSize.y / ratio,
    };

    this.image.attr('width', this.imageDisplaySize.x);
    this.image.attr('height', this.imageDisplaySize.y);

    if (!this.passive) {
      this.drawCrossHair(this.imageDisplaySize);
    }
    this.xScale = scaleLinear()
      .domain([0, 1])
      .range([0, this.imageDisplaySize.x])
      .clamp(false);
    this.yScale = scaleLinear()
      .domain([0, 1])
      .range([0, this.imageDisplaySize.y])
      .clamp(false);
  }

  public drawCrossHair(imageSize: { x: number; y: number }) {
    this.svg
      .select('#vertical-crosshair')
      .attr('x1', 0)
      .attr('x2', imageSize.x)
      .attr('stroke-dasharray', 4)
      .style('stroke', '#000');

    this.svg
      .select('#horizontal-crosshair')
      .attr('y1', 0)
      .attr('y2', imageSize.y)
      .attr('stroke-dasharray', 4)
      .style('stroke', '#000');
  }

  public drawPolygons() {
    const self = this;

    const sortedTaggings = Object.values(this.taggings).sort((a, b) =>
      computeArea(a) > computeArea(b) ? -1 : 1,
    );

    sortedTaggings.forEach((tagging) => {
      const verticesX = tagging.vertices.map((v) => v.x);
      const verticesY = tagging.vertices.map((v) => v.y);
      const minX = Math.min(...verticesX);
      const maxX = Math.max(...verticesX);
      const minY = Math.min(...verticesY);
      const maxY = Math.max(...verticesY);
      tagging.middle = { x: (minX + maxX) / 2, y: (minY + maxY) / 2 };
    });

    const polygons = this.svg
      .select('g.polygons')
      .selectAll<SVGPolygonElement, IImageTagging>('*')
      .data(sortedTaggings, (e) => e.id);

    const labels = this.svg
      .select('g.labels')
      .selectAll<SVGTextElement, IImageTagging>('*')
      .data(sortedTaggings, (e) => e.id);

    polygons
      .enter()
      .append('polygon')
      .attr('points', (d) =>
        d.vertices
          .map((v) => `${self.xScale(v.x)} ${self.yScale(v.y)}`)
          .join(' '),
      )
      .style('fill', (d) => d.tag!.color)
      .style('stroke', (d) => d.tag!.color)
      .on('mouseenter', function (e) {
        if (self.mode === Mode.erase) {
          self.highlightPolygon(this);
          self.drawEraserPointer();
        }
      })
      .on('mouseleave', function (e) {
        if (self.mode === Mode.erase) {
          self.unhighlightPolygon(this);
          self.removeEraserPointer();
        }
      })
      .on('click', function (e) {
        if (self.mode === Mode.erase) {
          self.$emit('delete', e);
          self.removeEraserPointer();
          if (Object.keys(self.taggings).length === 0) {
            self.changeMode(Mode.rectangle);
          }
        }
      })
      .on('mousemove', function (e) {
        if (self.mode === Mode.erase) {
          self.drawEraserPointer();
        }
      });

    labels
      .enter()
      .append('text')
      .attr('x', (d) => self.xScale(d.middle!.x) as number)
      .attr('y', (d) => self.yScale(d.middle!.y) as number)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '0.9rem')
      .style('fill', (d) => d.tag!.color)
      .style('opacity', 0.75)
      .style('cursor', null)
      .text((d) => d.tag!.title.slice(0,3))
      .on('mouseenter', function (e) {
        if (self.mode === Mode.erase) {
          self.highlightLabel(this);
          self.drawEraserPointer();
        }
      })
      .on('mouseleave', function (e) {
        if (self.mode === Mode.erase) {
          self.unhighlightLabel(this);
          self.removeEraserPointer();
        }
      })
      .on('click', function (e) {
        if (self.mode === Mode.erase) {
          self.$emit('delete', e);
          self.removeEraserPointer();
          if (Object.keys(self.taggings).length === 0) {
            self.changeMode(Mode.rectangle);
          }
        }
      })
      .on('mousemove', function (e) {
        if (self.mode === Mode.erase) {
          self.drawEraserPointer();
        }
      });

    polygons.exit().remove();
    labels.exit().remove();
  }

  public cleanSelection() {
    this.currentVertices = [];
    if (this.currentPolygon) {
      this.currentPolygon.remove();
    }
  }

  public highlightPolygon(polygon: SVGPolygonElement): void {
    select(polygon).style('stroke', '#FFF');

    labelForPolygon(polygon).style('fill', '#FFF');
  }

  public unhighlightPolygon(polygon: SVGPolygonElement): void {
    select<SVGPolygonElement, IImageTagging>(polygon)
      .style('stroke', (data) => data.tag!.color)
      .style('cursor', null);

    labelForPolygon(polygon).style('fill', (data) => data.tag!.color);
  }

  public highlightLabel(label: SVGTextElement): void {
    select(label).style('fill', '#FFF');

    polygonForLabel(label).style('stroke', '#FFF');
  }

  public unhighlightLabel(label: SVGTextElement): void {
    select<SVGTextElement, IImageTagging>(label)
        .style('fill', (data) => data.tag!.color);

    polygonForLabel(label)
        .style('stroke', (data) => data.tag!.color)
        .style('cursor', null);
  }

  public showCrosshair() {
    this.svg.style('cursor', 'crosshair');
    this.svg.select('#vertical-crosshair').style('display', '');
    this.svg.select('#horizontal-crosshair').style('display', '');
  }

  public hideCrosshair() {
    this.svg.style('cursor', 'auto');
    this.svg.select('#vertical-crosshair').style('display', 'none');
    this.svg.select('#horizontal-crosshair').style('display', 'none');
  }

  public drawEraserPointer() {
    this.svg.style('cursor', 'none');
    this.svg
      .select('g.eraser')
      .select('use')
      .attr('href', '#eraser')
      .style('display', 'block')
      .style('transform', 'translate(0, -23px)')
      .attr('x', event.offsetX)
      .attr('y', event.offsetY);
  }

  public removeEraserPointer() {
    this.svg.style('cursor', null);
    this.svg.select('g.eraser').select('use').style('display', 'none');
  }

  /**
   * Life cycle definitions
   */

  public addSvgDefs() {
    const defs = this.svg.append('svg:defs');
    defs
      .append('path')
      .attr('id', 'eraser')
      .attr(
        'd',
        'M144.084 537.94h169.219l47.41-47.28c7.311-7.3 7.323-19.264.035-26.563L220.006 322.965c-7.287-7.312-19.252-7.323-26.563-.036L74.481 441.56c-7.323 7.3-7.323 19.252-.035 26.563l69.638 69.816zm225.877 0H640v51.284H132.037L5.457 462.314c-7.3-7.312-7.288-19.277.035-26.564L385.99 56.257c7.335-7.311 19.276-7.288 26.575.035l206.341 206.884c7.288 7.3 7.276 19.264-.047 26.564l-248.897 248.2z',
      )
      .attr('transform', 'scale(0.05)')
      .style('fill', '#FFF');
  }

  public created() {
    if (this.passive) {
      this.$emit('change', Mode.inspect);
    }
  }

  private async mounted() {
    this.svg = select<SVGElement, {}>(this.$refs.svg as SVGGElement);

    this.fitSize = {
      x: this.$el.clientWidth,
      y: (this.$el.parentElement as HTMLElement).clientHeight,
    };

    await this.drawImage();

    this.addSvgDefs();

    this.addEventListeners();
    window.addEventListener('keyup', this.handleKeyup);

    this.drawPolygons();
  }

  private handleKeyup(e: any) {
    if (e.key && e.key === 'Escape') {
      this.cleanSelection();
      return;
    }
  }

  private beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup);
  }
}
