import { Component, Vue } from 'vue-property-decorator';
import IProject from '@/models/interfaces/project';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import AnnotationsImportForm from '@/components/AnnotationsImportForm/AnnotationsImportForm.vue';
import { inject } from 'vue-typescript-inject';
import IImport from '@/models/interfaces/import';

@Component({
  computed: mapState('admin-project', ['project']),
  components: { AnnotationsImportForm },
  providers: [ApiService],
})
export default class ProjectImportAnnotations extends Vue {
  public project!: IProject;
  public loading: boolean = false;
  @inject() private readonly apiService!: ApiService;

  public waitImport(createdProject: IProject, importData: IImport) {
    this.apiService
      .getImport(importData)
      .subscribe((newImportData: IImport) => {
        if (newImportData.finished && !newImportData.errored) {
          this.$notify({
            group: 'app',
            type: 'success',
            text: this.$t('alert.annotations.addition.success') as string,
          });
          this.$router.push({
            name: 'project-admin-settings-entries',
            params: { id: this.project.id },
          });
        } else if (newImportData.finished && newImportData.errored) {
          this.$notify({
            group: 'app',
            type: 'error',
            text: this.$t('alert.annotations.addition.error', {
              errorMessage: newImportData.errorMessage,
            }) as string,
          });
          this.$router.push({
            name: 'project-admin-settings-entries',
            params: { id: this.project.id },
          });
        } else {
          setTimeout(
            () => this.waitImport(createdProject, newImportData),
            1000,
          );
        }
      });
  }

  public onSubmit(event: any) {
    this.apiService
      .importAnnotations(this.project, event)
      .subscribe((importData: IImport) => {
        this.waitImport(this.project, importData);
      });
  }
}
