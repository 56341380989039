import { Component, Vue } from 'vue-property-decorator';
import en from './lang/qa-datamodel-form.en.json';
import fr from './lang/qa-datamodel-form.fr.json';

@Component({
  i18n: {
    messages: { en, fr },
  },
})
export default class QADataModelForm extends Vue {
  public datamodel: {} = {};

  public onSubmit(event: any) {
    event.preventDefault();
    this.$emit('submit', this.datamodel);
  }
}
