import { Component, Prop, Vue } from 'vue-property-decorator';
import IUser from '@/models/interfaces/user';
import { ICreatePool } from '@/models/interfaces/create-pool';
import { mapState } from 'vuex';
import IProject from '@/models/interfaces/project';
import Datepicker from 'vuejs-datepicker';
import { en, fr } from 'vuejs-datepicker/dist/locale';

@Component({
  computed: mapState('admin-project', ['project']),
  components: { Datepicker },
})
export default class CreateTasksForm extends Vue {
  @Prop() public freeDocumentsCount!: number;
  @Prop() public annotators!: IUser[];
  public project!: IProject;

  public datePickerLocale = {
    en,
    fr,
  };

  public selectableAnnotators: IUser[] = [];
  public errorAlerts: string[] = [];

  public disabledDates = {
    to: new Date(Date.now() - 1000 * 60 * 60 * 24),
  };

  public pool: ICreatePool = {
    name: '',
    documentsCount: 1,
    users: [] as IUser[],
    overlap: 0,
    project: this.project,
    dueDate: new Date(),
  };

  public onSubmit(event: any): void {
    event.preventDefault();
    this.verifyForm();
    if (!this.errorAlerts.length) {
      this.$emit('submit', this.pool);
    }
  }

  public annotatorToText(annotator: IUser): string {
    return `${annotator.lastName} ${annotator.firstName}`;
  }

  public selectAnnotator(index: number): void {
    this.pool.users.push(this.selectableAnnotators.splice(index, 1)[0]);
  }

  public deleteAnnotator(index: number): void {
    this.selectableAnnotators.push(this.pool.users.splice(index, 1)[0]);
  }

  public updateDocumentCount(event: any) {
    const value = event.target.value;
    this.pool.documentsCount =
      value > this.freeDocumentsCount ? this.freeDocumentsCount : value;
    event.target.value = this.pool.documentsCount;
  }
  public created() {
    this.selectableAnnotators = this.annotators;
    this.pool.project = this.project;
  }

  private verifyForm() {
    this.errorAlerts = [];
    if (!this.pool.name) {
      this.errorAlerts.push(
        this.$t('create-tasks-form.errors.no-name') as string,
      );
    }

    if (this.pool.documentsCount <= 0) {
      this.errorAlerts.push(
        this.$t('create-tasks-form.errors.no-documents') as string,
      );
    }
    if (this.pool.documentsCount > this.freeDocumentsCount) {
      this.errorAlerts.push(
        this.$t('create-tasks-form.errors.max-documents') as string,
      );
    }
    if (this.pool.overlap > 100 || this.pool.overlap < 0) {
      this.errorAlerts.push(
        this.$t('create-tasks-form.errors.invalid-percent') as string,
      );
    }
    if (this.pool.users.length === 0) {
      this.errorAlerts.push(
        this.$t('create-tasks-form.errors.no-annotators') as string,
      );
    }
    if (this.pool.users.length === 1 && this.pool.overlap > 0) {
      this.errorAlerts.push(
        this.$t('create-tasks-form.errors.invalid-overlap') as string,
      );
    }
  }
}
