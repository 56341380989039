function stringToHslColor(name: string, saturation: number, lightness: number) {
  let hash = 0;
  for (let i = name.length - 1; i >= 0; i -= 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${saturation}%, ${lightness}%)`;
}

export default stringToHslColor;
