import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import IUser from '@/models/interfaces/user';

@Component({
  computed: mapState(['user']),
})
export default class UserMixin extends Vue {
  public user!: IUser;
}
