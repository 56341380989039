import { render, staticRenderFns } from "./SimpleLogoutButton.vue?vue&type=template&id=337c7168&"
import script from "./SimpleLogoutButton.ts?vue&type=script&lang=ts&"
export * from "./SimpleLogoutButton.ts?vue&type=script&lang=ts&"
import style0 from "./SimpleLogoutButton.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports