import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Logged extends Vue {
  public loading: boolean = false;

  public created() {
    this.loading = true;
    this.$store.dispatch('onApplicationLoading').then(() => {
      this.loading = false;
    });
  }
}
