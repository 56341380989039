import INERAnnotationValue from '@/illuin-annotation/models/interfaces/ner-annotation';

const nerGCF = (
  annotations: INERAnnotationValue[],
): INERAnnotationValue | null => {
  if (annotations.length === 0) {
    return null;
  }
  if (annotations.length === 1) {
    return annotations[0];
  }

  const nerTaggingsCommonIds = annotations
    .map((annotation: INERAnnotationValue) =>
      annotation.tags.map((nerTagging) => nerTagging.id),
    )
    .reduce((nerTaggingIds1: string[], nerTaggingIds2: string[]) =>
      nerTaggingIds1.filter((nerTaggingId) =>
        nerTaggingIds2.includes(nerTaggingId),
      ),
    );

  return {
    tags: annotations[0].tags.filter((nerTagging) =>
      nerTaggingsCommonIds.includes(nerTagging.id),
    ),
  };
};

export default nerGCF;
