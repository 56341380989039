const toArrayBuffer = (
  buf: any /** node's Buffer type is inexistant here */,
) => {
  const ab = new ArrayBuffer(buf.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buf.length; i += 1) {
    view[i] = buf[i];
  }
  return ab;
};

const computeImageSource = (imageBuffer: ArrayBuffer) => {
  const blob = new Blob([imageBuffer], { type: 'image/jpeg' });
  const urlCreator = window.URL;
  return urlCreator.createObjectURL(blob);
};

export { toArrayBuffer, computeImageSource };
