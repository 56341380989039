import { Component, Vue } from 'vue-property-decorator';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';
import BaseNavSide from '@/layouts/BaseNavSide/BaseNavSide.vue';
import MenuBarPool from '@/components/MenuBarPool/MenuBarPool.vue';
import { forkJoin } from 'rxjs';

@Component({
  providers: [ApiService],
  components: { BaseNavSide, MenuBarPool },
})
export default class PoolAdmin extends Vue {
  public loading: boolean = false;
  @inject() private readonly apiService!: ApiService;

  public get fromHomepage() {
    return this.$route.query.from === 'homepage';
  }

  public created() {
    this.$store.commit('pool-admin/init');
    const poolId = this.$route.params.poolId;
    this.loading = true;
    this.apiService
      .getPoolDashboard(poolId)
      .subscribe((pool: PoolDashboardDto) => {
        this.$store.commit('pool-admin/setPool', pool);
        this.apiService.getTasksByPool(pool.id, true).subscribe((tasks) => {
          forkJoin(
            tasks.map((task) =>
              this.apiService.getDocumentsMetadataForTask(task.id),
            ),
          ).subscribe((documents) => {
            for (let i = 0; i < tasks.length; i += 1) {
              tasks[i].documents = documents[i];
            }
            this.$store.commit('pool-admin/setTasks', tasks);
            this.loading = false;
          });
        });
      });
  }
}
