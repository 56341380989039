function segmentsCollision(
  begin1: number,
  end1: number,
  begin2: number,
  end2: number,
) {
  return (
    Math.min(begin1, end1) <= Math.max(begin2, end2) &&
    Math.min(begin2, end2) <= Math.max(begin1, end1)
  );
}

function strictSegmentsCollision(
  begin1: number,
  end1: number,
  begin2: number,
  end2: number,
) {
  return (
    Math.min(begin1, end1) < Math.max(begin2, end2) &&
    Math.min(begin2, end2) < Math.max(begin1, end1)
  );
}

export { segmentsCollision, strictSegmentsCollision };
