import { Component, Vue } from 'vue-property-decorator';

import { mapState } from 'vuex';
import { LoginMethod } from '@/store/store';
import SimpleLogin from '@/views/Login/SimpleLogin/SimpleLogin.vue';
import GoogleLogin from '@/views/Login/GoogleLogin/GoogleLogin.vue';
import MicrosoftAzureLogin from '@/views/Login/MicrosoftAzureLogin/MicrosoftAzureLogin.vue';

@Component({
  computed: {
    ...mapState(['loginMethod']),
  },
  components: { SimpleLogin, GoogleLogin, MicrosoftAzureLogin },
})
export default class Login extends Vue {
  public loginMethod!: LoginMethod;
  public get loginComponent() {
    switch (this.loginMethod) {
      case LoginMethod.GOOGLE:
        return GoogleLogin;
      case LoginMethod.AZURE:
        return MicrosoftAzureLogin;
      case LoginMethod.SIMPLE:
      default:
        return SimpleLogin;
    }
  }
}
