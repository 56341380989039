import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';
import DocumentsTaskList from '@/components/DocumentsTaskList/DocumentsTaskList.vue';

@Component({
  computed: {
    ...mapState('pool-admin', ['tasks']),
    ...mapState(['userEmail']),
  },
  components: { DocumentsTaskList },
})
export default class PoolAdminMyDocuments extends Vue {
  public tasks!: TaskMetadataDto[];
  public userEmail!: string;
  public myTask: TaskMetadataDto | null = null;

  public created() {
    for (const task of this.tasks) {
      if (task.user.email === this.userEmail) {
        this.myTask = task;
      }
    }
  }
}
