var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ner-score-array"},[_c('b-card',[_c('div',{staticClass:"ner-score-array__header",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v(_vm._s(_vm.$t('ner-score-array.entity')))]),_c('span',[_vm._v(_vm._s(_vm.$t('ner-score-array.score')))])]),_c('ul',[_c('li',{staticClass:"ner-score-array__item"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('ner-score-array.total')))])]),_c('span',{class:{
                'danger': _vm.totalScore < 0.40,
                'warning': _vm.totalScore >= 0.40 && _vm.totalScore <= 0.80,
                'good': _vm.totalScore > 0.80,
              }},[_vm._v(_vm._s(Math.round(_vm.totalScore * 100) / 100))])]),_vm._l((_vm.scoreValue.tags),function(t,index){return _c('li',{key:index,staticClass:"ner-score-array__item"},[_c('span',[_vm._v(_vm._s(_vm.tagTitleFromId(index)))]),_c('span',{class:{
                'danger': _vm.val(t) < 0.40,
                'warning': _vm.val(t) >= 0.40 && _vm.val(t) <= 0.80,
                'good': _vm.val(t) > 0.80,
              }},[_vm._v(_vm._s(isNaN(_vm.val(t)) ? '--' : Math.round(_vm.val(t) * 100) / 100))])])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }