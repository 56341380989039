import { Component, Vue } from 'vue-property-decorator';
import MenuBar from '@/layouts/MenuBar/MenuBar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';

@Component({
  components: {
    NavBar,
    MenuBar,
  },
})
export default class BaseNavSide extends Vue {}
