import IAnnotation from '@/models/interfaces/annotation';
import IProject from '@/models/interfaces/project';
import IImport from '@/models/interfaces/import';
import IPool from '@/models/interfaces/pool';

export interface IDocument {
  id: string;
  name: string;
  url: string;
  source: DocumentSource;
  value?: any;
  priority: number;
  project: IProject;
  import: IImport;
  hasConflict: boolean;
  annotations: IAnnotation[];
  annotationId: string;
  annotationErrored?: boolean;
  annotationCount: number;
  pool: IPool;
  createdAt: string;
  updatedAt: string;
}

enum DocumentSource {
  aws = 'aws',
  local = 'local',
}

export default IDocument;
