import { Component, Vue } from 'vue-property-decorator';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import CreateAccountForm from '@/components/CreateAccountForm/CreateAccountForm.vue';
import AccountsList from '@/components/AccountsList/AccountsList.vue';
import IUser from '@/models/interfaces/user';
import ICreateUser from '@/models/interfaces/create-user';
import { mapState } from 'vuex';
import { LoginMethod } from '@/store/store';

@Component({
  computed: {
    ...mapState(['loginMethod']),
  },
  providers: [ApiService],
  components: { CreateAccountForm, AccountsList },
})
export default class CreateAccount extends Vue {
  public loginMethod!: LoginMethod;
  public accounts: IUser[] = [];
  @inject() private readonly apiService!: ApiService;

  public onSubmit(createUser: ICreateUser) {
    this.apiService.createAccount(createUser).subscribe(this.loadAccounts);
  }

  private loadAccounts() {
    this.apiService.getPlatformAccounts().subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  public get canCreateAccounts() {
    switch (this.loginMethod) {
      case 'google':
        return false;
      case 'simple':
      default:
        return true;
    }
  }

  private created() {
    if (!this.$can('platformCreateUsers', null) || !this.canCreateAccounts) {
      this.$router.push({ name: 'homepage' });
    }
    this.loadAccounts();
  }
}
