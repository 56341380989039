import { Component, Prop, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import { v4 as uuid } from 'uuid';
import INerRelationsDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerrelationsdatamodel';
import _ from 'lodash';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import en from './lang/edit-ner-relations-datamodel-form.en.json';
import fr from './lang/edit-ner-relations-datamodel-form.fr.json';
import IRelation from '@/illuin-annotation/models/interfaces/relation';

@Component({
  components: {
    Swatches,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class EditNerRelationsDataModelForm extends Vue {
  @Prop() public datamodel!: INerRelationsDataModel;

  public editRelation: number = -1;

  public defaultColors: string[] = [
    '#666666',
    '#32BCB1',
    '#881D81',
    '#D32455',
    '#6570FF',
    '#E28624',
    '#9ED400',
    '#000000',
  ];
  public errors: string[] = [];
  public editedDatamodel: INerRelationsDataModel = {
    tags: [],
    relations: [],
  };

  public onSubmit(event: any) {
    event.preventDefault();
    this.errors = [];
    if (this.hasUncompleteRelation()) {
      this.errors.push(
        this.$t('ner-relations-datamodel-form.error.empty-side') as string,
      );
    } else {
      this.$emit('submit', this.editedDatamodel);
    }
  }

  /**
   * General view related functions
   */

  public created() {
    this.editedDatamodel = _.cloneDeep(this.datamodel);
    if (!this.editedDatamodel.tags) {
      this.editedDatamodel.tags = [];
    }
    if (!this.editedDatamodel.relations) {
      this.editedDatamodel.relations = [];
    }
  }

  public discardChanges() {
    this.editedDatamodel = _.cloneDeep(this.datamodel);
    // this.editMode = EditMode.NONE;
    this.$bvModal.show('confirmDiscardModal');
  }

  /**
   * tags related functions
   */

  public get tagCustomIdDuplicates() {
    return this.editedDatamodel.tags
      .map((tag: ITag) => tag.customId)
      .filter((value, index, self) => self.indexOf(value) !== index);
  }

  public addTag() {
    const id = uuid();
    this.editedDatamodel.tags.push({
      id,
      customId: id,
      title: '',
      shortcut: '',
      color: this.defaultColors[
        this.editedDatamodel.tags.length % this.defaultColors.length
      ],
    });
  }

  public changeShortcut(index: number, event: any) {
    this.editedDatamodel.tags[index].shortcut = event.key;
  }

  public confirmDeleteTag(index: number): void {
    const tag = this.editedDatamodel.tags[index];

    for (const relation of this.editedDatamodel.relations) {
      for (const relTagId of relation.rightTagsIds.concat(
        relation.leftTagsIds,
      )) {
        if (relTagId === tag.id) {
          this.$bvModal.show(`deleteTag${index}`);
          return;
        }
      }
    }
    this.deleteTag(index);
  }

  public deleteTag(index: number) {
    const deletedTag = this.editedDatamodel.tags[index];
    this.editedDatamodel.relations.forEach((relation, i) => {
      this.editedDatamodel.relations[
        i
      ].leftTagsIds = relation.leftTagsIds.filter(
        (tagId) => tagId !== deletedTag.id,
      );
      this.editedDatamodel.relations[
        i
      ].rightTagsIds = relation.rightTagsIds.filter(
        (tagId) => tagId !== deletedTag.id,
      );
    });

    this.editedDatamodel.relations = this.editedDatamodel.relations.filter(
      (relation) => {
        return (
          relation.leftTagsIds.length !== 0 &&
          relation.rightTagsIds.length !== 0
        );
      },
    );
    this.editedDatamodel.tags.splice(index, 1);
  }

  public onInputBlur(event: any, tag: ITag) {
    if (event.target.value.length) {
      tag.title = event.target.value;
    } else {
      event.target.value = tag.title;
    }
  }

  /**
   * relations related functions
   */

  public get relationCustomIdDuplicates() {
    return this.editedDatamodel.relations
      .map((relation: IRelation) => relation.customId)
      .filter((value, index, self) => self.indexOf(value) !== index);
  }

  public addRelation() {
    const id = uuid();
    this.editedDatamodel.relations.push({
      id,
      customId: id,
      name: '',
      leftTagsIds: [],
      rightTagsIds: [],
    });
    this.editRelation = this.editedDatamodel.relations.length - 1;
  }

  public deleteRelation(index: number) {
    this.editedDatamodel.relations.splice(index, 1);
    this.editRelation = -1;
  }

  public setEditOnRelation(index: number) {
    this.editRelation = index;
  }

  public closeEdit() {
    this.editRelation = -1;
  }

  public tagNamesList(tagList: string[]) {
    return tagList.map((tagId) => this.tagTitleFromId(tagId)).join(', ');
  }

  public tagTitleFromId(tagId: string) {
    for (const tag of this.editedDatamodel.tags) {
      if (tag.id === tagId) {
        return tag.title;
      }
    }
    return null;
  }

  public availableTags(tagList: string[]) {
    const availableTags = [];

    for (const tag of this.editedDatamodel.tags) {
      let available = true;
      for (const tagId of tagList) {
        if (tagId === tag.id || !tag.title.length) {
          available = false;
          break;
        }
      }
      if (available) {
        availableTags.push({ value: tag, text: tag.title });
      }
    }
    return availableTags;
  }

  public addTagToRelation(event: ITag, tagList: string[]) {
    if (event) {
      tagList.push(event.id);
    }
  }

  public deleteTagForRelation(tagIndex: number, tagIdList: string[]) {
    tagIdList.splice(tagIndex, 1);
  }

  public showDeleteRelationModal(index: number) {
    this.$bvModal.show(`deleteRelation${index}`);
  }

  public hasUncompleteRelation(): boolean {
    for (const relation of this.editedDatamodel.relations) {
      if (!relation.leftTagsIds.length || !relation.rightTagsIds.length) {
        return true;
      }
    }
    return false;
  }
}
