import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import NerDataModelForm from '@/illuin-annotation/components/NER/NerDataModelForm/NerDataModelForm.vue';
import EditNerRelationsDataModelForm from '@/illuin-annotation/components/NERRelation/EditNerRelationsDataModelForm/EditNerRelationsDataModelForm.vue';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import ProjectType from '@/models/enums/project-type';

@Component({})
export default class EditDataModelForm extends Vue {
  @Prop() public type!: string;
  @Prop() public datamodel!: IDataModel;

  public formComponentByName: { [key: string]: VueConstructor<Vue> } = {
    [ProjectType.NER]: NerDataModelForm,
    [ProjectType.NER_RELATIONS]: EditNerRelationsDataModelForm,
    [ProjectType.TEXT_CLASSIFICATION]: NerDataModelForm,
    [ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL]: NerDataModelForm,
    [ProjectType.IMAGE_CLASSIFICATION]: NerDataModelForm,
    [ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL]: NerDataModelForm,
    [ProjectType.OBJECT_DETECTION]: NerDataModelForm,
    [ProjectType.OCR]: NerDataModelForm,
  };

  get componentName(): VueConstructor<Vue> {
    return this.formComponentByName[this.type];
  }

  public onSubmit(datamodel: IDataModel) {
    this.$emit('submit', datamodel);
  }
}
