import { Component, Vue } from 'vue-property-decorator';
import PoolScoreDto from '@/illuin-annotation/models/dtos/pool-score';
import BinomialScore from '@/illuin-annotation/models/dtos/binomial-score';
import { mapState } from 'vuex';
import IUser from '@/models/interfaces/user';
import Collapse from '@/components/Collapse/Collapse.vue';
import ScoreArray from '@/illuin-annotation/factories/ScoreArray/ScoreArray.vue';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import { PoolDashboardDto } from '@/models/dtos/pool-dashboard.dto';

@Component({
  computed: mapState('pool-admin', ['pool']),
  components: { Collapse, ScoreArray },
  providers: [ApiService],
})
export default class PoolScore extends Vue {
  public pool!: PoolDashboardDto;
  public poolScore: PoolScoreDto | null = null;

  public userA: IUser | null = null;
  public userB: IUser | null = null;

  public binomialScore: BinomialScore | null = null;

  @inject() private readonly apiService!: ApiService;

  public created() {
    if (this.pool.users.length === 1) {
      this.$router.push({
        name: 'pool-admin-dashboard',
        params: this.$route.params,
      });
      return;
    }
    this.apiService.getPoolScore(this.pool.id).subscribe((poolScore) => {
      this.poolScore = poolScore;
    });
  }

  public onSelect() {
    if (this.poolScore && this.userA && this.userB) {
      for (const binomialScore of this.poolScore.binomialScores) {
        if (
          (binomialScore.userIdA === this.userA.id &&
            binomialScore.userIdB === this.userB.id) ||
          (binomialScore.userIdA === this.userB.id &&
            binomialScore.userIdB === this.userA.id)
        ) {
          this.binomialScore = binomialScore;
          return;
        }
      }
    }
  }
}
