import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import NERRelationBaseAnnotatorTextMixin from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/NERRelationBaseAnnotatorTextMixin.ts';
import { offsetPositions as d3OffsetPosition } from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/utils/drawing';
import {
  CHARHEIGHT,
  RELATION_HEIGHT,
} from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/utils/constants';
import { select, Selection } from 'd3-selection';
import IBlock from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/block';
import ILink from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/link';

@Component({})
export default class NERRelationDiffAnnotatorText extends Mixins(
  NERRelationBaseAnnotatorTextMixin,
) {
  @Prop({
    default: () => {
      return {};
    },
  })
  public minRelationHeightByLine!: number[];
  public mutualRelationHeightByLine: number[] = [];

  public updatingConflicts!: Selection<any, any, any, any>;

  @Prop({ default: () => [] }) public temporaryElementsIds!: string[];
  @Prop({ default: () => new Set() }) public conflictedLines!: number[];
  @Prop() public displayConflicts!: boolean;

  public offsetPositions(
    selection: Selection<SVGGElement, any, SVGGElement, any>,
  ) {
    d3OffsetPosition(selection, this.mutualRelationHeightByLine, this.yScale);
  }

  @Watch('minRelationHeightByLine')
  public onNewMinRelationHeightByLine() {
    const mutualRelationHeightByLine: number[] = [];
    this.relationHeightByLine.forEach((lineHeight: number, index: number) => {
      mutualRelationHeightByLine.push(
        Math.max(lineHeight, this.minRelationHeightByLine[index]),
      );
    });
    this.mutualRelationHeightByLine = mutualRelationHeightByLine;

    this.offsetPositions(this.updatingLines);
    this.offsetPositions(this.updatingEntitiesLines);
    this.offsetPositions(this.updatingArrowsLines);
    this.setSvgHeight();
  }

  @Watch('relationHeightByLine')
  public heightByLineUpdated() {
    this.$emit('newRelationHeightByLine', [...this.relationHeightByLine]);
  }

  @Watch('temporaryElementsIds')
  public onNewTemporaryElementsIds() {
    // tslint:disable-next-line:no-this-assignment
    const self = this;

    this.updatingEntitiesLines.each(function () {
      select(this)
        .selectAll<SVGGElement, IBlock>('g.block')
        .attr('class', (d) =>
          self.temporaryElementsIds.indexOf(d.tagging.id) > -1
            ? 'block mitigated'
            : 'block',
        );
    });

    this.updatingArrowsLines.each(function () {
      select(this)
        .selectAll<SVGGElement, ILink>('g.arrow')
        .attr('class', (d) =>
          self.temporaryElementsIds.indexOf(d.relation.id) > -1
            ? 'arrow mitigated'
            : 'arrow',
        );
    });
  }

  @Watch('conflictedLines')
  public drawConflicts() {
    if (this.displayConflicts) {
      // tslint:disable-next-line:no-this-assignment
      const self = this;

      const conflicts = this.svg
        .select('g.conflicts')
        .attr('transform', (d) => 'translate(20 50)')
        .selectAll<SVGGElement, { y: number }>('g')
        .data(
          this.conflictedLines.map((y) => {
            return { y };
          }),
          (d: { y: number }) => d.y.toString(),
        );

      const enteringConflicts = conflicts
        .enter()
        .append('g')
        .attr('transform', (d) => `translate(0 ${this.yScale(d.y)})`);

      enteringConflicts.append('rect');

      this.updatingConflicts = enteringConflicts.merge(conflicts);
      // tslint:disable-next-line:only-arrow-functions
      this.updatingConflicts.each(function () {
        select(this)
          .select('rect')
          .attr('fill', '#dc3545')
          .attr('x', -20)
          .attr(
            'y',
            (d: any) =>
              self.yScale(-0.5) as number -
              (self.mutualRelationHeightByLine[d.y] * RELATION_HEIGHT || 0),
          )
          .attr('width', 2)
          .attr(
            'height',
            (d: any) =>
              self.yScale(1) as number +
              (self.mutualRelationHeightByLine[d.y] * RELATION_HEIGHT || 0),
          );
      });

      conflicts.exit().remove();

      this.offsetPositions(this.updatingConflicts);
    }
  }

  @Watch('entitiesLinesData', { immediate: true })
  public onNewEntitiesLinesData() {
    this.$emit('entitiesLinesData', this.entitiesLinesData);
  }

  @Watch('arrowsLinesData', { immediate: true })
  public onNewArrowsLinesData() {
    this.$emit('arrowsLinesData', this.arrowsLinesData);
  }

  public offsetAll() {
    this.offsetPositions(this.updatingLines);
    this.offsetPositions(this.updatingEntitiesLines);
    this.offsetPositions(this.updatingArrowsLines);
    this.drawConflicts();
  }

  public setSvgHeight() {
    const additionalRelationHeight =
      this.mutualRelationHeightByLine.reduce((acc: number, height: number) => {
        return acc + height;
      }, 0) || 0;
    this.svg.attr(
      'height',
      this.tokens[this.tokens.length - 1].y * CHARHEIGHT +
        100 +
        additionalRelationHeight * RELATION_HEIGHT,
    );
  }
}
