import { Component, Vue } from 'vue-property-decorator';
import { PoolMetadataDto } from '@/models/dtos/pool-metadata.dto';
import { mapState } from 'vuex';
import { TaskMetadataDto } from '@/models/dtos/task-metadata.dto';

@Component({
  computed: mapState('pool-admin', ['pool', 'tasks']),
})
export default class PoolDashboard extends Vue {
  public pool!: PoolMetadataDto;
  public tasks!: TaskMetadataDto[];

  public get localeDueDateString() {
    return new Date(this.pool.dueDate).toLocaleDateString(this.$i18n.locale, {
      dateStyle: 'full',
    });
  }

  public get remainingDays() {
    const exactDueDate = new Date(this.pool.dueDate);
    const dueDate = Date.UTC(
      exactDueDate.getFullYear(),
      exactDueDate.getMonth(),
      exactDueDate.getDate(),
    );
    const exactToday = new Date();
    const today = Date.UTC(
      exactToday.getFullYear(),
      exactToday.getMonth(),
      exactToday.getDate(),
    );

    const remainingDays = Math.round((dueDate - today) / (1000 * 60 * 60 * 24));

    if (remainingDays < 0) {
      return this.$t('pool-dashboard.due-date.overdue');
    }
    return this.$tc('pool-dashboard.due-date.remaining', remainingDays);
  }

  public get hasConflict() {
    return this.tasks.some((task) => task.conflictsCount > 0);
  }

  public onClosePool() {
    this.$emit('closePool');
  }
}
