import { i18n } from '@/plugins/i18n';

enum ProjectType {
  NONE = '',
  NER = 'text/ner',
  NER_RELATIONS = 'text/ner&relations',
  TEXT_CLASSIFICATION = 'text/classification',
  TEXT_CLASSIFICATION_MULTI_LABEL = 'text/multi_label_classification',
  QA = 'text/qa',
  IMAGE_CLASSIFICATION = 'image/classification',
  IMAGE_CLASSIFICATION_MULTI_LABEL = 'image/multi_label_classification',
  OBJECT_DETECTION = 'image/object_detection',
  OCR = 'image/ocr'
}

const projectTypeToText: { [projectType: string]: string } = {
  [ProjectType.NONE]: '',
  [ProjectType.NER]: i18n.t('project-type.ner') as string,
  [ProjectType.NER_RELATIONS]: i18n.t('project-type.ner-relations') as string,
  [ProjectType.TEXT_CLASSIFICATION]: i18n.t(
    'project-type.text-classification',
  ) as string,
  [ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL]: i18n.t(
    'project-type.text-classification-multi-label',
  ) as string,
  [ProjectType.QA]: i18n.t('project-type.qa') as string,
  [ProjectType.IMAGE_CLASSIFICATION]: i18n.t(
    'project-type.image-classification',
  ) as string,
  [ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL]: i18n.t(
    'project-type.image-classification-multi-label',
  ) as string,
  [ProjectType.OBJECT_DETECTION]: i18n.t(
    'project-type.object-detection',
  ) as string,
  [ProjectType.OCR]: i18n.t(
      'project-type.ocr',
  ) as string,
};

export default ProjectType;
export { projectTypeToText };
