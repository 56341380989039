import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import en from './lang/qa-annotator.en.json';
import fr from './lang/qa-annotator.fr.json';
import IQAAnnotationValue from '@/illuin-annotation/models/interfaces/qa-annotation';
import IDocument from '@/models/interfaces/document';
import IDataModel from '@/illuin-annotation/models/types/datamodel';

@Component({
  i18n: {
    messages: { en, fr },
  },
})
export default class QAAnnotator extends Vue {
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: { raw: any; rawString: string };
  @Prop() public datamodel!: IDataModel;
  @Prop() public preAnnotation!: any | null;

  public editingIndex: number | null = null;
  public pairsOfQA: IQAAnnotationValue[] = [];
  public question: string = '';
  public highlightedChunk: { start: number; end: number } | null = null;

  @Watch('document', { immediate: true })
  public onDocumentChange() {
    this.resetState();
    this.$emit('setAnnotation', this.annotation);
  }

  @Watch('preAnnotation', { immediate: true })
  public onPreAnnotationChange() {
    this.resetState();
    this.$emit('setAnnotation', this.annotation);
  }

  public resetState() {
    this.pairsOfQA = this.preAnnotation || [];
    this.question = '';
  }

  public get chunks(): {
    pre: string;
    highlighted: string;
    post: string;
  } | null {
    if (this.highlightedChunk) {
      return {
        pre: this.documentValue.rawString.slice(0, this.highlightedChunk.start),
        highlighted: this.documentValue.rawString.slice(
          this.highlightedChunk.start,
          this.highlightedChunk.end,
        ),
        post: this.documentValue.rawString.slice(this.highlightedChunk.end),
      };
    }
    return null;
  }

  public get annotation(): any {
    return this.pairsOfQA;
  }

  public addAnnotation(question: string, answer: string, answerStart: number) {
    if (question.length > 0) {
      this.pairsOfQA.push({
        question,
        answer,
        answer_start: answerStart,
      });
      this.$emit('setAnnotation', this.annotation);
      this.question = '';
    }

    Vue.nextTick(() => {
      const pairsContainer = this.$refs.pairs as HTMLElement;
      pairsContainer.scrollTop = pairsContainer.scrollHeight;
    });
  }

  public editAnnotationAnswer(index: number, answer: string, start: number) {
    Vue.set(this.pairsOfQA, index, {
      answer,
      answer_start: start,
      question: this.pairsOfQA[index].question,
    });
    this.editingIndex = null;
    this.$emit('setAnnotation', this.annotation);
  }

  public editAnnotationQuestion(index: number, question: string) {
    Vue.set(this.pairsOfQA, index, {
      question,
      answer: this.pairsOfQA[index].answer,
      answer_start: this.pairsOfQA[index].answer_start,
    });
    this.$emit('setAnnotation', this.annotation);
  }

  public deleteAnnotation(rowIndex: number) {
    this.pairsOfQA.splice(rowIndex, 1);
    this.highlightedChunk = null;
    this.$emit('setAnnotation', this.annotation);
  }

  public highlight(item: IQAAnnotationValue) {
    this.highlightedChunk = {
      start: item.answer_start,
      end: item.answer_start + item.answer.length,
    };
  }

  public unhighlight() {
    this.highlightedChunk = null;
  }

  public editQuestion(index: number) {
    this.editingIndex = index;
  }

  public editAnswer(index: number) {
    this.editingIndex = index;
    Vue.set(this.pairsOfQA, index, {
      answer: '',
      answer_start: 0,
      question: this.pairsOfQA[index].question,
    });
  }

  public handleMouseUp(event: any, preventBlur: boolean = false) {
    const selection = window.getSelection();
    const startOffset = selection ? selection!.anchorOffset : 0;
    const endOffset = selection ? selection!.focusOffset : 0;
    const start: number = Math.min(startOffset, endOffset);
    const end: number = Math.max(startOffset, endOffset);
    if (start !== end) {
      const answer = this.documentValue.rawString.substring(start, end);
      if (this.editingIndex === null) {
        this.addAnnotation(this.question, answer, start);
      } else {
        this.editAnnotationAnswer(this.editingIndex, answer, start);
      }
    }
    if (!preventBlur) {
      if (this.editingIndex !== null) {
        this.editingIndex = null;
      }
      if (this.question) {
        this.addAnnotation(this.question, '', 0);
      }
    }
  }

  public mounted() {
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  public beforeDestroy() {
    window.removeEventListener('mouseup', this.handleMouseUp);
  }
}
