import { Component, Prop, Vue } from 'vue-property-decorator';
import Roles from '@/models/enums/user-roles';
import IUser from '@/models/interfaces/user';
import { UserRoleDto } from '@/models/dtos/user-role.dto';
import ApiService from '@/services/api';
import { inject } from 'vue-typescript-inject';
import UserSearchBar from '@/components/UserSearchBar/UserSearchBar.vue';

@Component({
  providers: [ApiService],
  components: { UserSearchBar },
})
export default class AssignRoleForm extends Vue {
  @Prop() public readonly roleOptions!: { value: Roles; text: string }[];
  public errors: string[] = [];
  public addUserForm: UserRoleDto = {
    user: '',
    role: Roles.NONE,
  };
  public userSearchKey = 0;
  public userList: IUser[] = [];
  @inject() private readonly apiService!: ApiService;

  public created() {
    this.apiService.getPlatformUsers().subscribe((users) => {
      this.userList = users;
    });
  }

  public onSelectUser(user: IUser) {
    this.addUserForm.user = user.id;
  }

  public onSubmit() {
    this.errors = [];
    if (this.addUserForm.role === Roles.NONE) {
      this.errors.push('Veuillez choisir un rôle.');
    } else {
      this.$emit('submit', this.addUserForm);
      this.resetForm();
    }
  }

  public resetForm() {
    this.addUserForm = {
      user: '',
      role: Roles.NONE,
    };
    this.errors = [];
    this.userSearchKey = (this.userSearchKey + 1) % 2;
  }
}
