import { Component, Prop, Vue } from 'vue-property-decorator';
import en from './lang/object-detection-annotator-menu.en.json';
import fr from './lang/object-detection-annotator-menu.fr.json';
import RectangleIcon from '@/icons/Rectangle.vue';
import PolygonIcon from '@/icons/Polygon.vue';
import EraserIcon from '@/icons/Eraser.vue';
import MouseIcon from '@/icons/Mouse.vue';
import { Mode } from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotator/ObjectDetectionAnnotator';

@Component({
  components: {
    EraserIcon,
    MouseIcon,
    PolygonIcon,
    RectangleIcon,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class ObjectDetectionAnnotatorMenu extends Vue {
  public Mode = Mode;
  @Prop() public mode!: Mode;
}
