import { INERFullToken } from '@/illuin-annotation/models/interfaces/ner-token';
import { segmentsCollision } from '@/utils/math';
import INERTagging from '@/illuin-annotation/models/interfaces/ner-tagging';
import IBlock from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/block';
import IEntitiesLine from '@/illuin-annotation/components/NERRelation/mixins/NERRelationBaseAnnotatorTextMixin/interfaces/entities-line';

const computeSelectionTokens = (tokens: INERFullToken[]) => {
  const selection = window.getSelection();
  const anchorNode = selection ? selection.anchorNode : null;
  const focusNode = selection ? selection.focusNode : null;
  let anchorDataset = null;
  let focusDataset = null;
  let anchorToken = null;
  let focusToken = null;
  if (
    anchorNode &&
    anchorNode.firstChild &&
    anchorNode.firstChild.parentElement &&
    anchorNode.firstChild.parentElement.dataset
  ) {
    anchorDataset = anchorNode.firstChild.parentElement.dataset;
  } else if (
    anchorNode &&
    anchorNode.parentElement &&
    anchorNode.parentElement.dataset
  ) {
    anchorDataset = anchorNode.parentElement.dataset;
  }
  if (
    focusNode &&
    focusNode.firstChild &&
    focusNode.firstChild.parentElement &&
    focusNode.firstChild.parentElement.dataset
  ) {
    focusDataset = focusNode.firstChild.parentElement.dataset;
  } else if (
    focusNode &&
    focusNode.parentElement &&
    focusNode.parentElement.dataset
  ) {
    focusDataset = focusNode.parentElement.dataset;
  }
  if (anchorDataset) {
    const anchorTokenId: number = Number(anchorDataset.token);
    if (anchorTokenId >= 0) {
      anchorToken = tokens[anchorTokenId];
    }
  }
  if (focusDataset) {
    let focusTokenId: number = Number(focusDataset.token);
    if (selection && selection.focusOffset === 0 && focusTokenId > 0) {
      focusTokenId -= 1;
    }
    if (focusTokenId >= 0) {
      focusToken = tokens[focusTokenId];
    }
  }
  return [anchorToken, focusToken];
};

const isSelectionValid = (
  anchorToken: INERFullToken | null,
  focusToken: INERFullToken | null,
  nerTaggings: { [key: string]: INERTagging },
) => {
  const selection = window.getSelection();
  if (
    selection &&
    (selection.anchorNode !== selection.focusNode ||
      selection.anchorOffset !== selection.focusOffset) &&
    anchorToken &&
    focusToken
  ) {
    for (const tagging of Object.values(nerTaggings)) {
      if (
        segmentsCollision(
          anchorToken.id,
          focusToken.id,
          tagging.begin,
          tagging.end,
        )
      ) {
        return false;
      }
    }
    return true;
  }
  return false;
};

const computeBlocks = (
  maxwidth: number,
  tokens: INERFullToken[],
  nerTaggings: { [key: string]: INERTagging },
) => {
  let blocks: IBlock[] = [];
  for (const tagging of Object.values(nerTaggings)) {
    const tempBlocks = [];
    const taggingTokens = tokens.slice(tagging.begin, tagging.end + 1);
    for (let y = tokens[tagging.begin].y; y <= tokens[tagging.end].y; y += 1) {
      const blockTokens = taggingTokens.filter((token) => token.y === y);
      let endX = blockTokens.length > 0 ? blockTokens[0].x : 0;
      let text = '';
      for (const token of blockTokens) {
        text += ' '.repeat(token.x - endX);
        text += token.word;
        endX = token.endX;
      }
      tempBlocks.push({
        y,
        tagging,
        text,
        beginX: 0,
        endX: maxwidth,
        isFirst: false,
        isLast: false,
      });
    }
    tempBlocks[0].beginX = tokens[tagging.begin].x;
    tempBlocks[0].isFirst = true;
    tempBlocks[tempBlocks.length - 1].endX = tokens[tagging.end].endX;
    tempBlocks[tempBlocks.length - 1].isLast = true;

    blocks = blocks.concat(tempBlocks);
  }
  return blocks;
};

const computeEntitiesLines = (blocks: IBlock[]): IEntitiesLine[] => {
  const blocksByLine: { [key: number]: IBlock[] } = {};
  for (const block of blocks) {
    if (blocksByLine.hasOwnProperty(block.y)) {
      blocksByLine[block.y].push(block);
    } else {
      blocksByLine[block.y] = [block];
    }
  }

  const entitiesLines: IEntitiesLine[] = [];
  for (const line of Object.keys(blocksByLine)) {
    entitiesLines.push({ y: Number(line), blocks: blocksByLine[Number(line)] });
  }
  return entitiesLines;
};

export {
  computeSelectionTokens,
  isSelectionValid,
  computeBlocks,
  computeEntitiesLines,
};
