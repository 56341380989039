import IAnnotationValue from '@/illuin-annotation/models/types/annotation';
import nerRelationGCF from '@/illuin-annotation/services/annotation-gcf/ner-relation';
import nerGCF from '@/illuin-annotation/services/annotation-gcf/ner';
import INERRelationAnnotationValue from '@/illuin-annotation/models/interfaces/ner-relation-annotation';
import INERAnnotationValue from '@/illuin-annotation/models/interfaces/ner-annotation';
import { AnnotationType } from '@/models/enums/annotation-type';

const computeAnnotationsGCF = (
  annotationType: AnnotationType,
  annotations: IAnnotationValue[],
): IAnnotationValue | null => {
  switch (annotationType) {
    case AnnotationType.Ner:
      return nerGCF(annotations as INERAnnotationValue[]);
    case AnnotationType.NerRelations:
      return nerRelationGCF(annotations as INERRelationAnnotationValue[]);
    default:
      return null;
  }
};

export default computeAnnotationsGCF;
