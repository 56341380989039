import { Component, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import { v4 as uuid } from 'uuid';
import INerRelationsDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerrelationsdatamodel';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import en from './lang/ner-relations-datamodel-form.en.json';
import fr from './lang/ner-relations-datamodel-form.fr.json';
import IRelation from '@/illuin-annotation/models/interfaces/relation';

@Component({
  components: {
    Swatches,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class NerRelationsDataModelForm extends Vue {
  public show: string = 'tags';
  public editRelation: number = 0;
  public defaultColors: string[] = [
    '#666666',
    '#32BCB1',
    '#881D81',
    '#D32455',
    '#6570FF',
    '#E28624',
    '#9ED400',
    '#000000',
  ];
  public errors: string[] = [];
  public datamodel: INerRelationsDataModel = {
    tags: [],
    relations: [],
  };

  public onSubmit(event: any) {
    event.preventDefault();
    this.errors = [];
    if (this.hasUncompleteRelation()) {
      this.errors.push(
        this.$t('ner-relations-datamodel-form.error.empty-side') as string,
      );
      this.$forceUpdate();
    } else {
      this.$emit('submit', this.datamodel);
    }
  }

  public showTags() {
    this.show = 'tags';
  }

  public showRelations(event: any) {
    event.preventDefault();
    this.show = 'relations';
  }

  public hasUncompleteRelation(): boolean {
    for (const relation of this.datamodel.relations) {
      if (!relation.leftTagsIds.length || !relation.rightTagsIds.length) {
        return true;
      }
    }
    return false;
  }

  /*
   * Tag related methods
   */

  public get tagCustomIdDuplicates() {
    return this.datamodel.tags
      .map((tag: ITag) => tag.customId)
      .filter((value, index, self) => self.indexOf(value) !== index);
  }

  public changeShortcut(index: number, event: any) {
    this.datamodel.tags[index].shortcut = event.key;
  }

  public addTag() {
    const id = uuid();
    this.datamodel.tags.push({
      id,
      customId: id,
      title: '',
      shortcut: '',
      color: this.defaultColors[
        this.datamodel.tags.length % this.defaultColors.length
      ],
    });
  }

  public confirmDeleteTag(index: number) {
    const tag = this.datamodel.tags[index];
    const relationList = [];

    for (const relation of this.datamodel.relations) {
      let isUsed: boolean = false;
      for (const relTagId of relation.leftTagsIds) {
        if (relTagId === tag.id) {
          isUsed = true;
        }
      }

      for (const relTagId of relation.rightTagsIds) {
        if (relTagId === tag.id) {
          isUsed = true;
        }
      }

      if (isUsed) {
        relationList.push(relation);
      }
    }

    if (relationList.length) {
      this.$bvModal.show(`deleteTag${index}`);
    } else {
      this.deleteTag(index);
    }
  }

  public deleteTag(index: number) {
    const deletedTag = this.datamodel.tags[index];
    this.datamodel.relations.forEach((relation, i) => {
      this.datamodel.relations[i].leftTagsIds = relation.leftTagsIds.filter(
        (tagId) => tagId !== deletedTag.id,
      );
      this.datamodel.relations[i].rightTagsIds = relation.rightTagsIds.filter(
        (tagId) => tagId !== deletedTag.id,
      );
    });
    this.datamodel.tags.splice(index, 1);
  }

  /*
   * Relations related methods
   */

  public get relationCustomIdDuplicates() {
    return this.datamodel.relations
      .map((relation: IRelation) => relation.customId)
      .filter((value, index, self) => self.indexOf(value) !== index);
  }

  public addRelation() {
    const id = uuid();
    this.datamodel.relations.push({
      id,
      customId: id,
      name: '',
      leftTagsIds: [],
      rightTagsIds: [],
    });
    this.editRelation = this.datamodel.relations.length - 1;
  }

  public deleteRelation(index: number) {
    this.datamodel.relations.splice(index, 1);
    this.editRelation = -1;
  }

  public setEditOnRelation(index: number) {
    this.editRelation = index;
  }

  public tagNamesList(tagList: string[]) {
    return tagList.map((tagId) => this.tagTitleFromId(tagId)).join(', ');
  }

  public tagTitleFromId(tagId: string) {
    for (const tag of this.datamodel.tags) {
      if (tag.id === tagId) {
        return tag.title;
      }
    }
    return null;
  }

  public deleteTagForRelation(tagIndex: number, tagIdList: string[]) {
    tagIdList.splice(tagIndex, 1);
  }

  public availableTags(tagList: string[]) {
    const availableTags = [];

    for (const tag of this.datamodel.tags) {
      let available = true;
      for (const tagId of tagList) {
        if (tagId === tag.id) {
          available = false;
          break;
        }
      }
      if (available) {
        availableTags.push({ value: tag, text: tag.title });
      }
    }
    return availableTags;
  }

  public addTagToRelation(event: ITag, tagList: string[]) {
    if (event) {
      tagList.push(event.id);
    }
  }

  public closeEdit() {
    this.editRelation = -1;
  }

  public created() {
    this.addTag();
    this.addRelation();
  }
}
