import { Component, Vue } from 'vue-property-decorator';
import IProject from '@/models/interfaces/project';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import DataImport from '@/components/DataImport/DataImport.vue';
import { inject } from 'vue-typescript-inject';
import IImport from '@/models/interfaces/import';
import { StorageMethod } from '@/store/store';

@Component({
  computed: mapState('admin-project', ['project']),
  components: { DataImport },
  providers: [ApiService],
})
export default class ProjectImportEntries extends Vue {
  public project!: IProject;
  public importing: boolean = false;
  @inject() private readonly apiService!: ApiService;

  public waitImport(createdProject: IProject, importData: IImport) {
    this.apiService
      .getImport(importData)
      .subscribe((newImportData: IImport) => {
        if (newImportData.finished && !newImportData.errored) {
          this.importing = false;
          this.$notify({
            group: 'app',
            type: 'success',
            text: this.$t('alert.entries.addition.success') as string,
          });
          this.$router.push({
            name: 'project-admin-settings-entries',
            params: { id: this.project.id },
          });
        } else if (newImportData.finished && newImportData.errored) {
          this.$notify({
            group: 'app',
            type: 'error',
            text: this.$t('alert.entries.addition.error', {
              errorMessage: newImportData.errorMessage,
            }) as string,
          });
          this.$router.push({
            name: 'project-admin-settings-entries',
            params: { id: this.project.id },
          });
        } else {
          setTimeout(
            () => this.waitImport(createdProject, newImportData),
            1000,
          );
        }
      });
  }

  public onSubmit(event: { dataset: any; type: StorageMethod }) {
    if (event.type === StorageMethod.LOCAL) {
      this.importing = true;
      this.apiService
        .importData(this.project, event.dataset)
        .subscribe((importData: IImport) => {
          this.waitImport(this.project, importData);
        });
    } else if (event.type === StorageMethod.AWS) {
      this.importing = true;
      this.apiService
        .importAwsData(this.project, event.dataset)
        .subscribe((documents) => {
          this.importing = false;
          this.$notify({
            group: 'app',
            type: 'success',
            text: this.$t('alert.entries.addition.success') as string,
          });
          this.$router.push({
            name: 'project-admin-settings-entries',
            params: { id: this.project.id },
          });
        });
    } else {
      this.$notify({
        group: 'app',
        type: 'error',
        text: this.$t('alert.entries.addition.error', {
          errorMessage: 'Invalid file extension',
        }) as string,
      });
    }
  }
}
