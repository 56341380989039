import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { UserAgentApplication } from 'msal';
import { inject } from 'vue-typescript-inject';
import ApiService from '@/services/api';
import { Observable } from 'rxjs';

@Component({
  computed: {
    ...mapState(['azureClientId', 'azureTenantAuthority', 'azureRedirectUri']),
  },
  providers: [ApiService],
})
export default class AzureSigninMixin extends Vue {
  public azureClientId!: string;
  public azureTenantAuthority!: string;
  public azureRedirectUri!: string;
  public msalInstance!: UserAgentApplication;
  @inject() private readonly apiService!: ApiService;

  public created(): void {
    const msalConfig = {
      auth: {
        clientId: this.azureClientId,
        authority: `https://login.microsoftonline.com/${this.azureTenantAuthority}`,
        postLogoutRedirectUri: this.azureRedirectUri,
      },
    };

    this.msalInstance = new UserAgentApplication(msalConfig);
  }

  public signOut(): void {
    this.msalInstance.logout();
    this.$store.commit('deleteBackToken');
    this.$router.push({ name: 'login' });
  }

  public async login(): Promise<Observable<any>> {
    const loginRequest = {
      scopes: ['user.read'],
      redirectUri: this.azureRedirectUri,
    };

    await this.msalInstance.loginPopup(loginRequest);

    const accessTokenResponse = await this.msalInstance.acquireTokenSilent({
      scopes: ['user.read'],
    });

    return this.apiService.azureAuthenticate(accessTokenResponse.accessToken);
  }
}
