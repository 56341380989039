import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import AnnotatorTags from '@/illuin-annotation/components/common/AnnotatorTags/AnnotatorTags.vue';
import INerDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerdatamodel';
import ObjectDetectionAnnotatorRenderer from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorRenderer/ObjectDetectionAnnotatorRenderer.vue';
import ObjectDetectionAnnotatorMenu from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionAnnotatorMenu/ObjectDetectionAnnotatorMenu.vue';
import PieTagSelector from '@/illuin-annotation/components/common/PieTagSelector/PieTagSelector.vue';
import IImageTagging from '@/illuin-annotation/models/interfaces/image-tagging';
import IImageAnnotationValue from '@/illuin-annotation/models/interfaces/image-annotation';
import uuidByString from 'uuid-by-string';
import { toArrayBuffer } from '@/illuin-annotation/services/utils/image-buffer';
import en from './lang/object-detection-annotator.en.json';
import fr from './lang/object-detection-annotator.fr.json';
import IDocument from '@/models/interfaces/document';

export enum Mode {
  inspect = 'inspect',
  rectangle = 'rectangle',
  polygon = 'polygon',
  erase = 'erase',
}

@Component({
  components: {
    AnnotatorTags,
    ObjectDetectionAnnotatorMenu,
    ObjectDetectionAnnotatorRenderer,
    PieTagSelector,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class ObjectDetectionAnnotator extends Vue {
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: { raw: { data: ArrayBuffer; type: string } };
  @Prop() public datamodel!: INerDataModel;
  @Prop() public passive!: boolean;
  @Prop() public preAnnotation!: IImageAnnotationValue;

  public mode: Mode = Mode.rectangle;

  public taggings: { [key: string]: IImageTagging } = {};

  public displayTagSelector: boolean = false;
  public mousePosition: { [key: string]: number } = {
    left: 0,
    top: 0,
  };
  public imagePosition: { [key: string]: number } = {
    left: 0,
    top: 0,
  };

  public pendingTagging: IImageTagging | null = null;

  @Watch('documentValue')
  public onDocumentChange() {
    this.clear();
    this.computePreAnnotation();
    this.$emit('setAnnotation', this.annotation);
  }

  @Watch('preAnnotation')
  public onPreAnnotationChange() {
    this.computePreAnnotation();
  }

  public get tags(): ITag[] {
    return this.datamodel.tags;
  }

  public get imageBuffer() {
    return toArrayBuffer(this.documentValue.raw.data);
  }

  public get tagsByKey(): { [key: string]: ITag } {
    const tagsByKey: { [key: string]: ITag } = {};
    this.tags.forEach((tag: ITag) => {
      tagsByKey[tag.shortcut] = tag;
    });
    return tagsByKey;
  }

  public get tagsById(): { [key: string]: ITag } {
    const tagsById: { [key: string]: ITag } = {};
    this.tags.forEach((tag: ITag) => {
      tagsById[tag.id] = tag;
    });
    return tagsById;
  }

  public get annotation(): IImageAnnotationValue {
    return {
      tags: Object.values(this.taggings).map((tagging: IImageTagging) => {
        return {
          id: uuidByString(
            `${tagging.vertices.map((v) => `${v.x} ${v.y}`).join(' ')}-${
              tagging.tag!.id
            }`,
          ),
          vertices: tagging.vertices,
          tag: tagging.tag!.id,
        };
      }),
    };
  }

  public change(mode: Mode) {
    this.clear();
    this.mode = mode;
  }

  public addTagging(data: {
    mousePosition: { left: number; top: number };
    imagePosition: { left: number; top: number };
    taggingInfo: IImageTagging;
  }) {
    this.pendingTagging = data.taggingInfo;
    if (this.tags.length === 1) {
      this.confirmTagging(this.tags[0]);
    } else {
      this.mousePosition = data.mousePosition;
      this.imagePosition = data.imagePosition;

      this.displayTagSelector = true;
    }
  }

  public confirmTagging(tag: ITag) {
    if (this.pendingTagging) {
      Vue.set(this.taggings, this.pendingTagging.id, {
        ...this.pendingTagging,
        tag,
      });
    }
    this.clear();
    this.$emit('setAnnotation', this.annotation);
  }

  public clear() {
    this.pendingTagging = null;
    this.displayTagSelector = false;
  }

  public deleteTagging(tagging: IImageTagging) {
    Vue.delete(this.taggings, tagging.id);
    this.$emit('setAnnotation', this.annotation);
  }

  public computePreAnnotation() {
    this.taggings = {};
    if (this.preAnnotation) {
      this.preAnnotation.tags.forEach((tagging) => {
        this.taggings[tagging.id] = {
          id: tagging.id,
          vertices: tagging.vertices,
          tag: this.tagsById[tagging.tag],
        };
      });
    }
  }

  /**
   * Life cycle
   */

  private handleKeyup(event: any) {
    if (event.key && event.key === 'Escape') {
      this.clear();
    }
    if (!this.pendingTagging && event.key) {
      switch (event.key) {
        case 'v':
          this.change(Mode.inspect);
          break;
        case 'm':
          this.change(Mode.rectangle);
          break;
        case 'l':
          this.change(Mode.polygon);
          break;
        case 'e':
          this.change(Mode.erase);
          break;
      }
    }
    if (this.pendingTagging && event.key && this.tagsByKey[event.key]) {
      this.confirmTagging(this.tagsByKey[event.key]);
    }
  }

  private mounted() {
    if (!this.passive) {
      window.addEventListener('keyup', this.handleKeyup);
      this.$emit('setAnnotation', this.annotation);
    }
  }

  private created() {
    this.computePreAnnotation();
  }

  private beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup);
  }
}
