import { RootState } from '@/store/store';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import IProject from '@/models/interfaces/project';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import IPool from '@/models/interfaces/pool';
import Vue from 'vue';
import IExport from '@/models/interfaces/export';

interface AdminProjectState {
  project: IProject | null;
}

const getDefaultState = (): AdminProjectState => {
  return {
    project: null,
  };
};

const storeState: AdminProjectState = getDefaultState();

const storeGetters: GetterTree<AdminProjectState, RootState> = {};

const storeMutations: MutationTree<AdminProjectState> = {
  init(state) {
    Object.assign(state, getDefaultState());
  },
  setProject(state, project: IProject) {
    state.project = project;
  },
  updateDataModel(state, dataModel: IDataModel) {
    if (state.project) {
      Vue.set(state.project, 'dataModel', dataModel);
    }
  },
  updatePools(state, pools: IPool[]) {
    if (state.project) {
      Vue.set(state.project, 'pools', pools);
    }
  },
  addPool(state, pool: IPool) {
    if (state.project) {
      if (state.project.pools) {
        Vue.set(state.project, 'pools', [...state.project.pools, pool]);
      } else {
        Vue.set(state.project, 'pools', [pool]);
      }
    }
  },
  deletePool(state, deletedPool: IPool) {
    if (state.project) {
      if (state.project.pools) {
        Vue.set(
          state.project,
          'pools',
          state.project.pools.filter((pool) => pool.id !== deletedPool.id),
        );
      }
    }
  },
  updateExports(state, exports: IExport[]) {
    if (state.project) {
      Vue.set(state.project, 'exports', exports);
    }
  },
  addExport(state, exprt: IExport) {
    if (state.project) {
      if (state.project.exports) {
        Vue.set(state.project, 'exports', [...state.project.exports, exprt]);
      } else {
        Vue.set(state.project, 'exports', [exprt]);
      }
    }
  },
};

const storeActions: ActionTree<AdminProjectState, RootState> = {};

const adminProjectStore = {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};

export default adminProjectStore;
