import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LocalDataImportForm extends Vue {
  public dataset: any = null;

  public onSubmit(event: any) {
    event.preventDefault();
    this.$emit('submit', this.dataset);
  }
}
