// tslint:disable
import Vue, { CreateElement } from 'vue';
import Router from 'vue-router';
import Annotation from '@/views/Annotation/Annotation.vue';
import ProjectList from '@/views/ProjectList/ProjectList.vue';
import NewProject from '@/views/NewProject/NewProject.vue';
import Project from '@/views/Project/Project.vue';
import ProjectGeneralSettings from '@/views/Project/ProjectGeneral/ProjectInfos/ProjectInfos.vue';
import ProjectDataModel from '@/views/Project/ProjectGeneral/ProjectDataModel/ProjectDataModel.vue';
import ProjectExport from '@/views/Project/ProjectExport/ProjectExport.vue';
import Login from '@/views/Login/Login.vue';
import store from '@/store/store';
import JWT from 'jwt-decode';
import CreateTasks from '@/views/Project/CreateTasks/CreateTasks.vue';
import PoolList from '@/views/Project/PoolList/PoolList.vue';
import Homepage from '@/views/Homepage/Homepage.vue';
import ProjectRoles from '@/views/Project/ProjectSettings/ProjectRoles/ProjectRoles.vue';
import Permissions from '@/views/Logged/Logged.vue';
import PlatformAdmin from '@/views/PlatformAdmin/PlatformAdmin.vue';
import PlatformRoles from '@/views/PlatformAdmin/PlatformRoles/PlatformRoles.vue';
import PlatformProjectAdmin from '@/views/PlatformAdmin/PlatformProjectAdmin/PlatformProjectAdmin.vue';
import PoolAdmin from '@/views/PoolAdmin/PoolAdmin.vue';
import PoolAdminProject from '@/views/PoolAdmin/PoolAdminProject/PoolAdminProject.vue';
import PoolAdminDashboard from '@/views/PoolAdmin/PoolAdminDashboard/PoolAdminDashboard.vue';
import PoolAdminAnnotators from '@/views/PoolAdmin/PoolAdminAnnotators/PoolAdminAnnotators.vue';
import PoolAdminScores from '@/views/PoolAdmin/PoolAdminScores/PoolAdminScores.vue';
import PoolAdminConflicts from '@/views/PoolAdmin/PoolAdminConflicts/PoolAdminConflicts.vue';
import PoolAdminSettings from '@/views/PoolAdmin/PoolAdminSettings/PoolAdminSettings.vue';
import ProjectDashboard from '@/views/Project/ProjectDashboard/ProjectDashboard.vue';
import ProjectImportEntries from '@/views/Project/ProjectSettings/ProjectImportEntries/ProjectImportEntries.vue';
import ProjectImportAnnotations from '@/views/Project/ProjectSettings/ProjectImportAnnotations/ProjectImportAnnotations.vue';
import ProjectEntries from '@/views/Project/ProjectSettings/ProjectEntries/ProjectEntries.vue';
import PoolAdminMyDocuments from '@/views/PoolAdmin/PoolAdminMyDocuments/PoolAdminMyDocuments.vue';
import CreateAccount from '@/views/PlatformAdmin/CreateAccount/CreateAccount.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: store.state.baseUrl,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      component: Permissions,
      beforeEnter: (to, from, next) => {
        const token = store.getters.backToken;
        if (!token) {
          next({ name: 'login' });
        } else {
          try {
            const decoded: { exp: number; [key: string]: any } = JWT(token);
            if (decoded.exp * 1000 < Date.now()) {
              store.commit('deleteBackToken');
              next({ name: 'login' });
            } else {
              next();
            }
          } catch (e) {
            store.commit('deleteBackToken');
            next({ name: 'login' });
          }
        }
      },
      children: [
        {
          path: '/',
          name: 'homepage',
          component: Homepage,
        },
        {
          path: 'projects',
          name: 'project-list',
          component: ProjectList,
        },
        {
          path: 'projects/new',
          name: 'new-project',
          component: NewProject,
        },
        {
          path: 'project/:id',
          name: 'project',
          component: Project,
          redirect: 'project/:id/dashboard',
          children: [
            {
              path: 'general',
              name: 'project-admin',
              redirect: 'general/infos',
              component: {
                render(c: CreateElement) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: 'infos',
                  name: 'project-admin–general',
                  component: ProjectGeneralSettings,
                },
                {
                  path: 'datamodel',
                  name: 'project-admin–datamodel',
                  component: ProjectDataModel,
                },
              ],
            },
            {
              path: 'dashboard',
              name: 'project-admin-dashboard',
              component: ProjectDashboard,
            },
            {
              path: 'pools',
              name: 'project-admin-pools',
              redirect: 'pools/list',
              component: {
                render(c: CreateElement) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: 'list',
                  name: 'project-admin-pools-list',
                  component: PoolList,
                },
                {
                  path: 'create',
                  name: 'project-admin-pools-create',
                  component: CreateTasks,
                },
              ],
            },
            {
              path: 'settings',
              name: 'project-admin-settings',
              redirect: 'settings/roles',
              component: {
                render(c: CreateElement) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: 'roles',
                  name: 'project-admin-settings-roles',
                  component: ProjectRoles,
                },
                {
                  path: 'entries',
                  name: 'project-admin-settings-entries',
                  component: ProjectEntries,
                },
                {
                  path: 'entries/add',
                  name: 'project-admin-settings-entries-add',
                  component: ProjectImportEntries,
                },
                {
                  path: 'entries/add-annotations',
                  name: 'project-admin-settings-annotations-add',
                  component: ProjectImportAnnotations,
                },
              ],
            },
            {
              path: 'export',
              name: 'project-admin-export',
              component: ProjectExport,
            },
          ],
        },
        {
          path: 'pool/:poolId',
          name: 'pool-admin',
          component: PoolAdmin,
          redirect: 'pool/:poolId/dashboard',
          children: [
            {
              path: 'project',
              name: 'pool-admin-project',
              component: PoolAdminProject,
            },
            {
              path: 'dashboard',
              name: 'pool-admin-dashboard',
              component: PoolAdminDashboard,
            },
            {
              path: 'annotators',
              name: 'pool-admin-annotators',
              component: PoolAdminAnnotators,
            },
            {
              path: 'my-documents',
              name: 'pool-my-documents',
              component: PoolAdminMyDocuments,
            },
            {
              path: 'scores',
              name: 'pool-admin-scores',
              component: PoolAdminScores,
            },
            {
              path: 'conflicts',
              name: 'pool-admin-conflicts',
              component: PoolAdminConflicts,
            },
            {
              path: 'settings',
              name: 'pool-admin-settings',
              component: PoolAdminSettings,
            },
          ],
        },
        {
          path: '/annotate/:taskId',
          name: 'annotation',
          component: Annotation,
        },
        {
          path: 'platform-admin',
          name: 'platform-admin',
          component: PlatformAdmin,
          redirect: 'platform-admin/platform-roles',
          children: [
            {
              path: 'create-account',
              name: 'create-account',
              component: CreateAccount,
            },
            {
              path: 'platform-roles',
              name: 'platform-roles',
              component: PlatformRoles,
            },
            {
              path: 'platform-project-admin',
              name: 'platform-project-admin',
              component: PlatformProjectAdmin,
            },
          ],
        },
      ],
    },
  ],
});
