import { Component, Prop, Vue } from 'vue-property-decorator';
import Swatches from 'vue-swatches';
import INerRelationsDataModel from '@/illuin-annotation/models/interfaces/datamodels/nerrelationsdatamodel';
import en from './lang/ner-relations-display.en.json';
import fr from './lang/ner-relations-display.fr.json';

@Component({
  components: {
    Swatches,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class NerRelationsDisplay extends Vue {
  @Prop() public datamodel!: INerRelationsDataModel;

  public tagNamesList(tagList: string[]) {
    return tagList.map((tagId) => this.tagTitleFromId(tagId)).join(', ');
  }

  public tagTitleFromId(tagId: string) {
    for (const tag of this.datamodel.tags) {
      if (tag.id === tagId) {
        return tag.title;
      }
    }
    return null;
  }
}
