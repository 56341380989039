import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  computeImageSource,
  toArrayBuffer,
} from '@/illuin-annotation/services/utils/image-buffer';

@Component({})
export default class ImagePreviewer extends Vue {
  @Prop() public documentValue!: { raw: { data: ArrayBuffer; type: string } };

  public imageUrl: string = '';

  public created() {
    this.imageUrl = computeImageSource(
      toArrayBuffer(this.documentValue.raw.data),
    );
  }
}
