import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/layouts/Sidebar/Sidebar.vue';
import DocumentsList from '@/components/DocumentsList/DocumentsList.vue';
import { mapState } from 'vuex';
import PastelIcon from '@/components/PastelIcon/PastelIcon.vue';
import ITask from '@/models/interfaces/task';

@Component({
  components: {
    Sidebar,
    PastelIcon,
    DocumentsList,
  },
  computed: {
    ...mapState('annotation', ['task']),
  },
})
export default class SidebarAnnotation extends Vue {
  public task!: ITask;
}
