import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import IDataModel from '@/illuin-annotation/models/types/datamodel';
import NERRelationDiffAnnotator from '@/illuin-annotation/components/NERRelation/NERRelationDiffAnnotator/NERRelationDiffAnnotator.vue';
import ProjectType from '@/models/enums/project-type';
import IDocument from '@/models/interfaces/document';
import ObjectDetectionDiffAnnotator from '@/illuin-annotation/components/ObjectDetection/ObjectDetectionDiffAnnotator/ObjectDetectionDiffAnnotator.vue';
import ClassificationDiffAnnotator
  from '@/illuin-annotation/components/Classification/ClassificationDiffAnnotator/ClassificationDiffAnnotator.vue';
import IAnnotation from '@/models/interfaces/annotation';
import OCRDiffAnnotator from '@/illuin-annotation/components/OCR/OCRDiffAnnotator/OCRDiffAnnotator.vue';

@Component({})
export default class DiffAnnotator extends Vue {
  @Prop() public type!: ProjectType;
  @Prop() public document!: IDocument;
  @Prop() public documentValue!: any;
  @Prop() public datamodel!: IDataModel;
  @Prop({ default: false }) public passive!: boolean;
  @Prop({ default: () => [] }) public preAnnotations!: IAnnotation[];

  public diffAnnotatorComponentByName: {
    [key: string]: VueConstructor<Vue>;
  } = {
    [ProjectType.NER]: NERRelationDiffAnnotator,
    [ProjectType.NER_RELATIONS]: NERRelationDiffAnnotator,
    [ProjectType.TEXT_CLASSIFICATION_MULTI_LABEL]: ClassificationDiffAnnotator,
    [ProjectType.IMAGE_CLASSIFICATION_MULTI_LABEL]: ClassificationDiffAnnotator,
    [ProjectType.TEXT_CLASSIFICATION]: ClassificationDiffAnnotator,
    [ProjectType.IMAGE_CLASSIFICATION]: ClassificationDiffAnnotator,
    [ProjectType.OBJECT_DETECTION]: ObjectDetectionDiffAnnotator,
    [ProjectType.OCR]: OCRDiffAnnotator,
  };

  get componentName(): VueConstructor<Vue> {
    return this.diffAnnotatorComponentByName[this.type];
  }
}
