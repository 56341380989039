import { curveBasis, line } from 'd3';

const CHARHEIGHT = 60;
const CHARWIDTH = 10;
const RELATION_LABEL_MARGIN = CHARWIDTH / 2;
const RELATION_CHARWIDTH = 8.5;
const RELATION_HEIGHT = (2 * CHARHEIGHT) / 5;
const ENTITY_TYPE_CHARWIDTH = 9;

const arrow = line<{ x: number; y: number }>()
  .x((d) => d.x)
  .y((d) => d.y)
  .curve(curveBasis);

export {
  CHARHEIGHT,
  CHARWIDTH,
  RELATION_LABEL_MARGIN,
  RELATION_CHARWIDTH,
  RELATION_HEIGHT,
  ENTITY_TYPE_CHARWIDTH,
  arrow,
};
