import INERTagging from '@/illuin-annotation/models/interfaces/ner-tagging';
import uuidByString from 'uuid-by-string';
import IRelationTagging from '@/illuin-annotation/models/interfaces/relation-tagging';
import INERAnnotationValue from '@/illuin-annotation/models/interfaces/ner-annotation';
import INERRelationAnnotationValue from '@/illuin-annotation/models/interfaces/ner-relation-annotation';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import IRelation from '@/illuin-annotation/models/interfaces/relation';

const formatExportableNerTaggings = (nerTaggings: {
  [key: string]: INERTagging;
}) => {
  return Object.values(nerTaggings).map((nerTagging: INERTagging) => {
    return {
      id: uuidByString(
        `${nerTagging.begin}-${nerTagging.end}-${nerTagging.tag!.id}`,
      ),
      begin: nerTagging.begin,
      end: nerTagging.end,
      tag: nerTagging.tag!.id,
    };
  });
};

const formatExportableRelationTaggings = (
  relationTaggings: IRelationTagging[],
) => {
  return relationTaggings.map((relationTagging: IRelationTagging) => {
    return {
      id: uuidByString(
        `${relationTagging.begin.begin}-${relationTagging.end.begin}-${
          relationTagging.relation!.id
        }`,
      ),
      begin: uuidByString(
        `${relationTagging.begin.begin}-${relationTagging.begin.end}-${
          relationTagging.begin.tag!.id
        }`,
      ),
      end: uuidByString(
        `${relationTagging.end.begin}-${relationTagging.end.end}-${
          relationTagging.end.tag!.id
        }`,
      ),
      relation: relationTagging.relation!.id,
    };
  });
};

const getNerTaggingsFromExported = (
  annotationValue: INERAnnotationValue | INERRelationAnnotationValue | null,
  tagsById: { [key: string]: ITag },
) => {
  const nerTaggings: { [key: string]: INERTagging } = {};
  if (annotationValue) {
    annotationValue.tags.forEach((nerTagging) => {
      nerTaggings[nerTagging.id] = {
        id: nerTagging.id,
        begin: nerTagging.begin,
        end: nerTagging.end,
        tag: tagsById[nerTagging.tag],
      };
    });
  }
  return nerTaggings;
};

const getRelationTaggingsFromExported = (
  annotationValue: INERAnnotationValue | INERRelationAnnotationValue | null,
  nerTaggings: { [key: string]: INERTagging },
  relationsById: { [key: string]: IRelation },
) => {
  const relationTaggings: IRelationTagging[] = [];
  if (annotationValue) {
    (annotationValue as INERRelationAnnotationValue).relations!.forEach(
      (relationTagging) => {
        const begin = nerTaggings[relationTagging.begin];
        const end = nerTaggings[relationTagging.end];
        relationTaggings.push({
          begin,
          end,
          id: relationTagging.id,
          relation: relationsById[relationTagging.relation],
          reversed: begin.begin > end.begin,
        });
      },
    );
  }
  return relationTaggings;
};

export {
  formatExportableNerTaggings,
  formatExportableRelationTaggings,
  getNerTaggingsFromExported,
  getRelationTaggingsFromExported,
};
