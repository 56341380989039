import { Component, Prop, Vue } from 'vue-property-decorator';
import IUser from '@/models/interfaces/user';
import UserSearchBar from '@/components/UserSearchBar/UserSearchBar.vue';
import Roles from '@/models/enums/user-roles';

@Component({
  components: { UserSearchBar },
})
export default class PlatformRolesForm extends Vue {
  @Prop() public userList!: IUser[];
  @Prop() public roleOptions!: { value: Roles; text: string }[];
  public selectedUser: IUser | null = null;
  public selectedRole: Roles = Roles.NONE;
  public resetKey: number = 0;
  public errors: string[] = [];

  public onSelectUser(user: IUser) {
    this.selectedUser = user;
  }

  public onSubmit() {
    if (this.selectedUser) {
      const userRole = { user: this.selectedUser.id, role: this.selectedRole };
      this.$emit('submit', userRole);
      this.resetForm();
    }
  }
  public resetForm() {
    this.resetKey += 1;
  }
}
