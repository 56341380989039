import { Component, Prop, Vue } from 'vue-property-decorator';
import ITag from '@/illuin-annotation/models/interfaces/tag';
import { PreviewStrategy } from '@/illuin-annotation/components/Classification/utils/preview-strategy';

@Component({
  components: {},
})
export default class ClassesConflictedTags extends Vue {
  @Prop() public tags!: ITag[];
  @Prop({ default: () => [] }) public commonTagIds!: string[];
  @Prop({ default: () => [] }) public mismatchTagIdsList!: string[][];
  @Prop() public previewStrategy!: PreviewStrategy | null;

  public PreviewStrategy = PreviewStrategy;
}
