import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import IDocument from '@/models/interfaces/document';

@Component({
  computed: mapState('annotation', [
    'documents',
    'cachedAnnotations',
    'currentDocumentIdx',
  ]),
})
export default class DocumentsList extends Vue {
  public documents!: IDocument[];
  public cachedAnnotations!: { [key: string]: { duration: number, value: any } };
  public currentDocumentIdx!: number | null;
  public queryFilter: string = '';
  public conflictOnly: boolean = false;

  public get displayedDocuments() {
    return this.documents.filter((document) => {
      if (this.conflictOnly && !document.hasConflict) {
        return false;
      }
      return document.name.includes(this.queryFilter);
    });
  }

  public documentIsValid(index: number): boolean {
    return !!this.documents[index].annotationId;
  }

  public documentIsPending(index: number): boolean {
    return (
      !this.documents[index].annotationId &&
      !!this.cachedAnnotations[this.documents[index].id]
    );
  }

  public isCurrentDocument(documentId: string): boolean {
    if (this.currentDocumentIdx !== null) {
      return this.documents[this.currentDocumentIdx].id === documentId;
    }
    return false;
  }

  public goTo(documentId: string) {
    this.$store.commit(
      'annotation/setCurrentDocumentIndex',
      this.documentIdToIndex(documentId),
    );
  }

  private documentIdToIndex(documentId: string): number | null {
    for (const [index, document] of this.documents.entries()) {
      if (document.id === documentId) {
        return index;
      }
    }
    return null;
  }
}
