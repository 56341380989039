import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ProjectDashboardDto from '@/models/dtos/project-dashboard.dto';
import { inject } from 'vue-typescript-inject';
import ApiService from '@/services/api';
import IProject from '@/models/interfaces/project';

@Component({
  computed: mapState('admin-project', ['project']),
  providers: [ApiService],
})
export default class ProjectDashboard extends Vue {
  public project!: IProject;
  public dashboard: ProjectDashboardDto | null = null;
  public loading: boolean = false;

  @inject() private readonly apiService!: ApiService;

  public created() {
    this.loading = true;
    this.apiService
      .getProjectDashboard(this.project.id)
      .subscribe((dashboard) => {
        this.dashboard = dashboard;
        this.loading = false;
      });
  }
}
