import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import en from './lang/ocr-transcription-panel.en.json';
import fr from './lang/ocr-transcription-panel.json';
import IOCRTagging from '@/illuin-annotation/models/interfaces/ocr-tagging';
import OCRTranscriptionTile from '@/illuin-annotation/components/OCR/OCRTranscriptionTile/OCRTranscriptionTile.vue';

@Component({
  components: {
    OCRTranscriptionTile,
  },
  i18n: {
    messages: { en, fr },
  },
})
export default class OCRTranscriptionPanel extends Vue {
  @Prop() taggings!: { [key: string]: IOCRTagging };
  @Prop() public imageSrc!: string;
  @Prop() public imageSize!: {x: number, y: number};
  @Prop() public imageBuffer!: any;
}
