import { Component, Vue } from 'vue-property-decorator';
import ProjectInfosViewer from '@/components/ProjectInfosViewer/ProjectInfosViewer.vue';
import { mapState } from 'vuex';
import DataModelDisplay from '@/illuin-annotation/factories/DataModelDisplay/DataModelDisplay.vue';

@Component({
  computed: mapState('pool-admin', ['pool']),
  components: { ProjectInfosViewer, DataModelDisplay },
})
export default class PoolAdminProject extends Vue {}
