import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ApiService from '@/services/api';
import CreateTasksForm from '@/components/CreateTasksForm/CreateTasksForm.vue';
import IProject from '@/models/interfaces/project';
import { ICreatePool } from '@/models/interfaces/create-pool';
import { inject } from 'vue-typescript-inject';
import IUser from '@/models/interfaces/user';

@Component({
  computed: mapState('admin-project', ['project']),
  providers: [ApiService],
  components: { CreateTasksForm },
})
export default class CreateTasks extends Vue {
  public project!: IProject;
  public freeDocumentsCount = 0;
  public annotators: IUser[] = [];
  public loading: boolean = false;
  public errorMessages: string[] = [];
  @inject() private readonly apiService!: ApiService;

  public onSubmit(newPool: ICreatePool) {
    this.$router.push({
      name: 'project-admin-pools-list',
      params: { id: this.project.id },
    });
    this.apiService.createPool(newPool).subscribe((pool) => {
      this.$store.dispatch('onPoolCreated', pool);
    });
  }

  public created() {
    this.loading = true;
    this.apiService.getProjectMetadatas(this.project).subscribe((metadata) => {
      if (metadata.freeDocumentsCount === 0) {
        this.errorMessages.push(
          this.$t('create-tasks.errors.no-documents') as string,
        );
      } else {
        this.freeDocumentsCount = metadata.freeDocumentsCount;
      }
      this.apiService.getProjectUsers(this.project).subscribe((annotators) => {
        if (annotators.length === 0) {
          this.errorMessages.push(
            this.$t('create-tasks.errors.no-annotators') as string,
          );
        }
        this.annotators = annotators;
        this.loading = false;
      });
    });
  }
}
